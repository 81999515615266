export const faq = [
    // 1 
    {
        Question: "Do you provide any specific kinds of bespoke packaging?",
       
        Answer: "At One Step Custom Boxes, our array of custom packaging solutions spans a diverse spectrum to meet the unique needs of various industries. We offer a comprehensive selection from retail boxes to e-commerce packaging, product display boxes, food packaging, cosmetic boxes, and beyond. Feel free to contact us at any time if you need further packing assistance.",
      },
      // 2
      {
       
        
        Question: "Please tell me how to get a tailored quotation for all of my packaging needs.",

        Answer: "A customised quote is at your fingertips! Head to our “Get a Quote” page and fill out the form with your details, including specific packaging requirements. A member of our staff will contact you to discuss your needs in more detail and provide you with a quote.",
      },
      // 3
      {
       
        Question: "Can my company's logo and other branding elements be printed on these boxes?",
 
        Answer: "Sure thing! We offer to print on demand for all our custom boxes, allowing you to showcase your company logo, branding, and other design elements with vibrant colours and precision. Our state-of-the-art printing technology will ensure that your packaging accurately represents your brand.",
      },
      // 4
      {
       
        Question: "What quantity of custom boxes are required for an order?",
    
        Answer: "Because we know that every business has unique needs, we are happy to work with you to determine the optimal minimum order quantity for your custom boxes. We will work with you to determine your requirements, your financial constraints, and the best course of action.",
      },
      // 5
      {
       
        Question: "How long before I get my order? What is the total time required?",
     
        Answer: "The production and delivery times are contingent on the complexity of your custom packaging and the quantity of your order. After discussing your specific project, our team will provide an estimated timeline. The safe and timely delivery of your orders is our top concern, and we spare no effort in our pursuit of this objective.",
      },
      // 6
      {
       
        
        Question: "Is it possible to find packaging options that are both sustainable and eco-friendly?",

        Answer: "Without a doubt! We understand the importance of ensuring your satisfaction with the packaging design. Once we finalize the design and specifications, we can furnish you with a physical or digital sample to ensure it aligns with your expectations before proceeding with full production.",
      },
      // 7
      {
       
        Question: "Is it feasible to see a preview of the personalized box before I make a purchase?",
    
        Answer: "The production and delivery times are contingent on the complexity of your custom packaging and the quantity of your order. After discussing your specific project, our team will provide an estimated timeline. Rest assured that we will make every effort to fulfil your purchase on the due date without compromising on quality.",
      },
      // 8
      {
        Question: "What payment methods do you accept?",

             Answer: "We accept various payment methods, including credit/debit cards, PayPal, and bank transfers, to ensure convenient and secure transactions for our valued customers.",
      },
     
    ];