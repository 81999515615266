import React, { useState, useEffect,useRef } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { FaRegCheckCircle } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../Components/mainbanner.css";
import { mainslider, Section2 } from "../Data/Homepagedata";
import { Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";
// import Mainbanner2 from "../Components/Mainbanner2";

const QuoteForm = () => {
  const navigate= useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    width: "",
    height: "",
    length: "",
    unit: "cm",
    foiling:"",
    sizesuggestion:"",
    materialType: "",
    materialThickness: "",
    coating: "",
    color: "",
    quantity: "",
    numberOfDesigns: "",
    printingSides: "",
    designFile:null,
    additionalNotes: "",
  });
  // const [filePreviews, setFilePreviews] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  // const [image, setImage] = useState(null); // Initialize image state as null
  // const [imageURL, setImageURL] = useState(''); // Initialize imageURL state

const handleChange = (e) => {
  const { name, value, files } = e.target;
  if (name === 'designFile') { // Updated to designFile
    setFormData({
      ...formData,
      designFile: files[0], // Get the first selected file
    });
  } else {
    setFormData({
      ...formData,
      [name]: value,
    });
  }
};
  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? (checked ? 'yes' : 'no') : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData,"data")
    try {
      const formDataToSubmit = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataToSubmit.append(key, formData[key]);
      });

      selectedFiles.forEach((file) => {
        // setFormData.designFile(file.name)
        console.log(file.name);
        formDataToSubmit.append("designFile", file.name);
      });

      const response = await axios.post(
        "https://crm.onestepcustomboxes.co.uk/api/quote",
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data);

      // localStorage.setItem('orderFormData', JSON.stringify(dataToStore));

      setFormData({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        width: "",
        height: "",
        foiling:"",
        sizesuggestion:"",
        length: "",
        unit: "cm",
        materialType: "",
        materialThickness: "",
        coating: "",
        color: "",
        quantity: "",
        numberOfDesigns: "",
        printingSides: "",
        designFile:null,
        additionalNotes: "",
      });
      setSubmitSuccess(true);
      navigate("/thank-you")
      setTimeout(() => {
        setFormData({
          /* Your initial state */
        });
        setSelectedFiles([]);
        // setFilePreviews([]);
        setSubmitSuccess(false);
      }, 2000); // 2000 milliseconds = 2 seconds
    } catch (error) {
      console.error("There was an error submitting the form:", error);
    }
  };

  useEffect(() => {
    const savedFormData = JSON.parse(localStorage.getItem("orderFormData"));
    if (savedFormData) {
      setFormData(savedFormData);
    }

    AOS.init({
      duration: 1000, // Adjust the duration as needed
    });
  }, []);
  const targetRef = useRef(null);
  const handleNavigate = () => {
    // navigate('#request-quote');
    window.scrollTo(0, 0);
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const [slides, setSlides] = useState([]);
  const [section, setSection] = useState([]);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Adjust the duration as needed
    });

    setSlides(mainslider);
    setSection(Section2);
  }, []);

  const home=()=>{
    navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <>
       <Helmet>
        <title>Quick and Easy Quoting | Request a Quote</title>
        <meta name="Description" content="Get a fast and accurate quote for custom packaging solutions. Fill out our simple form and receive your personalized quote within 24 hours. Request your quote now!" />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/request-quote'/>
  <meta name="robots" content="index" />
  <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '464918529854741');
            fbq('track', 'PageView');
          `}
        </script>
      </Helmet>

      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          src="https://www.facebook.com/tr?id=464918529854741&ev=PageView&noscript=1"
          alt=""
        />
      </noscript>
      <Navbar />
      <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
        <div className="flex justify-center item-center  ">
          <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] cursor-pointer font-medium " onClick={home}>
            Home
          </p>
          <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
          <p className="lg:text-[15px] text-[9px]  font-medium">
            Request Quote
          </p>
        </div>
      </div>
      {/* <Mainbanner2 /> */}
      <div className="bg-white">
        <div>
          <div className="flex flex-wrap justify-between 2xl:w-[80%] xl:w-[90%] lg:w-[90%] md:w-[90%] w-[90%] xl:mt-8 lg:mt-8 md:mt-8 mt-4 xl:mb-14 lg:mb-14 md:mb-14 mb-4 mx-auto">
            <div className="lg:hidden md:hidden flex xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {slides.map((slide) => (
                  <SwiperSlide
                    key={slide.id}
                    className="fade-in"
                  >
                    <img
                      className="2xl:h-[380px] xl:h-[310px] lg:h-[310px] md:h-[370px] h-[250px] 2xl:w-[480px] xl:w-[380px] lg:w-[380px] md:w-[370px] w-[350px] mx-auto"
                      src={slide.img}
                      loading="lazy"
                      alt=""
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%] mt-5">
              <h1
                className="xl:text-[33px] lg:text-[35px] md:text-[35px] text-[22px] font-medium slide-up"
                style={{ fontFamily: "montserrat", lineHeight: "1.2" }}
              >
                Your One-Stop Destination <br />
                For
                <span style={{ fontWeight: "650" }}>
                  {" "}
                  Custom <span className="text"> Boxes </span> and <br />
                  <span className="text"> Packaging </span> Needs.
                </span>
              </h1>
              <p
                className="xl:mt-8 lg:mt-8 md:mt-8 mt-4 xl:text-[18px] lg:text-[18px] md:text-[18px] text-[14px] slide-up"
                style={{ lineHeight: "1.4" }}
              >
                In The Dynamic UK Beauty Market, We Stand Out With Our Extensive
                Collection Of Bespoke Cosmetics And Packaging.
              </p>
              <button className="bg-[#e36956] hover:bg-white hover:text-[#e36956] border-2 border-transparent hover:border-[#e36956] text-white px-4 xl:font-bold lg:font-bold md:font-bold font-medium xl:mt-7 lg:mt-7 md:mt-7 mt-5 rounded py-3 hover:pointer slide-up" 
              onClick={handleNavigate}
              >
                Get Instant Quote
              </button>
            </div>
            <div className="lg:flex md:flex hidden xl:w-[50%] lg:w-[50%] md:w-[50%] w-[80%]">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {slides.map((slide) => (
                  <SwiperSlide
                    key={slide.id}
                    className="fade-in"
                  >
                    <img
                      className="2xl:h-[380px] xl:h-[310px] lg:h-[310px] md:h-[370px] h-[370px] 2xl:w-[480px] xl:w-[380px] lg:w-[380px] md:w-[370px] w-[370px] mx-auto"
                      src={slide.img}
                      alt=""
                      loading="lazy"
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="mx-auto 2xl:w-[100%] xl:w-[100%] lg:w-[100%] md:w-[100%] w-[95%]" style={{ fontFamily: "montserrat" }}>
          <h2 className="text-center flex flex-wrap justify-center items-center lg:mt-3 md:mt-4 mt-0 xl:text-[16px] lg:text-[22px] md:text-[21px] text-[14px] p-3 xl:font-bold lg:font-bold md:font-bold font-medium" slide-up>
            See what our clients are saying! With 4.5 Social Media Platforms
            Rating !
            <img
              className="lg:h-5 md:h-5 h-4 lg:w-28 md:w-[120px] w-22 lg:ml-5 md:ml-5 ml-0 lg:mt-0 md:mt-2 mt-2 slide-down"
              src="/images/stars.webp"
              loading="lazy"
              alt=""
            />
          </h2>
          <h2 className="xl:text-[23px] lg:text-[25px] md:text-[29px] text-[18px] font-bold xl:mt-8 lg:mt-12 md:mt-5 mt-4 text-center slide-up">
            YOUR FINAL RESORT FOR OUTSTANDING PACKAGING SOLUTIONS
          </h2>
          <p
            className="lg:w-[50%] md:w-[90%] w-[90%] xl:text-[16px] lg:text-[18px] md:text-[21px] text-[14px] font-medium lg:mt-4 md:mt-4 mt-2 lg:mb-3 md:mb-3 mb-10 text-center mx-auto slide-up"
            style={{ lineHeight: "1.2" }}
          >
            Onestepcustomboxes is the go-to option for projects because of their excellent customer service and reasonably priced, customizable packaging solutions
          </p>
          <div className="flex flex-wrap lg:mt-12 md:mt-8 mt-4 xl:w-[75%] lg:w-[69%] md:w-[70%] w-[85%] mb-8 mx-auto">
            {section.map((data) => (
              <div className="lg:w-[16%] md:w-[33%] w-[50%] lg:mb-0 md:mb-10 mb-10 flex flex-col items-center slide-up" key={data.text1}>
                <div
                  className="lg:text-[45px] md:text-[50px] text-[40px] p-2 rounded-full border"
                  style={{ border: "3px solid #fe7d6a" }}
                >
                  {data.icon}
                </div>
                <p
                  className="text-center mt-2 text-[14px]"
                  style={{ fontFamily: "montserrat", fontWeight: "550" }}
                >
                  {data.text1} <br /> {data.text2}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>


      <div className='w-[98%]  mt-10' data-aos="zoom-in" ref={targetRef}>
                <h2    className="text-center text-[30px] font-bold  mb-4 ">Get a Free Quote</h2>
             <div className='flex  flex-wrap'>
                <div className='2xl:w-[70%] lg:w-[70%] md:w-[70%] w-[100%]'>
                <div className='flex flex-wrap'>
                    <div className="w-full md:w-[90%] mx-auto p-8 bg-white rounded ">
                        <form onSubmit={handleSubmit}>
                            {/* Personal Information Section */}
                            <div className=''>
                                <div className='flex justify-between'>
                                    <h2 className='text-base md:text-xl font-semibold mb-2'>Personal Information</h2>
                                    <hr className="border-b border-1 border-gray-300 w-[25%] md:w-[70%] mt-4" />
                                </div>
                            </div>
                            <div className="mb-4 grid grid-cols-2 gap-8 mt-8">
                                <div>
                                    <label htmlFor="firstName" className="block text-sm font-semibold text-gray-700 ">First Name <span className='text-[#23745E]'>*</span></label>
                                    <input type="text" id="firstName" name="firstName" placeholder=""
                                        value={formData.firstName} onChange={handleInputChange}
                                        className="mt-1 p-2 w-full bg-[#F5F5F5]  rounded-md" required />
                                </div>
                                <div>
                                    <label htmlFor="lastName" className="block text-sm font-semibold text-gray-700">Last Name <span className='text-[#23745E]'>*</span></label>
                                    <input type="text" id="lastName" name="lastName" placeholder=" "
                                        value={formData.lastName} onChange={handleInputChange}
                                        className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md" required />
                                </div>
                                <div>
                                    <label htmlFor="phone" className="block text-sm font-semibold text-gray-700">Phone <span className='text-[#23745E]'>*</span></label>
                                    <input type="text" id="phone" name="phone" placeholder=" "
                                        value={formData.phone} onChange={handleInputChange}
                                        className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md" required />
                                </div>
                                <div>
                                    <label htmlFor="email" className="block text-sm font-semibold text-gray-700">Email <span className='text-[#23745E]'>*</span></label>
                                    <input type="email" id="email" name="email" placeholder=" "
                                        value={formData.email} onChange={handleInputChange}
                                        className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md" required />
                                </div>
                            </div>

                            {/* Size Section */}
                            <div className='mb-8 mt-10'>
                                <div className='flex justify-between'>
                                    <h2 className='text-lg md:text-xl font-semibold'>Size</h2>
                                    <hr className="border-b border-1 border-gray-300 w-[92%] mt-4" />
                                </div>
                                <div className="mb-4 mt-6 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div>
                <label htmlFor="sizesuggestion" className="block text-sm font-semibold text-gray-700">Size suggestion</label>
                <select id="sizesuggestion" name="sizesuggestion" value={formData.sizesuggestion} onChange={handleInputChange}
                    className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md">
                      
                    <option value="">Size suggestion </option>
                    <option value="i-have-the-exact-size">I have the exact size</option>
                    <option value="need-advice">Need advice</option>
                    <option value="i-just-have-a-rough-size">I just have a rough size</option>
                </select>
            </div>

            {formData.sizesuggestion === 'i-have-the-exact-size' && (
                <>
                    <div>
                        <label htmlFor="width" className="block text-sm font-semibold text-gray-700">Width</label>
                        <input type="text" id="width" name="width" placeholder=" " value={formData.width} onChange={handleInputChange}
                            className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md" required />
                    </div>
                    <div>
                        <label htmlFor="height" className="block text-sm font-semibold text-gray-700">Height</label>
                        <input type="text" id="height" name="height" placeholder=" " value={formData.height} onChange={handleInputChange}
                            className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md" required />
                    </div>
                    <div>
                        <label htmlFor="length" className="block text-sm font-semibold text-gray-700">Length</label>
                        <input type="text" id="length" name="length" placeholder=" " value={formData.length} onChange={handleInputChange}
                            className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md" required />
                    </div>
                    <div>
                        <label htmlFor="unit" className="block text-sm font-semibold text-gray-700">Unit</label>
                        <select id="unit" name="unit" value={formData.unit} onChange={handleInputChange}
                            className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md">
                            <option value="cm">cm</option>
                            <option value="inch">inch</option>
                            <option value="mm">mm</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="foiling" className="block text-sm font-semibold text-gray-700">Foiling</label>
                        <select id="foiling" name="foiling" value={formData.foiling} onChange={handleInputChange}
                            className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md">
                            <option value="silver-foiling">Silver foiling</option>
                            <option value="gold-foiling">Gold foiling</option>
                            <option value="cooper-foiling">Cooper foiling</option>
                            <option value="holographic-foiling">Holographic foiling</option>
                            <option value="purple-foiling">Purple foiling</option>
                            <option value="red-foiling">Red foiling</option>
                            <option value="pink-foiling">Pink foiling</option>
                            <option value="black-foiling">Black foiling</option>
                            <option value="green-foiling">Green foiling</option>
                            <option value="orange-foiling">Orange foiling</option>
                        </select>
                    </div>
                </>
            )}
        </div>
                            </div>

                            {/* Material Section */}
                            <div className='mb-8 mt-10'>
                                <div className='flex justify-between'>
                                    <h2 className='text-lg md:text-xl font-semibold'>Material</h2>
                                    <hr className="border-b border-1 border-gray-300 w-[86%] mt-4" />
                                </div>
                                <div className="mb-4 mt-6 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">

                                    <div>
                                        <label htmlFor="materialType" className="block text-sm font-semibold text-gray-700">Material Type</label>
                                        <select id="materialType" name="materialType" value={formData.materialType} onChange={handleInputChange}
                                            className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md">
                                            <option value="SBS">SBS</option>
                    <option value="1-side-coated-paperboard">1 Side Coated Paperboard</option>
                    <option value="2-side-coated-paperboard">2 Side Coated Paperboard</option>
                    <option value="brown-card">Brown Card</option>
                    <option value="white-and-brown-recycled">White and Brown Recycled</option>
                    <option value="brilliant-white-uncoated-recyclable-eco-friendly-card">Brilliant White Uncoated Recyclable Eco Friendly Card</option>
                    <option value="corrugated-brown-fluting-(E-flute)=2mm">Corrugated Brown fluting (E-flute) = 2mm</option>
                    <option value="corrugated-brown-fluting-(B-flute)=3mm">Corrugated Brown fluting (B-flute) = 3mm</option>
                    <option value="corrugated-white-fluting-(E-flute)=5mm">Corrugated White fluting (E-flute) = 5mm</option>
                    <option value="blood-red-recycled">Blood Red Recycled</option>
                    <option value="dark-green-recycled">Dark Green Recycled</option>
                    <option value="dark-purple-recycled">Dark Purple Recycled</option>
                    <option value="green-recycled">Green Recycled</option>
                    <option value="maroon-recycled">Maroon Recycled</option>
                    <option value="mustard-recycled">Mustard Recycled</option>
                                        </select>
                                    </div>

            <div>
                <label htmlFor="materialThickness" className="block text-sm font-semibold text-gray-700">Material Thickness</label>
                <select id="materialThickness" name="materialThickness"
                    value={formData.materialThickness} onChange={handleInputChange}
                    className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md">
                  <option value="Thickness 1">Not sure Need advice</option>
                    <option value="210-gsm">210 gsm</option>
                    <option value="350-gsm">350 gsm</option>
                    <option value="420-gsm">420 gsm</option>
                    <option value="500-gsm">500 gsm</option>
                    <option value="600-gsm">600 gsm</option>
                    <option value="800-gsm">800 gsm</option>
                    <option value="900-gsm">900 gsm</option>
                </select>
            </div>
            <div>
                <label htmlFor="coating" className="block text-sm font-semibold text-gray-700">Coating</label>
                <select id="coating" name="coating"
                    value={formData.coating} onChange={handleInputChange}
                    className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md">
                   <option value="Coating 1">None</option>
                    <option value="Coating 2">Gloss Lamination</option>
                    <option value="Coating 3">Matte Lamination</option>
                    <option value="Coating 4">Gloss Varnish</option>
                    <option value="Coating 5">Matte Varnish</option>
                    <option value="Coating 6">Soft Touch Lamination</option>
                </select>
            </div>
            <div>
                <label htmlFor="color" className="block text-sm font-semibold text-gray-700">Color</label>
                <select id="color" name="color"
                    value={formData.color} onChange={handleInputChange}
                    className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md">
                        <option value="">-Please Choose an option-</option>
                    <option value="1color ">1 Color</option>
                    <option value="2color">2 Color </option>
                    <option value="3color">3 Color </option>
                    <option value="4color">4 Color </option>
                    <option value="1/pantone-color">1/pantone Color </option>
                    <option value="2/pantone-color">2/pantone Color </option>
                    <option value="3/pantone-color">3/pantone Color </option>
                    <option value="4/pantone-color">4/pantone Color </option>
                </select>
            </div>
                                </div>
                            </div>

                            {/* Other Details Section */}
                            <div className='mb-8 mt-10'>
                                <div className='flex justify-between'>
                                    <h2 className='text-lg md:text-xl font-semibold'>Quantity</h2>
                                    <hr className="border-b border-1 border-gray-300 w-[79%] mt-4" />
                                </div>
                                <div className="mb-4 mt-6 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                
                                    <div>
                                        <label htmlFor="quantity" className="block text-sm font-semibold text-gray-700">Quantity</label>
                                        <input type="number" id="quantity" name="quantity" placeholder=" "
                                            value={formData.quantity} onChange={handleInputChange}
                                            className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md" />
                                    </div>
                                    <div>
                                        <label htmlFor="numberOfDesigns" className="block text-sm font-semibold text-gray-700">Number of Designs</label>
                                        <input type="number" id="numberOfDesigns" name="numberOfDesigns" placeholder=" "
                                            value={formData.numberOfDesigns} onChange={handleInputChange}
                                            className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md" />
                                    </div>
                                    <div>
                                        <label htmlFor="printingSides" className="block text-sm font-semibold text-gray-700">Printing Sides</label>
                                        {/* <input type="text" id="printingSides" name="printingSides" placeholder=" "
                                            value={formData.printingSides} onChange={handleInputChange}
                                            className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md" /> */}
                                              <select id="printingSides" name="printingSides"
                    value={formData.printingSides} onChange={handleInputChange}
                    className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md">
                    <option value="single-side">Single Side</option>
                    <option value="both-side">Both Side </option>
                </select>
                                    </div>
                                </div>
                            </div>

                            {/* Upload Design Section */}
                            <div className='mb-8 mt-10'>
                                <div className='flex justify-between'>
                                    <h2 className='text-lg md:text-xl font-semibold'>Attach Designs</h2>
                                    <hr className="border-b border-1 border-gray-300 w-[78%] mt-4" />
                                </div>
                                <div className="mb-4 mt-6 grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                <div className="col-md-4">
            <div className="card p-2">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="input-group my-3">
                    <input
                type="file"
                id="designFile" // Updated to designFile
                name="designFile" // Updated to designFile
                onChange={handleChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
              />
                    </div>
                    {/* {imageURL && (
                        <div className="mt-3">
                            <p>Image uploaded successfully!</p>
                            <a href={imageURL} target="_blank" rel="noopener noreferrer">View Image</a>
                        </div>
                    )} */}
                </div>
            </div>
        </div>
                                    {/* <div>
                                        <label htmlFor="designFile" className="block text-sm font-semibold text-gray-700">Design File</label>
                                        <input type="file" id="designFile" name="designFile" onChange={handleFileUpload}
                                            className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md" multiple />
                                    </div>
                                    <div className="mt-4 col-span-2">
                                        {filePreviews.map((preview, index) => (
                                            <div key={index} className="relative inline-block mr-4">
                                                {preview && (
                                                    <img src={preview} alt={`preview ${index}`} className="w-20 h-20 object-cover rounded-lg cursor-pointer" onClick={() => handleOpenFile(index)} />
                                                )}
                                                <button
                                                    type="button"
                                                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center"
                                                    onClick={() => handleRemoveFile(index)}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        ))}
                                    </div> */}
                                </div>
                            </div>

                            {/* Additional Notes Section */}
                            <div className='mb-8 mt-10'>
                                <div className='flex justify-between'>
                                    <h2 className='text-lg md:text-xl font-semibold'>Additional Notes</h2>
                                    <hr className="border-b border-1 border-gray-300 w-[72%] mt-4" />
                                </div>
                                <div className="mb-4 mt-6">
                                    <textarea id="additionalNotes" name="additionalNotes" rows="4" placeholder="Any additional information..."
                                        value={formData.additionalNotes} onChange={handleInputChange}
                                        className="mt-1 p-2 w-full bg-[#F5F5F5] rounded-md"></textarea>
                                </div>
                            </div>
                            <div className="w-full md:w-[90%] mx-auto bg-white rounded ">
                        {/* Your form elements */}

                        {submitSuccess && ( // Conditionally render success message
                            <div className="bg-green-600 text-white p-3 font-bold text-center">
                            Quote Submitted Successfully
                            </div>
                        )}
                    </div>
                            <div className="text-center mt-8">
                                <button type="submit" className="bg-[#fe7d6a] text-white py-2 px-4 rounded-md">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                </div>
                <div className='2xl:w-[30%] lg:w-[30%] md:w-[30%]   w-[90%] mx-auto flex flex-wrap justify-center  2xl:mt-14 lg:mt-14'>
<img className='2xl:h-[400px]  xl:h-[400px] lg:h-[400px] md:h-[400px] h-[300px]' src='/images/finalbanner/ok.webp' loading="lazy" alt='img'/>
<div className='flex flex-wrap justify-between  mt-4 w-full 2xl:mb-[50%] xl:mb-[50%] lg:mb-[50%] md:mb-[50%] mb-[20px] mt-2'>
  {[
    { title: 'Budget-Friendly:', text: 'Premium quality Boxes, Stickers & Tags at affordable prices.' },
    { title: 'Versatile Designs:', text: 'Customize Your Design with Multiple Artwork Options!' },
    { title: 'Endless Choices:', text: 'Diverse Finishes, Adhesives, and Materials Await!' },
    { title: 'Lightning-Fast Delivery:', text: '98% of Orders Ready within 24 Hours!' },
    { title: 'Order Flexibility:', text: 'Get Boxes, Stickers & tags Designs You Need, No Minimums!' },
    { title: 'Free PDF Proofs:', text: 'Approve Your Design file Before Printing!' },
    { title: 'Seamless Online Ordering:', text: 'Get Your Boxes, Stickers & Tags with Just a Few Clicks!' },
    { title: 'Try Before You Buy:', text: 'Free Sample Pack to Feel the Quality!' },
    { title: 'Transparent Pricing:', text: 'What You See Is What You Get - No Surprises!' },
    { title: 'Rapid Dispatch:', text: 'Your Order Printed and Shipped in 2 Days with DPD!' }
  ].map((item, index) => (
    <div key={index} className='flex flex-col 2xl:w-[48%] xl:w-[48%] lg:w-[48%] md:w-[48%] w-[50%] items-center justify-col mt-3'>
      <FaRegCheckCircle className='mr-2 text-[#fe7d6a] text-[25px]' />
      <div className='text-center mt-2'>
      <h3 className='text-[15px] font-bold'>{item.title}</h3>
      <p className='text-[12px]'>{item.text}</p>
      </div>
    </div>
  ))}
</div>

                </div>
             </div>
            </div>
      <Footer />
    </>
  );
};

export default QuoteForm;