import React, { useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import AOS from 'aos';
import 'aos/dist/aos.css';

const HotComponent = ({ prod }) => {
  const [currentPage, setCurrentPage] = useState(1);
  // const [clickedData, setClickedData] = useState(null); // State to store clicked card's data

  const navigate = useNavigate();

  const itemsPerPage = 12; // Number of items per page

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate total number of pages
  const totalPages = Math.ceil(prod.length / itemsPerPage);

  // Calculate index range for current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, prod.length);

  // Slice the items array to display items for the current page
  const currentItems = prod.slice(startIndex, endIndex);

  const ProductCard = ({ data }) => {

    const handleClick = async () => {
      let mainurl; // Declare mainurl outside the try block
  
      try {
        // const categoryName = data.catgr.toLowerCase().split(' ')[0];
        const words = data.catgr.toLowerCase().split(' ');
        let categoryName = words[0] === 'by' && words.length > 1 ? words[1] : words[0]; // Skip 'by' if it's the first word

        // Correct 'suplements' to 'supplements'
        if (categoryName === 'supplements') {
          categoryName = 'suplements';
        }
        if (categoryName === 'style') {
          categoryName = 'industry';
        }  console.log(categoryName, "categoryName");
        const response = await axios.get(`https://crm.onestepcustomboxes.co.uk/api/${categoryName}`);      
        console.log("Industry URL22:", response.data.pro);  
        mainurl = response.data.pro; // Assign value to mainurl 
      } catch (error) {
        console.error('Error fetching industry URL:', error);
      }
  
      const formattedName = decodeURIComponent(decodeURIComponent(data.url));
      navigate(`/${formattedName}`, { state: { product: data, mainurl: mainurl } }); // Pass mainurl as part of the state
      window.scrollTo(0, 0);
    };


    useEffect(() => {
      AOS.init({
        duration: 1000, // Adjust the duration as needed
      });

    }, []);

    return (
      <div
      className="lg:w-[23%] md:w-[45%] mt-6 w-[43%] rounded overflow-hidden shadow-lg rounded-tl-3xl rounded-tr-3xl relative cursor-pointer"
      onClick={handleClick}
      data-aos="fade-up"
      onContextMenu={(e) => e.preventDefault()}  // Right-click menu disable
      onDragStart={(e) => e.preventDefault()}   // Dragging disable
      onMouseDown={(e) => e.preventDefault()}   // Mouse events disable
      onKeyDown={(e) => e.preventDefault()}     // Keyboard events disable
    >
      <img
        className="w-full"
        src={data.image}
        loading="lazy"
        alt={data.image}
      />
      <div className="px-6 py-2">
        <div className="font-bold lg:text-[18px] md:text-[18px] text-[15px] mb-2">
          {data.name}
        </div>
        <p className="text-gray-700 lg:text-[14px] md:text-[14px] lg:mt-0 md:mt-0 mt-[-8px] text-[13px]">
          {data.description}
        </p>
      </div>
    </div>
    
    );
  };

  // Log clicked card's data to console
  // console.log("Clicked Card Data:", clickedData);

  return (
    <div>
      <div className='flex flex-wrap lg:w-[85%] md:w-[85%] w-[100%] mt-6 mx-auto justify-around'>
        {currentItems.map((data, index) => (
          <ProductCard key={index} data={data} />
        ))}
      </div>
      {/* Pagination */}
      <div className="flex justify-center m-10">
        {[...Array(totalPages)].map((_, page) => (
          <button
            key={page}
            className={`${
              page + 1 === currentPage
                ? "bg-[#e36956] text-white"
                : "bg-gray-200 text-gray-700"
            } hover:bg-[#e36956] hover:text-white font-semibold py-2 px-4 rounded-full mx-1`}
            onClick={() => handlePageChange(page + 1)}
          >
            {page + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default HotComponent;
