import React, { useState,useEffect } from 'react';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { Helmet } from 'react-helmet';

export default function Reviewadd() {
  const [formData, setFormData] = useState({
    name: '',
    review: '',
    stars: 0,
  });
  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleRatingChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      stars: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Replace with your actual API endpoint
      const response = await fetch('https://crm.onestepcustomboxes.co.uk/api/clientreview', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Clear the form
        setFormData({
          name: '',
          review: '',
          stars: 0,
        });
        setStatus('Your review has been submitted successfully.');
      } else {
        setStatus('Failed to submit your review. Please try again.');
      }
    } catch (error) {
      setStatus('An error occurred. Please try again later.');
    }

    setTimeout(() => {
        setStatus('');
      }, 2000);
  };
  useEffect(() => {
    // Remove data-react-helmet attribute from the canonical link
    const canonicalLink = document.querySelector("link[rel='canonical']");
    if (canonicalLink) {
      canonicalLink.removeAttribute("data-react-helmet");
    }
  }, []);
  
  return (
    <div>
       <Helmet>
        {/* <title>Quick and Easy Quoting | Request a Quote</title> */}
        {/* <meta name="Description" content="Get a fast and accurate quote for custom packaging solutions. Fill out our simple form and receive your personalized quote within 24 hours. Request your quote now!" /> */}
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/feedback'/>
  <meta name="robots" content="index" />

      </Helmet>
      <Navbar />
      <div>
        <div className='w-[95%] xl:h-auto overflow-hidden lg:h-auto md:h-auto flex flex-wrap justify-between lg:pt-16 md:pt-16 pt-6 pb-10 mx-auto'>
          <div className='lg:w-[59%] md:w-[59%] w-[100%]'>
            <div className='ml-6 xl:w-[60%] lg:w-[60%] md:w-[60%] w-[90%]'>
              <h1 className='font-bold xl:text-[40px] lg:text-[40px] md:text-[40px] text-[25px]'>
              Share Feedback & We would Love your<span className='text-[#fe7d6a]'> Reviews </span>
              </h1>
              <p className='mt-6'>
              Your Opinion is Really Valuable To us, So we really appreciate If you share you Thoughts with us
              </p>
            </div>
            <form
              className="lg:w-[80%] md:w-[80%] w-[90%] lg:mt-5 md:mt-5 mt-8 mx-auto shadow-2xl p-[6%]"
              data-aos="fade-right"
              onSubmit={handleSubmit}
            >
              <div className="mb-5">
                <label htmlFor="name" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="mb-5">
                <label className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">Rating</label>
                <div className="flex space-x-1">
                  {[1, 2, 3, 4, 5].map((value) => (
                    <React.Fragment key={value}>
                      <input
                        type="radio"
                        id={`star${value}`}
                        name="stars"
                        value={value}
                        className="hidden"
                        checked={formData.stars === value}
                        onChange={() => handleRatingChange(value)}
                        required
/>
                      <label
                        htmlFor={`star${value}`}
                        className={`cursor-pointer text-[28px] ${formData.stars >= value ? 'text-yellow-500' : 'text-gray-300'}`}
                  required
                     >
                        &#9733;
                      </label>
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="review" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
                  Review
                </label>
                <textarea
                  id="review"
                  className="shadow-sm bg-gray-50 h-[110px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="Type your Review here"
                  value={formData.review}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              
            {status && <p className="pb-4 pt-3 mb-3 bg-green-500 text-white font-bold text-center">{status}</p>}
              <button
                type="submit"
                className="text-white bg-[#fe7d6a] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Submit
              </button>
            </form>
          </div>
          <div className='lg:w-[40%] md:w-[36%] w-[100%] xl:flex lg:flex md:flex hidden justify-center items-center relative mb-[-45px] xl:ml-3 md:ml-3 lg:ml-3 ml-0' data-aos="fade-left">
            <img src='/images/girl-cont.png' loading="lazy" className='xl:h-[80%] lg:h-[80%] md:h-[80%] h-[400px] absolute bottom-1' alt='img' />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
