import React ,{useEffect} from 'react'

import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

export default function Choosing2() {

    const navigate = useNavigate()

const reviewclick=()=>{
    navigate(`/feedback`)
}
    
const handle =()=>{
    navigate('/portfolio')
}

    const handlecontact = () => {
  navigate('/contact')
  window.scrollTo(0, 0);
  };
  const handleportfolio = () => {
    navigate('/request-quote')
    window.scrollTo(0, 0);
    };

    useEffect(() => {
    
        AOS.init({
          duration: 1000, // Adjust the duration as needed
        });
      }, []);

  return (
    <div className='bg-white '>
       <div className='flex flex-wrap lg:w-[95%] overflow-hidden md:w-[95%] w-[100%] justify-center  pb-[2%] items-center mx-auto'>
        <div className='lg:w-[43%] md:w-[40%] w-[90%] '
        //  data-aos="fade-right"
         >
            <img className='xl:p-[10%] md:p-[7%] p-[5%] mx-auto' src='/images/image-group.webp' loading="lazy" alt='img' />
            </div>
        <div className='lg:w-[55%] md:w-[55%] w-[90%] ' 
        // data-aos="fade-left"
        >
            <h2 className='lg:text-[33px] md:text-[33px] text-[26px] lg:w-[70%] md:w-[70%] w-[90%] lg:mb-4 md:mb-4 mb-3 font-bold lg:mt-0 md:mt-0 mt-8 ' style={{lineHeight:"1.2"}}>One-Step <span className='text-[#fe7d6a]'> Custom Boxes </span> Shop</h2>
            <p className='lg:text-[14px] md:text-[14px] text-[13px]'>
            When it comes to custom packaging, no one does it better than One Step Bespoke Boxes. Our steadfast dedication to quality has earned us the trust of many industries, who depend on us for their high-quality custom boxes. Regardless of your industry—retail, e-commerce, food, cosmetics, or any other—you need look no further for all your packaging requirements.
            </p>
            <button className='lg:mt-10 md:mt-10 mt-6 bg-[#fe7d6a] font-bold px-3 rounded-xl py-2' onClick={handle} >Learn More</button>
        </div>
        </div> 
        <div className='lg:pb-[5%] md:pb-[5%] pb-[7%] text-white' style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/images/backr.png)", backgroundSize:"100% 100%"}}>
<h2 className='lg:text-[35px] md:text-[35px] text-[25px] text-center font-bold lg:pt-[5%] md:pt-[5%] pt-[7%]'>Quality Custom Boxes, Affordable Prices</h2>
<p className='text-center lg:text-[15px] md:text-[15px] text-[14px] mt-[20px]  m-2 ' style={{lineHeight:"1.1"}}>From your order to your front door, our team manages it all to make things simpler for you</p>
<div className='flex lg:w-[31%] md:w-[31%] w-[100%]  lg:mt-[30px] md:mt-[2%] mt-[5%] mx-auto'>
    <button className='border-2 text-[12px] mx-auto rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handlecontact}>Contact Us Today</button>
    <button className='border-2 text-[12px] mx-auto lg:ml-10 md:ml-10 rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handleportfolio}>Get Free Quote</button>

</div>
        </div>
        <div className='bg-white  xl:p-7 lg:p-7 md:p-7 p-2'>
            <div className='flex items-center justify-between'>
                <h2 className=' font-bold lg:text-[30px] md:text-[30px] text-[20px]'>Give us a <span className='text-[#fe7d6a]'> Feedback</span></h2>
                <button className='bg-[#fe7d6a] lg:text-[17px] md:text-[17px] text-[12px] text-white font-bold lg:py-2 md:py-2 py-2 lg:px-4 md:px-4 px-2 rounded-lg' onClick={reviewclick} > Write a Review</button>
            </div>
        </div>
    </div>
  )
}
