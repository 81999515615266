import React, { useState, useEffect } from "react";
// import { topdata } from "../Data/Homepagedata";
import AOS from 'aos';
import axios from 'axios';
import 'aos/dist/aos.css';
import YourComponent from "../Pages/YourComponent";
import { useNavigate } from "react-router-dom";

export default function Topproduct() {
    const navigate = useNavigate()

    const [prod, setProd] = useState([]);
  useEffect(() => {
    axios.get('https://crm.onestepcustomboxes.co.uk/api/hot-items')
        .then(response => {
            const data = response.data;
            console.log('Fetched data:', data.pro);

            // Adjust the image URLs
            const modifiedPro = data.pro.map(product => ({
                ...product,
                image: `https://crm.onestepcustomboxes.co.uk${product.image}`
            }));

            setProd(modifiedPro);
            AOS.init({
                duration: 1000
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
}, []);

const handle =()=>{
    navigate('/hotitems')
}

    // const visibleProducts = showMore ? data : data.slice(0, 8);
    AOS.init({
        duration: 2000, // Adjust the duration as needed
      });

    return (
        <div className='bg-[#f2f2f2] pb-8'>
            <div>
                <div className='flex lg:w-[80%] md:w-[80%] w-[89%] pt-12 mx-auto justify-between'>
                    <h2 className='lg:text-[35px] md:text-[35px] text-[24px] font-bold'>Top <span className='text-[#ef6e5b]'> Products</span></h2>
               <button className='bg-[#ef6e5b] font-bold  text-[17px] rounded-3xl hover:bg-white hover:text-[#e36956] pl-4 pr-4' onClick={handle}>See More</button>
                </div>
                {/* ----2------- */}
                <div>
                    <YourComponent prod={prod} />
                  
                </div>
            </div>
        </div>
    );
}
