import React,{useEffect} from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";

export default function  ArtworkGuidelines() {

  useEffect(() => {

    AOS.init({
      duration: 1000, // Adjust the duration as needed
    });

  }, []);

  const navigate=useNavigate();

  const home=()=>{
    navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <>
     <Helmet>
        <title>Essential Artwork Guidelines for Perfect Printing</title>
        <meta name="Description" content="Follow our detailed artwork guidelines to ensure flawless printing. Learn about file formats, resolutions, and best practices for stunning custom packaging designs." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/artwork'/>
  <meta name="robots" content="index" />
      </Helmet>
        <Navbar />
      <div className="">
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className=" text-[#C4C4C4] lg:text-[15px] text-[9px] font-medium cursor-pointer " onClick={home}>
              Home
            </p>
             <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            {/* Add this line */}
            <p className="lg:text-[15px] text-[9px] font-medium">
            Artwork Guidelines
            </p>
          </div>
        </div>
        <div className=' xl:h-[370px] lg:h-[400px] lg:flex md:flex hidden md:h-[380px] h-[300px] 2xl:w-[70%] xl:w-[95%] lg:w-[80%] md:w-[80%] w-[90%] mx-auto rounded-[80px]  mx-auto mt-10'  style={{backgroundImage:"url(/images/Banner.jpg" , backgroundSize:"100% 100%"}} data-aos="fade-left" >
        {/* <div className='w-[90%]  h-[100%] mx-auto flex justify-between items-center '>
<div className='lg:w-[50%] md:w-[32%] w-[100%]   flex flex-col lg:items-start items-center p-4 lg:mt-0 md:mt-0 mt-5 mb-5' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
   <h2 className='lg:text-[35px] mt-2 md:text-[35px] text-[25px]  font-bold' style={{lineHeight:"1.1"}}>Discover the <span className='text-[#fe7d6a]'><br/> top quality </span>  Stickers</h2>
   <p className='lg:mt-8 font-medium md:mt-8 mt-4'>We are proud to be a top UK manufacturer, providing you with high-quality materials for your custom boxes.</p>
   <button className='bg-[#b04d4b] text-white hover:bg-white hover:text-[#b04d4b] lg:w-[35%] md:w-[35%] w-[60%]  lg:mt-8 md:mt-8 mt-6 font-bold lg:py-3 md:py-4 py-3 rounded-lg'  onClick={handleNavigate} >Get a Quote</button>
    </div>
</div> */}

        </div>
        <div className='xl:h-[400px] lg:h-[400px] lg:hidden md:hidden flex mb-[50px] md:h-[350px] h-[200px] p-1 xl:w-[90%] lg:w-[80%] md:w-[80%] w-[90%] mx-auto rounded-[80px]  mx-auto mt-10 bg-[#f76a56]'>
        <div className='w-[90%]  h-[100%] mx-auto flex justify-between items-center '>
<div className='lg:w-[50%] md:w-[32%] w-[100%]   flex flex-col lg:items-start items-center p-4 lg:mt-0 md:mt-0 mt-5 mb-5' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" >
   <h1 className='lg:text-[35px] mt-2 md:text-[35px] text-[25px]  font-bold' style={{lineHeight:"1.5"}}>How To Create a Full Size Mock-up Form Dieline.</h1>
   {/* <p className='lg:mt-8 font-medium md:mt-8 mt-4'>Customizable, high-quality packaging slotions tailored  to your needs. Fast turnaround and free design  assistance avaliable! </p> */}
   {/* <button className='bg-[#b04d4b] text-white hover:bg-white hover:text-[#b04d4b] lg:w-[35%] md:w-[35%] w-[60%]  lg:mt-8 md:mt-8 mt-6 font-bold lg:py-3 md:py-4 py-3 rounded-lg'  onClick={handleNavigate} >Get a Quote</button> */}
    </div>
</div>

        </div>

        <div className="w-[95%] md:w-[90%] mx-auto overflow-hidden pt-10 md:pt-16 pb-10" >
          {/* 1 */}
          <div className="overflow-hidden" data-aos="zoom-in-up">
            <h2 className="text-base md:text-[18px] font-semibold md:font-semibold pt-4">
            Artwork Guidelines
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium pt-4 md:pt-4 ">
            Designing the perfect custom packaging is kind of like following a recipe. You need the secret ingredients for success. That’s where our artwork guidelines come in: a handy list of recommendations that will make the design process smooth and seamless.Remember, too, that we offer free in-house design support to all our customers. So, if you need extra support or expertise, we’ve got you covered.Let’s dive in.
            </p>

          </div>

          {/* 2 */}

          <div  className="overflow-hidden" data-aos="zoom-in-down">
            <h2 className="text-base md:text-[18px] font-semibold md:font-semibold pt-4" >
              {" "}
              Preferred Files:
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
            To move from packaging vision to reality, please send us either editable or outlined vector-based AI design files. We also accept EPS, SVG or PDF files, as long as they are editable and vector-based.
                        </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
            We know that some organizations also work with CDR or PSD files. Unfortunately, these files are a little tricker for our packaging wizards to work with! While you can submit these options, your project may take a little longer and cost more, so avoid using these file types if you can.
            </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
            If you’ve got any questions about file types, give your design team a quick bell or instant message. We’ll get back to you quickly.
        </p>

            <h2 className="text-base md:text-[18px] font-semibold md:font-semibold pt-4">
            Colors:
            </h2>

            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
            We’ll print your box using CMYK or Pantone inks. If your brand uses a specific color palette, we recommend choosing Pantone printing over CMYK. From our experience, we know that CMYK colors can sometimes print slightly on different machines. Pantone really is your best bet for universal consistency!
                    </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
            What if you work with RBG colors? Well, our system automatically converts these to CMYK. Of course, it’s our mission to create packaging that replicates your initial color choices as closely as possible. So, we’ll share a digital proof with you before printing to ensure you’re happy with the results.
            </p>

          </div>

          {/* 3 */}

          <div className="overflow-hidden" data-aos="fade-right">
            <h2 className="text-base md:text-[18px] font-semibold md:font-semibold pt-4">
            Font:
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
            Please outline the fonts used in your design in the file you share with us. If your brand uses a unique or paid-for font, please provide your font in a separate document. As part of our deep commitment to our clients’ privacy, we promise to never share, reveal or use your in-house font for another client. Your font secrets are safe with us!
            </p>

            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
            Additionally, here are several key points to remember when using text and line art in your artwork:
            </p>

            <ol className="w-[85%] md:w-full text-[#7A7A7A] text-sm md:text-sm font-medium font-robot  list-outside list-decimal pt-4 md:pt-4 mx-8 md:mx-12 leading-loose md:leading-loose">
              <li class="list-disc">
              Make sure text is at least 8pt. Small font sizes may look good on your screen, but may not print well.

              </li>
              <li class="list-disc">
              Try to keep text in bold colors that are made up of one or two inks maximum (C, M, Y, or K).

              </li>
              <li class="list-disc">
              Artwork registration to any panel may vary up to 1/8 of an inch.

              </li>
              <li class="list-disc">
              Include at least a 0.25-inch bleed in areas where graphics extend past the dieline.

              </li>
              <li class="list-disc">
              When printing lines in a solid or dark color, they should be no thinner than 1 point.

              </li>
              <li class="list-disc">
              Make sure all fonts are embedded or outlined.

              </li>
              <li class="list-disc">
          Save your designs using the ‘Adobe PDF/X-1a’ preset, where possible.

              </li>    
            </ol>
          </div>

          {/* 4 */}

          <div className="overflow-hidden" data-aos="fade-left">
            <h2 className="text-base md:text-[18px] font-semibold md:font-semibold pt-4">
            Support Graphics:
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
            Powerful graphics are a fantastic way to boost the wow factor of your packaging. To ensure your graphics come out just right, please double-check that all links are embedded and working before sending your file to us.

            </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
             If you prefer, you can also share your graphics separately in zip files. Choose whatever option works best for your team!
            </p>

            <h2 className="text-base md:text-[18px] font-semibold md:font-semibold pt-4">
            Dieline:
            </h2>

            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
            To achieve the layout of your dreams, your dielines need to hit the mark. Make sure the background of your design extends to your bleeding lines, and keep your art and graphics at least 1/8” from all knives and scores.
                        </p>
            
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
            Designing dielines is a tricky discipline. If you need help, we’re on hand. With 100% free design support, we can ensure the style of your final product perfectly matches your vision.
                        </p>

        

            <h2 className="text-base md:text-[18px] font-semibold md:font-semibold pt-4">
            UPC’s/Barcodes:
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
            Struggling to scan a barcode can be off-putting and frustrating for your customers. To avoid these issues, make sure your barcode images are constructed in high-definition. Size is also an essential factor. The larger the image, the easier it is to scan.
            </p>

            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-3  ">
            If you’re not sure about the perfect proportions for your barcode, don’t fret! Send it to us separately, and we’ll find the most attractive, usable way to incorporate it into your design.
            </p>
          </div>

        
        </div>

      </div>
        <Footer />

</>
);
}
