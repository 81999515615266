import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import YourComponent from '../YourComponent';
import Navbar from "../../Components/Navbar";
import {  Data } from "../../Data/Mystyledata";
import Footer from '../../Components/Footer';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

export default function Beauty() {
    const [prod, setProd] = useState([]);
    const navigate = useNavigate()
    const reviewclick=()=>{
      navigate(`/testimonial`)
  }
      
  const handlecontact = () => {
    navigate('/contact')
    window.scrollTo(0, 0);
    };
    const handleportfolio = () => {
      navigate('/request-quote')
      window.scrollTo(0, 0);
      };
  
  useEffect(() => {
    axios.get('https://crm.onestepcustomboxes.co.uk/api/beauty')
        .then(response => {
            const data = response.data;
            console.log('Fetched data:', data.pro);

            // Adjust the image URLs
            const modifiedPro = data.pro.map(product => ({
                ...product,
                image: `https://crm.onestepcustomboxes.co.uk${product.image}`
            }));

            setProd(modifiedPro);
            AOS.init({
                duration: 1000
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
}, []);

const home=()=>{
    navigate("/")
}
const handle2=()=>{
  navigate("/request-quote")
}


const productSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    name: "Cosmetic Packaging",
    image: "https://onestepcustomboxes.co.uk/logo192.png",
    description: "High-quality custom cosmetic packaging solutions for all your beauty product needs.",
    brand: {
      "@type": "Brand",
      name: "One Step Custom Boxes",
    },
    offers: {
      "@type": "Offer",
      url: "https://onestepcustomboxes.co.uk/cosmetic-packaging",
      priceCurrency: "GBP",
      price: "0.65",
      itemCondition: "https://schema.org/NewCondition",
      availability: "https://schema.org/InStock",
    },
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.8",
      reviewCount: "276",
    },
  };

  const breadcrumbSchema = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://onestepcustomboxes.co.uk/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Cosmetic Packaging",
        item: "https://onestepcustomboxes.co.uk/cosmetic-packaging",
      },
    ],
  };

  const faqSchema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What types of cosmetic packaging do you offer?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "We offer a variety of cosmetic packaging options including custom boxes, tubes, and jars.",
        },
      },
      {
        "@type": "Question",
        name: "Can I customize my cosmetic packaging?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, we provide fully customizable options to meet your specific branding and product needs.",
        },
      },
    ],
  };

  const organizationSchema = {
    "@context": "https://schema.org/",
    "@type": "Organization",
    name: "One Step Custom Boxes",
    url: "https://onestepcustomboxes.co.uk",
    logo: "https://onestepcustomboxes.co.uk/logo192.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+44 20 3332 0061",
      contactType: "Customer Service",
    },
  };

  const websiteSchema = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    name: "OneStepCustomBoxes",
    url: "https://onestepcustomboxes.co.uk/",
  };

  const completeSchema = {
    "@context": "https://schema.org/",
    "@graph": [
      productSchema,
      breadcrumbSchema,
      faqSchema,
      organizationSchema,
      websiteSchema,
    ],
  };

  useEffect(() => {
    // Remove data-react-helmet attribute from the canonical link
    const canonicalLink = document.querySelector("link[rel='canonical']");
    if (canonicalLink) {
      canonicalLink.removeAttribute("data-react-helmet");
    }
  }, []);
    return (
        <div>
             <Helmet>
        <title>Elegant Beauty and Cosmetics Packaging Solutions</title>
        <meta name="Description" content="Discover premium beauty packaging that elevates your brand's essence. Perfect for secure shipping, gifting, and presenting your products in a sophisticated manner." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/cosmetic-packaging'/>
  <meta name="robots" content="index,follow" />
  <script type="application/ld+json">
          {JSON.stringify(completeSchema)}
        </script>
      </Helmet>
            <Navbar />
            <div className="w-[100%] p-4 bg-[#F5F5F5] text-center">
                <div className="flex justify-center item-center">
                    <p className="lg:text-[15px] text-[9px] cursor-pointer text-[#C4C4C4] font-medium" onClick={home}>
                        Home
                    </p>
                    <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
                    <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] font-medium">
                        By Industry
                    </p>
                    <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
                    <p className="lg:text-[15px] text-[9px] font-medium">
                        Beauty and Cosmetics Custom Boxes
                    </p>
                </div>
            </div>
            <div>
                <div className='text-black lg:w-[90%] md:w-[90%] w-[90%] mx-auto p-8 mt-4' data-aos="fade-right">
                    <h1 className='font-bold xl:text-[30px] lg:text-[30px] md:text-[30px] text-[25px] mt-4'>Discover Our Beauty and Cosmetics Custom boxes</h1>
                    <p className='mt-6'>
                    Do you want to get custom beauty and cosmetic boxes to enhance your marketing purposes and secure your products? At One Step Custom Boxes, we understand that the packaging is as essential as the product. Our custom beauty and cosmetic boxes are designed to enhance your brand awareness and product marketing. Not only this, but they will secure the products inside the boxes. The premium packaging will make the product different from the others; hence, customers will be attracted to it, regardless of the product.  </p>
                    <button className='hover:bg-white bg-[#b04d4b] hover:text-[#b04d4b] text-white border-2 border-[#b04d4b] px-8 rounded-lg py-2 mt-5 font-bold' onClick={handle2}>Get a Quote</button>
                </div>
            </div>
            <div className='pb-10'>
                <h2 className='font-bold mt-14 text-center lg:text-[38px] md:text-[35px] text-[25px]'>Our Products</h2>
                <YourComponent prod={prod} />
            </div>

            <div className='2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-1'>
      <div className='2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-4 py-6'>
        {/* <h3 className='text-[17px] font-medium'>
          Do you want to get custom beauty and cosmetic boxes to enhance your marketing purposes and secure your products? At One Step Custom Boxes, we understand that the packaging is as essential as the product. Our custom beauty and cosmetic boxes are designed to enhance your brand awareness and product marketing. Not only this, but they will secure the products inside the boxes. The premium packaging will make the product different from the others; hence, customers will be attracted to it, regardless of the product.
        </h3> */}
        <h2 className='text-[20px] mt-6 font-bold'>
          Why Choose Our Custom Beauty and Cosmetics Boxes?
        </h2>
      </div>

      <div className='2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-4 pb-10'>
        <h3 className='text-[18px] font-medium mb-4'>
          Here is why you should choose custom beauty and cosmetic boxes:
        </h3>
        <ol className='list-decimal pl-6'>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Tailored to Your Brand:</strong> Your packaging should be as unique as your brand. We offer fully customizable boxes tailored to reflect your brand’s identity. From size and shape to colour schemes and finishes, we ensure your packaging perfectly represents your brand's vision.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Premium Quality Materials:</strong> We use only the highest-quality materials to ensure your products are protected and presented in the best possible way. These include durable materials like corrugated cardboard, rigid paperboard, and eco-friendly Kraft paper, with options for specialty coatings and laminations. In addition to ensuring the product’s safety, this sort of material will make your product unique from the others in the market.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Versatile Designs:</strong> Whether you're packaging lipsticks, perfumes, skincare products, or other beauty items, we offer versatile design options to suit all products. However, we offer free design options to our customers. Our team of experts can help you create packaging that highlights your products' unique features and design.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Affordable Then Market:</strong> We offer quality packaging at the most affordable price in the UK. If you order today, you will get 25% off on various packaging solutions.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Fast & Nearly Free Delivery:</strong> After the order is placed, your unique packages will be manufactured within one day, and the packages will be delivered to your doorstep within a short time of 7 to 8 days. Apart from the fast and secure delivery, we offer free delivery over an order of 300 GBP.
          </li>
        </ol>

        <h3 className='text-[18px] font-medium mt-6 mb-4'>
          How Does It Work?
        </h3>
        <ol className='list-decimal pl-6'>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Quote & Consultation:</strong> We will start understanding your brand and product requirements. Our team will work closely with you to create a packaging solution that aligns with your vision and goals.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Design:</strong> At the One Step Custom Boxes, we offer free designs for your custom boxes. Our skilled designers will create mock-ups and prototypes to give you a clear idea of how your packaging will look. Not only this, we offer unlimited revisions to ensure the final design meets your expectations.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Production:</strong> We instantly move on to production once the design is finalized. We use state-of-the-art technology to produce your boxes with precision.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Delivery:</strong> We deliver custom boxes to your doorstep within 7-8 days. We offer flexible shipping options to suit your business needs.
          </li>
        </ol>

        <h3 className='text-[17px] font-medium mt-6'>
          Are you ready to transform your business to the next level with custom packaging? Contact One Step Custom Boxes via mail or phone today to discuss your packaging needs and discover how we can help you create the perfect boxes for your products.
        </h3>
      </div>
    </div>
            <div className="bg-[#191a18]  py-10">
<div className="w-[95%]  mx-auto">
<div className="flex flex-wrap ">
{Data.map(item => (
<div className="p-4  lg:w-[32%] md:w-1/3  mt-10" key={item.id} data-aos="fade-up">
  <div className="flex flex-col items-center h-full px-6 py-6 bg-white rounded-lg shadow-lg">
    <img
      src={item.icon} // Assuming icon is a path to an image file
      alt={`Icon for ${item.title}`}
      loading="lazy"
      className="mb-3 w-20 h-20 mt-[-60px]"
    />
    <h3 className="text-[18px] text-center font-bold mb-4">{item.title}</h3>
    <p className="leading-relaxed text-center text-[15px] mb-4">{item.content}</p>
  </div>
</div>
))}
</div>
</div>
</div>
            <div className='lg:pb-[5%] md:pb-[5%] pb-[7%] text-white' style={{ backgroundImage: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/images/backr.png)", backgroundSize: "100% 100%" }}>
                <h2 className='lg:text-[35px] md:text-[35px] text-[25px] text-center font-bold lg:pt-[5%] md:pt-[5%] pt-[7%]'>Quality Custom Boxes, Affordable Prices</h2>
                <p className='text-center lg:text-[15px] md:text-[15px] text-[14px] mt-[20px]  m-2 ' style={{ lineHeight: "1.1" }}>From your order to your front door, our team manages it all to make things simpler for you</p>
                <div className='flex lg:w-[31%] md:w-[31%] w-[100%]  lg:mt-[30px] md:mt-[2%] mt-[5%] mx-auto'>
                    <button className='border-2 text-[12px] mx-auto rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handlecontact}>Contact Us Today</button>
                    <button className='border-2 text-[12px] mx-auto lg:ml-10 md:ml-10 rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handleportfolio}>Get Free Quote</button>
                </div>
            </div>
            <div className='bg-white p-7'>
                <div className='flex items-center justify-between'>
                    <h2 className='font-bold lg:text-[30px] md:text-[30px] text-[20px]'>Give us a <span className='text-[#fe7d6a]'> Feedback</span></h2>
                    <button className='bg-[#fe7d6a] lg:text-[17px] md:text-[17px] text-[13px] text-white font-bold lg:py-2 md:py-2 py-2 lg:px-4 md:px-4 px-2 rounded-lg' onClick={reviewclick}> Write a Review</button>
                </div>
            </div>
            <Footer />
        </div>
    );
}
