import React,{useEffect,useState} from 'react'
import { Helmet } from 'react-helmet';
import Navbar from '../Components/Navbar'
import {  Section2 } from "../Data/Homepagedata";
import {  Data } from "../Data/Mystyledata";
import YourComponent from './YourComponent';
import Footer from '../Components/Footer';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';



export default function ByTages() {
  
  const navigate = useNavigate()
  const handlecontact = () => {
    navigate('/contact')
    window.scrollTo(0, 0);
    };
    const handleportfolio = () => {
      navigate('/request-quote')
      window.scrollTo(0, 0);
      };
  
const reviewclick=()=>{
    navigate(`/feedback`)
}
    
  const handleNavigate = () => {
navigate('/request-quote')
window.scrollTo(0, 0);
};


const [prod, setProd] = useState([]);
const [section, setSection] = useState([]);
useEffect(() => {  
  axios.get('https://crm.onestepcustomboxes.co.uk/api/tags')
      .then(response => {
          const data = response.data;
          console.log('Fetched data:', data.pro);

          // Adjust the image URLs
          const modifiedPro = data.pro.map(product => ({
              ...product,
              image: `https://crm.onestepcustomboxes.co.uk${product.image}`
          }));

          setProd(modifiedPro);
          AOS.init({
            duration: 1000, // Adjust the duration as needed
          });
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
  setSection(Section2);
}, []);


const home=()=>{
  navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <div>
        <Helmet>
        <title>Premium Custom Clothing Tags for Branding</title>
        <meta name="Description" content="Elevate your brand with our custom clothing tags. Designed for quality and style, they enhance your apparel’s presentation and boost brand recognition effortlessly." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/clothing-tags'/>
  <meta name="robots" content="noindex" />

      </Helmet>
        <Navbar/>
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] cursor-pointer font-medium " onClick={home}>
              Home
            </p>
             <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px] font-medium">
           By Tages
            </p>
          </div>
        </div>
        <div className=' xl:h-[370px] lg:h-[400px] lg:flex md:flex hidden md:h-[380px] h-[300px] 2xl:w-[70%] xl:w-[95%] lg:w-[80%] md:w-[80%] w-[90%] mx-auto rounded-[80px]  mx-auto mt-10' style={{backgroundImage:"url(/images/BySwingtagsBanner.png" , backgroundSize:"100% 100%"}} data-aos="zoom-in">
        <div className='w-[90%]  h-[100%] mx-auto flex justify-between items-center ' >
<div className='lg:w-[50%] md:w-[32%] w-[100%] flex flex-col justify-end h-[90%] p-4 lg:mt-0 md:mt-0 mt-5 mb-5' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" >
   {/* <h2 className='lg:text-[35px] mt-2 md:text-[35px] text-[25px]  font-bold' style={{lineHeight:"1.1"}}>Discover the <span className='text-[#fe7d6a]'><br/> top quality </span>  Material</h2> */}
   {/* <p className='lg:mt-8 font-medium md:mt-8 mt-4'>We are proud to be a top UK manufacturer, providing you with high-quality materials for your custom boxes.</p> */}
   <button className='bg-[#b04d4b] text-white hover:bg-white hover:text-[#b04d4b] lg:w-[35%] md:w-[35%] w-[60%] font-bold lg:py-3 md:py-4 py-3 rounded-lg'  onClick={handleNavigate} >Get a Quote</button>
    </div>
</div>

        </div>
        <div className='xl:h-[400px] lg:h-[400px] lg:hidden md:hidden flex mb-[50px] md:h-[380px] h-[auto] p-1 xl:w-[90%] lg:w-[80%] md:w-[80%] w-[90%] mx-auto rounded-[80px]  mx-auto mt-10 bg-[#f8e7d6]'>
        <div className='w-[90%]  h-[100%] mx-auto flex justify-between items-center '>
<div className='lg:w-[50%] md:w-[32%] w-[100%]   flex flex-col lg:items-start items-center p-4 lg:mt-0 md:mt-0 mt-5 mb-5' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" >
   <h1 className='lg:text-[35px] mt-2 md:text-[35px] text-[25px]  font-bold' style={{lineHeight:"1.1"}}>"Custom <span className='text-[#fe7d6a]'> printed tags </span>  in diverse shapes and  <span className='text-[#fe7d6a]'> styles avaliable "</span></h1>
   <p className='lg:mt-8 font-medium md:mt-8 mt-4'>High-quality, personalize tags perfect for all needs, Fast turnaround and free  design assistance. Enhance your  branding today.</p>
   <button className='bg-[#b04d4b] text-white hover:bg-white hover:text-[#b04d4b] lg:w-[35%] md:w-[35%] w-[60%]  lg:mt-8 md:mt-8 mt-6 font-bold lg:py-3 md:py-4 py-3 rounded-lg'  onClick={handleNavigate} >Get a Quote</button>
     </div>
</div>

        </div>
        {/* ---------2-------------- */}
        <div className="mx-auto " style={{ fontFamily: "montserrat" }}>
          <div className="flex flex-wrap  lg:mt-7 md:mt-5 mt-4 lg:w-[69%] md:w-[70%] w-[85%] mb-8 mx-auto">
            {section.map((data) => (
              <div className="lg:w-[16%]  md:w-[33%] w-[50%] lg:mb-0 md:mb-10 mb-10 flex flex-col items-center" data-aos="fade-up">
                <div
                  className="lg:text-[45px] md:text-[50px] text-[40px] p-2 rounded-full border"
                  style={{ border: "3px solid #fe7d6a" }}
                >
                  {data.icon}
                </div>
                <p
                  className="text-center mt-2 text-[14px]"
                  style={{ fontFamily: "montserrat", fontWeight: "550" }}
                >
                  {data.text1} <br /> {data.text2}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* ----------------3--------------------- */}
        {/* <div className=' mb-10'>
            <h2 className=' text-center font-bold mt-[60px] lg:text-[30px] md:text-[30px] text-[25px]'>Browse The Products</h2>
            <p className='text-center  mt-3 lg:text-[15px] md:text-[15px] m-3 text-[13px]'>Look on some of the famous Products in United Kingdom</p>
            <div className='lg:w-[85%] md:w-[85%] w-[100%] mx-auto mt-10 flex flex-wrap justify-around' data-aos="fade-up">
                <div className='lg:w-[30%] md:w-[30%] mt-10 w-[47%]'>
                    <img className='w-[100%] lg:h-[400px] md:h-[400px] h-[250px]' src='/images/mystyle/F.jpg' alt='S022'/>
                    <h2 className='font-bold text-center text-[25px] '>S022</h2>
                </div>
                <div className='lg:w-[30%] md:w-[30%] mt-10 w-[47%]'>
                    <img className='w-[100%] lg:h-[400px] md:h-[400px] h-[250px]' src='/images/mystyle/G.jpg' alt='S023'/>
                    <h2 className='font-bold text-center text-[25px] '>S023</h2>
                </div>
                <div className='lg:w-[30%] hidden lg:flex md:flex flex-col md:w-[30%] mt-10 w-[100%]'>
                    <img className='w-[100%] h-[400px]' src='/images/mystyle/H.jpg' alt='S024'/>
                    <h2 className='font-bold text-center text-[25px] '>S024</h2>
                </div>
            </div>

            </div> */}
{/* -----------------Our Products---------------- */}         
         <div className=' pb-10 '>

            <h2 className='font-bold mt-14 text-center lg:text-[38px] md:text-[35px] text-[25px]'>Our Products</h2>
            <YourComponent prod={prod} />
            </div>  


  {/* --------------Furniture-----------------  */}
  <div className="bg-[#191a18]  py-10">
      <div className="w-[95%]  mx-auto">
        <div className="flex flex-wrap ">
          {Data.map(item => (
            <div className="p-4  lg:w-[32%] md:w-1/3  mt-10" key={item.id} data-aos="fade-up">
              <div className="flex flex-col items-center h-full px-6 py-6 bg-white rounded-lg shadow-lg">
                <img
                  src={item.icon} // Assuming icon is a path to an image file
                  alt={`Icon for ${item.title}`}
                  loading="lazy"
                  className="mb-3 w-20 h-20 mt-[-60px]"
                />
                <h3 className="text-[18px] text-center font-bold mb-4">{item.title}</h3>
                <p className="leading-relaxed text-center text-[15px] mb-4">{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

{/* -------------boxes--------------------- */}
<div className='lg:pb-[5%] md:pb-[5%] pb-[7%] text-white' style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/images/backr.png)", backgroundSize:"100% 100%"}}>
<h2 className='lg:text-[35px] md:text-[35px] text-[25px] text-center font-bold lg:pt-[5%] md:pt-[5%] pt-[7%]'>Quality Custom Boxes, Affordable Prices</h2>
<p className='text-center lg:text-[15px] md:text-[15px] text-[14px] mt-[20px]  m-2 ' style={{lineHeight:"1.1"}}>From your order to your front door, our team manages it all to make things simpler for you</p>
<div className='flex lg:w-[31%] md:w-[31%] w-[100%]  lg:mt-[30px] md:mt-[2%] mt-[5%] mx-auto'>
    <button className='border-2 text-[12px] mx-auto rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handlecontact}>Contact Us Today</button>
    <button className='border-2 text-[12px] mx-auto lg:ml-10 md:ml-10 rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handleportfolio}>Get Free Quote</button>

</div>
        </div>
        <div className='bg-white  p-7'>
            <div className='flex items-center justify-between'>
                <h2 className=' font-bold lg:text-[30px] md:text-[30px] text-[20px]'>Give us a <span className='text-[#fe7d6a]'> Feedback</span></h2>
                <button className='bg-[#fe7d6a] lg:text-[17px] md:text-[17px] text-[13px] text-white font-bold lg:py-2 md:py-2 py-2 lg:px-4 md:px-4 px-2 rounded-lg' onClick={reviewclick}> Write a Review</button>
            </div>
        </div>
        <Footer/>
    </div>
  )
}
