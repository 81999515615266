import React,{useState,useEffect,useCallback } from 'react'
import Navbar from "../Components/Navbar";
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaStar } from "react-icons/fa";
import 'swiper/css';
import 'swiper/css/pagination';
import axios from 'axios';
import { Autoplay } from 'swiper/modules';
import { Helmet } from 'react-helmet';
import Faqs from './Faq/Faqs';
import Footer from '../Components/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import YourComponent from './YourComponent';
import "./Portfolio.css"
export default function Portfolio() {

  const navigate = useNavigate()
const reviewclick=()=>{
    navigate(`/feedback`)
}
    
const handlecontact = () => {
  navigate('/contact')
  window.scrollTo(0, 0);
  };
  const handleportfolio = () => {
    navigate('/request-quote')
    window.scrollTo(0, 0);
    };

  const handleNavigate = () => {
navigate('/request-quote')
window.scrollTo(0, 0);
};
const handleNavigate1 = () => {
  navigate('/portfolio')
  window.scrollTo(0, 0);
  };
  const [prod, setProd] = useState([]);
  const [prod2, setProd2] = useState([]);
  const [prod3, setProd3] = useState([]);
  const [prod4, setProd4] = useState([]);
  const [prodr, setProdr] = useState([]);
  const fetchData = useCallback(() => {
    Promise.all([
      axios.get('https://crm.onestepcustomboxes.co.uk/api/reviews'),
      axios.get('https://crm.onestepcustomboxes.co.uk/api/material'),
      axios.get('https://crm.onestepcustomboxes.co.uk/api/industry'),
      axios.get('https://crm.onestepcustomboxes.co.uk/api/tags'),
      axios.get('https://crm.onestepcustomboxes.co.uk/api/stickers')
    ])
      .then(([reviewsRes, materialRes, industryRes, tagsRes, stickersRes]) => {
        setProd(modifyData(materialRes.data.pro));
        setProd2(modifyData(industryRes.data.pro));
        setProd3(modifyData(tagsRes.data.pro));
        setProd4(modifyData(stickersRes.data.pro));
        setProdr(modifyData(reviewsRes.data.reviews));
        // setLoading(false);
        AOS.init({ duration: 1000 });
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  const modifyData = (data) => {
    return data.map(item => ({
      ...item,
      image: `https://crm.onestepcustomboxes.co.uk/${item.image}`
    }));
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [activeTab, setActiveTab] = useState("material");

  // if (loading) return <div>Loading...</div>;

  // Define an array of image paths

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Submitting...');
    try {
      const response = await fetch('https://crm.onestepcustomboxes.co.uk/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name, email, message })
      });
      if (response.ok) {
        setStatus('Thank you for contacting');
        setName('');
        setEmail('');
        setMessage('');
      } else {
        setStatus('Failed to send message. Please try again.');
      }
    } catch (error) {
      setStatus('An error occurred. Please try again.');
    }

    setTimeout(() => {
      setStatus('');
    }, 2000);
  }

  const home=()=>{
    navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);

  return (
    <div>
       <Helmet>
        <title> Stunning Portfolio of Custom Packaging Designs</title>
        <meta name="Description" content="Explore our portfolio showcasing innovative custom packaging designs. Discover high-quality, stylish solutions tailored to enhance your brand’s product presentation." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/portfolio'/>
  <meta name="robots" content="index" />
      </Helmet>
<Navbar/>
<div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] font-medium cursor-pointer " onClick={home}>
              Home
            </p>
             <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px] font-medium">
      Portfolio
            </p>
          </div>
        </div>
{/* -------------mainsection--------- */}
<div className='bg 2xl:h-[70vh] xl:h-[70vh] lg:h-[70vh] overflow-hidden md:h-[50vh] h-[50vh] flex flex-col justify-center items-center'>
<div className='text-white text-center xl:w-[60%] lg:w-[60%] md:w-[60%] w-[90%] ' data-aos="zoom-in"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
  <p className='font-medium'>Welcome To <span className='text-[#fe7d6a]'> OnestepCustomBoxes</span></p>
  <h1 className='xl:text-[50px] lg:text-[50px] md:text-[40px] text-center text-[25px] border-b-4 border-[#fe7d6a] text-center mt-2 font-bold xl:w-[73%] lg:w-[73%] md:w-[87%] w-[85%] mx-auto ' style={{lineHeight:"1.2"}}>Build Future with OnestepcustomBoxes</h1>
  <p className='mt-5 font-medium xl:w-[86%] lg:w-[86%] md:w-[86%] w-[100%] mx-auto'>
  In order to empower you to mould your own fate, we at OnestepcustomBoxes blend innovation with quality. Make your brand shine by maximizing its potential.
  </p>
  <div className='mt-5 flex xl:w-[50%] flex justify-around lg:w-[50%] md:w-[73%] w-[100%] mx-auto'>
    <button className='bg-white text-[16px] text-black font-bold xl:py-3 lg:py-3 md:py-3 py-2 rounded-lg xl:px-8 lg:px-8 md:px-8 px-4'  onClick={handleNavigate}>Get a quote</button>
    <button className='bg-[#fe7d6a] text-[16px] text-white ml-5 font-bold xl:py-3 lg:py-3 md:py-3 py-2 rounded-lg xl:px-8 lg:px-8 md:px-8 px-4'   onClick={handleNavigate1}>View Portfolio</button>
  </div>
</div>
</div>
{/* --------------Section2------------ */}
<div className='container  py-4 mx-auto overflow-hidden flex flex-wrap justify-center items-center'>
<div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[90%]' data-aos="fade-right" >
  <div className='xl:p-8 lg:p-8 md:p-8 mt-8'>
  <p className='font-medium'>About <span className='text-[#fe7d6a]'> OnestepCustomBoxes</span></p>
  <h2 className='xl:text-[40px] lg:text-[40px] md:text-[40px] xl:text-[27px] lg:text-[27px] md:text-[27px] text-[20px] font-bold mt-6 ' style={{lineHeight:"1.2"}}>Introduction To The Best <span className='text-[#fe7d6a]'> Custom Packaging </span></h2>
<div className='xl:w-[92%] lg:w-[92%] md:w-[92%] w-[100%] mt-7'>
<p>Prepare to be astounded by our superb custom packaging services, which have been painstakingly designed to surpass all of your expectations. Using visually striking packaging is one way to raise brand awareness and make a lasting impression.</p>
<p className='mt-3'>
Join us as we reimagine excellence and guide you to the height of your brand's expression. Let us be your ally while you rule the world; we provide creative packaging solutions that will impress consumers and propel your products to new heights.
</p>
<button className='bg-[#fe7d6a] text-[16px] mb-5 text-white mt-5 font-bold xl:py-3 lg:py-3 md:py-3 py-2 rounded-lg xl:px-8 lg:px-8 md:px-8 px-5' onClick={handleNavigate}>Get a quote</button>
</div>

  </div>
</div>
<div className='xl:w-[50%] lg:w-[50%] md:w-[50%] w-[100%]  flex justify-center items-center ' data-aos="fade-left">
  <img className='xl:h-[400px] lg:h-[400px] md:h-[400px]  h-[330px]' src='/images/portfo-imag.png' loading="lazy" alt='img'/>
</div>

</div>
{/* ------------------Section3-------------- */}
<div className='bg-[#191a18]'>
<div className='container  py-4 mx-auto overflow-hidden flex flex-wrap justify-between items-center'>
<div className='xl:w-[50%] lg:w-[50%]  md:w-[50%] w-[100%]  flex justify-center items-center '>
<div>
<div className="  py-10">
      <div className="xl:w-[95%] lg:w-[95%] md:w-[100%] w-[95%]  mx-auto" data-aos="fade-right">
        <div className="flex flex-wrap " >
          {/* -----------1--------- */}
            <div className="p-4  lg:w-[50%] md:w-[50%]  mt-10" data-aos="fade-up">
              <div className="flex flex-col items-center h-full px-6 py-6 bg-white rounded-lg shadow-lg">
                <img
                  src="/images/mystyle/box-five.png" // Assuming icon is a path to an image file
                  alt="img"
                  loading="lazy"
                  className="mb-3 w-20 h-20 mt-[-60px]"
                />
                <h3 className="text-[18px] text-center font-bold mb-4">No Die/Plate Fee</h3>
                <p className="leading-relaxed text-center text-[15px] mb-4" style={{lineHeight:"1.2"}}>No more wasteful spending thanks to our no-cost die and plate policy. Forget about these usual costs and unleash your boundless imagination.</p>
              </div>
            </div>
            {/* ------2------------ */}
            <div className="p-4  lg:w-[50%] md:w-[50%]  mt-10" data-aos="fade-up">
              <div className="flex flex-col items-center h-full px-6 py-6 bg-white rounded-lg shadow-lg">
                <img
                  src="/images/mystyle/box-five.png" // Assuming icon is a path to an image file
                  alt="img"
                  loading="lazy"
                  className="mb-3 w-20 h-20 mt-[-60px]"
                />
                <h3 className="text-[18px] text-center font-bold mb-4">No Die/Plate Fee</h3>
                <p className="leading-relaxed text-center text-[15px] mb-4" style={{lineHeight:"1.2"}}>No more wasteful spending thanks to our no-cost die and plate policy. Forget about these usual costs and unleash your boundless imagination.</p>
              </div>
            </div>
            {/* -----------------3---------- */}
            <div className="p-4  lg:w-[50%] md:w-[50%]  mt-10" data-aos="fade-up">
              <div className="flex flex-col items-center h-full px-6 py-6 bg-white rounded-lg shadow-lg">
                <img
                  src="/images/mystyle/box-five.png" // Assuming icon is a path to an image file
                  alt="img"
                  loading="lazy"
                  className="mb-3 w-20 h-20 mt-[-60px]"
                />
                <h3 className="text-[18px] text-center font-bold mb-4">No Die/Plate Fee</h3>
                <p className="leading-relaxed text-center text-[15px] mb-4" style={{lineHeight:"1.2"}}>No more wasteful spending thanks to our no-cost die and plate policy. Forget about these usual costs and unleash your boundless imagination.</p>
              </div>
            </div>
            {/* ---------------------4------------ */}
            <div className="p-4  lg:w-[50%] md:w-[50%]  mt-10" data-aos="fade-up">
              <div className="flex flex-col items-center h-full px-6 py-6 bg-white rounded-lg shadow-lg">
                <img
                  src="/images/mystyle/box-five.png" // Assuming icon is a path to an image file
                  alt="img"
                  loading="lazy"
                  className="mb-3 w-20 h-20 mt-[-60px]"
                />
                <h3 className="text-[18px] text-center font-bold mb-4">No Die/Plate Fee</h3>
                <p className="leading-relaxed text-center text-[15px] mb-4" style={{lineHeight:"1.2"}}>No more wasteful spending thanks to our no-cost die and plate policy. Forget about these usual costs and unleash your boundless imagination.</p>
              </div>
            </div>
        </div>
      </div>
    
    </div>
</div>
</div>
<div className='xl:w-[50%] lg:w-[50%] md:w-[50%] text-white w-[90%] mx-auto' data-aos="fade-left" >
  <div className='xl:p-8 lg:p-8 md:p-8 mt-8'>
  <p className='font-medium'>About <span className='text-[#fe7d6a]'> OnestepCustomBoxes</span></p>
  <h2 className='xl:text-[40px] lg:text-[40px] md:text-[40px] text-[27px] font-bold mt-6 ' style={{lineHeight:"1.2"}}>Providing you the <span className='text-[#fe7d6a]'> Excellent Services</span></h2>
<div className='xl:w-[95%] lg:w-[92%] md:w-[92%] w-[100%] mt-7'>
<p>
Our first-rate services are a direct result of your detailed specifications for custom packaging. Nothing less than the utmost quality and care will be provided to you since we cater to your specific requirements. You may depend on our company to deliver outstanding packing services that surpass your expectations. Look no farther than Onestepcustomboxes for first-rate, purpose-built packaging.
</p>
<p className='mt-3'>
For as long as you work with us, you can rest assured that your packing will be first-rate. Relying on us to deliver unparalleled quality, creativity, and reliability is crucial for your brand's success in today's market.
</p>
<button className='bg-[#fe7d6a] text-[16px] mb-5 text-white mt-5 font-bold xl:py-3 lg:py-3 md:py-3 py-2 rounded-lg xl:px-8 lg:px-8 md:px-8 px-5' onClick={handleNavigate}>Get a quote</button>
</div>

  </div>
</div>

</div>
</div>
{/* -----------------Project------------- */}
<div className="container mx-auto p-2">
      <div className="flex flex-col items-center">
        <div className="mt-6 mb-14 ">
          <div className="flex 2xl:w-[33%] w-[32%] justify-between">
            <button onClick={() => setActiveTab("material")} className={`tab-btn rounded-3xl hover:border-2 hover:border-red-500 px-5 py-2 px-4 xl:font-bold lg:font-bold md:font-bold font-medium  ${activeTab === "material" ? "bg-[#e36956] text-white font-bold" : " bg-white text-[#e36956] border-2 border-transparent border-[#e36956] font-medium"}`}>
             Material
            </button>
            <button onClick={() => setActiveTab("printing" )} className={`tab-btn rounded-3xl hover:border-2 hover:border-red-500 px-5 py-2  px-4 xl:font-bold lg:font-bold md:font-bold font-medium  ${activeTab === "printing"  ? "bg-[#e36956] text-white font-bold" : "bg-white text-[#e36956] border-2 border-transparent border-[#e36956]  font-medium"}`}>
             Style
            </button>
            <button onClick={() => setActiveTab("coating" )} className={`tab-btn rounded-3xl hover:border-2 hover:border-red-500 px-5 py-2 px-4 xl:font-bold lg:font-bold md:font-bold font-medium  ${activeTab === "coating"  ? "bg-[#e36956] text-white font-bold" : " bg-white text-[#e36956] border-2 border-transparent border-[#e36956] font-medium"}`}>
             Tags
            </button>
            <button onClick={() => setActiveTab("special" )} className={`tab-btn rounded-3xl hover:border-2 hover:border-red-500 px-5 py-2 px-4 xl:font-bold lg:font-bold md:font-bold font-medium  ${activeTab === "special"  ? "bg-[#e36956] text-white font-bold" : " bg-white text-[#e36956] border-2 border-transparent border-[#e36956] font-medium"}`}>
             Sticker
            </button>
            {/* Add more buttons for other tabs */}
          </div>
          <div className="bg-white p-5">
            {activeTab === "material" && <YourComponent prod={prod} />}
            {activeTab === "printing" && <YourComponent prod={prod2} />}
            {activeTab === "coating" && <YourComponent prod={prod3} />}
            {activeTab === "special" && <YourComponent prod={prod4} />}
            {/* Add more tab contents here */}
          </div>
        </div>
      </div>
    </div>

{/* -------------------Customer Feedback------------- */}
<div className='bg-black'>
  <div className='container  w-[95%] mx-auto py-16' >
    <div className='text-white'>
      <p>Customer <span className='text-[#fe7d6a]'> Feedback</span></p>
      <h2 className='font-bold xl:text-[40px] lg:text-[40px] md:text-[40px] text-[26px] mt-5' style={{lineHeight:"1.2"}}>
      What Our <span className='text-[#fe7d6a]'>  Clients <br/> </span> Have to Say
      </h2>
    </div>
    <div className='py-10 w-[90%] mx-auto' >
    <Swiper
    data-aos="fade-up"
       autoplay={{
        delay: 2500, // specify the delay in milliseconds between slides
        disableOnInteraction: false, // set to false if you want autoplay to continue even when user interacts with the swiper
      }}
       slidesPerView={4}
        spaceBetween={30}
        centeredSlides={true}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
        modules={[Autoplay]}
        className="mySwiper "
      >
{prodr.map((review, index) => (
        <SwiperSlide key={index} className='bg-white rounded-xl'>
          <div className='flex justify-center p-5 items-center flex-col'>
            <h2 className='font-medium'>{review.name}</h2>
            <div className='flex mt-1'>
              {review.stars !== null && review.stars !== undefined ? (
                // Render stars based on the number provided in review.stars
                [...Array(parseInt(review.stars))].map((_, starIndex) => (
                  <FaStar key={starIndex} className='mr-2 text-[#fe7d6a]' />
                ))
              ) : (
                <p>No star rating provided</p>
              )}
            </div>
            <p className='text-[15px] text-center mt-3' style={{ lineHeight: "1.2" }}>
              {review.review}
            </p>
          </div>
        </SwiperSlide>
      ))}
      </Swiper>
    </div>
  </div>

</div>

{/* ------------------FAQ--------------- */}
<div className='py-8'>
  <div>
    <h2 className='xl:text-[37px] lg:text-[37px] md:text-[37px] text-[25px] xl:mt-10 lg:mt-10 md:mt-10 mt-7 xl:mb-10 lg:mb-10 md:mb-10 mb-7 xl:w-[45%] lg:w-[45%] md:w-[35%] w-[90%] text-center mx-auto font-bold'>
    Frequently Asked <span className='text-[#fe7d6a]'> Questions </span> and Answers
    </h2>
  </div>
  <div data-aos="zoom-in">
  <Faqs />
  </div>
</div>
{/* ----------------------Contact US------------ */}
<div>
<div className='w-[95%]  xl:h-auto overflow-hidden lg:h-auto md:h-auto   flex flex-wrap justify-between lg:pt-16 md:pt-16 pt-6 pb-10  mx-auto'>
    <div className='lg:w-[59%] md:w-[59%]  w-[100%]  ' >
     <div className='ml-6 xl:w-[60%] lg:w-[60%] md:w-[60%] w-[90%] '>
     <h2 className='font-bold xl:text-[40px] lg:text-[40px] md:text-[40px] text-[25px]'>
      Contact Us & We Can <span className='text-[#fe7d6a]'> Work Together </span>
      </h2>
      <p className='mt-6' >
      Get in touch with us today and let's collaborate to bring your custom packaging vision to life.
      </p>
     </div>
     <form className="lg:w-[80%] md:w-[80%] w-[90%] lg:mt-0 md:mt-0 mt-8 mx-auto shadow-2xl p-[6%]" data-aos="fade-left" onSubmit={handleSubmit}>
            <div className="mb-5">
              <label htmlFor="name" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">Name</label>
              <input 
                type="text" 
                id="name" 
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" 
                placeholder="Your Name" 
                required 
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-5">
              <label htmlFor="email" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">Email address</label>
              <input 
                type="email" 
                id="email" 
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" 
                placeholder="example@gmail.com" 
                required 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-5">
              <label htmlFor="message" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">MESSAGE</label>
              <textarea 
                id="message" 
                className="shadow-sm bg-gray-50 h-[110px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light" 
                placeholder="Type your message here" 
                required 
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          {status && <p className="mt-4 text-center bg-green-600 text-white mb-3 p-2 font-bold">{status}</p>}
            <button type="submit" className="text-white bg-[#fe7d6a] hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
          </form>

    </div>
    <div className='lg:w-[40%] md:w-[36%] w-[100%] xl:flex lg:flex md:flex hidden justify-center items-center relative  mb-[-45px]  xl:ml-3 md:ml-3 lg:ml-3 ml-0' data-aos="fade-left">
 <img src='/images/girl-cont.png' loading="lazy" className='xl:h-[80%] lg:h-[80%] md:h-[80%] h-[400px] absolute bottom-1' alt='img'/>
    </div>
    </div>
</div>
{/* ------Footer----- */}
<div className='lg:pb-[5%] md:pb-[5%] pb-[7%] text-white' style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/images/backr.png)", backgroundSize:"100% 100%"}}>
<h2 className='lg:text-[35px] md:text-[35px] text-[20px] text-center font-bold lg:pt-[5%] md:pt-[5%] pt-[7%]'>Quality Custom Boxes, Affordable Prices</h2>
<p className='text-center lg:text-[15px] md:text-[15px] text-[13px] mt-[15px]  m-2 ' style={{lineHeight:"1.1"}}>From your order to your front door, our team manages it all to make things simpler for you</p>
<div className='flex lg:w-[31%] md:w-[31%] w-[100%]  pb-2 lg:mt-[30px] md:mt-[2%] mt-[5%] mx-auto'>
    <button className='border-2 lg:text-[12px] md:text-[12px] text-[10px] mx-auto rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handlecontact}>Contact Us Today</button>
    <button className='border-2 lg:text-[12px] md:text-[12px] text-[10px] mx-auto lg:ml-10 md:ml-10 rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handleportfolio}>Get Free Quote</button>

</div>
        </div>
        <div className='bg-white  p-7'>
            <div className='flex items-center justify-between'>
                <h2 className=' font-bold lg:text-[30px] md:text-[30px] text-[20px]'>Give us a <span className='text-[#fe7d6a]'> Feedback</span></h2>
                <button className='bg-[#fe7d6a] lg:text-[17px] md:text-[17px] text-[13px] text-white font-bold lg:py-2 md:py-2 py-2 lg:px-4 md:px-4 px-2 rounded-lg' onClick={reviewclick}> Write a Review</button>
            </div>
        </div>

        <Footer/>
    </div>
  )
}