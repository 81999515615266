import React,{useEffect,useState} from 'react'
import { Helmet } from 'react-helmet';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbar from '../Components/Navbar'
import {  Section2 } from "../Data/Homepagedata";
import {   Data } from "../Data/Mystyledata";
import YourComponent from './YourComponent';
import Footer from '../Components/Footer';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

export default function Material() {


  const navigate = useNavigate()
const reviewclick=()=>{
    navigate(`/feedback`)
}
    
const handlecontact = () => {
  navigate('/contact')
  window.scrollTo(0, 0);
  };
  const handleportfolio = () => {
    navigate('/request-quote')
    window.scrollTo(0, 0);
    };

  const handleNavigate = () => {
navigate('/request-quote')
window.scrollTo(0, 0);
};
const handleClick =()=>{
  navigate('/contact')
window.scrollTo(0, 0);
}


    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 3000
    // };
  const [prod, setProd] = React.useState([]);
      const [section, setSection] = useState([]);
    useEffect(() => {
      setSection(Section2);

      axios.get('https://crm.onestepcustomboxes.co.uk/api/material')
          .then(response => {
              const data = response.data;
              // console.log('Fetched data:', data.pro);
              // Adjust the image URLs
              const modifiedPro = data.pro.map(product => ({
                  ...product,
                  image: `https://crm.onestepcustomboxes.co.uk${product.image}`
              }));
              setProd(modifiedPro);
              AOS.init({
                duration: 1000, // Adjust the duration as needed
              });
          })
          .catch(error => {
              console.error('Error fetching data:', error);
          });
    }, []);


    const home=()=>{
      navigate("/")
  }
  useEffect(() => {
    // Remove data-react-helmet attribute from the canonical link
    const canonicalLink = document.querySelector("link[rel='canonical']");
    if (canonicalLink) {
      canonicalLink.removeAttribute("data-react-helmet");
    }
  }, []);
  return (
    <div>
      <Helmet>
        <title>Get Your Premium Custom Boxes by Material</title>
        <meta name="Description" content="Discover our range of custom boxes made from high-quality materials. Perfect for any product, our boxes ensure durability, protection, and great presentation." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/material'/>
  <meta name="robots" content="noindex" />
      </Helmet>
        <Navbar/>
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] cursor-pointer font-medium " onClick={home}>
              Home
            </p>
             <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px]  font-medium">
           By Material
            </p>
          </div>
        </div>
        <div className=' xl:h-[370px] lg:h-[400px] lg:flex md:flex hidden md:h-[380px] h-[300px] 2xl:w-[70%] xl:w-[95%] lg:w-[80%] md:w-[80%] w-[90%] mx-auto rounded-[80px]  mx-auto mt-10' style={{backgroundImage:"url(/images/ByMaterialBanner.jpg" , backgroundSize:"100% 100%"}} data-aos="zoom-in">
        <div className='w-[90%]  h-[100%] mx-auto flex justify-between items-center ' >
<div className='lg:w-[50%] md:w-[32%] w-[100%] flex flex-col justify-end h-[90%] p-4 lg:mt-0 md:mt-0 mt-5 mb-5' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" >
   {/* <h2 className='lg:text-[35px] mt-2 md:text-[35px] text-[25px]  font-bold' style={{lineHeight:"1.1"}}>Discover the <span className='text-[#fe7d6a]'><br/> top quality </span>  Material</h2> */}
   {/* <p className='lg:mt-8 font-medium md:mt-8 mt-4'>We are proud to be a top UK manufacturer, providing you with high-quality materials for your custom boxes.</p> */}
   <button className='bg-[#b04d4b] text-white hover:bg-white hover:text-[#b04d4b] lg:w-[35%] md:w-[35%] w-[60%] font-bold lg:py-3 md:py-4 py-3 rounded-lg'  onClick={handleNavigate} >Get a Quote</button>
    </div>
</div>
        </div>
        <div className='xl:h-[400px] lg:h-[400px] lg:hidden md:hidden flex mb-[50px] md:h-[350px] h-auto p-1 xl:w-[90%] lg:w-[80%] md:w-[80%] w-[90%] mx-auto rounded-[80px]  mx-auto mt-10 bg-[#f8e7d6]'>
        <div className='w-[90%]  h-[100%] mx-auto flex justify-between items-center '>
<div className='lg:w-[50%] md:w-[32%] w-[100%]   flex flex-col lg:items-start items-center p-4 lg:mt-0 md:mt-0 mt-5 mb-5' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" >
   <h1 className='lg:text-[35px] mt-2 md:text-[35px] text-[25px]  font-bold' style={{lineHeight:"1.1"}}>Discover premium <span className='text-[#fe7d6a]'><br/> Packaging solutions </span> with our extensive <span className='text-[#fe7d6a]'><br/> Cardboard Stock.</span></h1>
   <p className='lg:mt-8 font-medium md:mt-8 mt-4'>High-quality, customizable options for all your packaging needs. Fast turnaround and free design assistance! </p>
   <button className='bg-[#b04d4b] text-white hover:bg-white hover:text-[#b04d4b] lg:w-[35%] md:w-[35%] w-[60%]  lg:mt-8 md:mt-8 mt-6 font-bold lg:py-3 md:py-4 py-3 rounded-lg'  onClick={handleNavigate} >Get a Quote</button>
    </div>
</div>

        </div>
        {/* ---------2-------------- */}
        <div className="mx-auto " style={{ fontFamily: "montserrat" }}>
          <div className="flex flex-wrap  lg:mt-7 md:mt-5 mt-4 lg:w-[69%] md:w-[70%] w-[85%] mb-8 mx-auto">
            {section.map((data) => (
              <div className="lg:w-[16%]  md:w-[33%] w-[50%] lg:mb-0 md:mb-10 mb-10 flex flex-col items-center" data-aos="fade-up">
                <div
                  className="lg:text-[45px] md:text-[50px] text-[40px] p-2 rounded-full border"
                  style={{ border: "3px solid #fe7d6a" }}
                >
                  {data.icon}
                </div>
                <p
                  className="text-center mt-2 text-[14px]"
                  style={{ fontFamily: "montserrat", fontWeight: "550" }}
                >
                  {data.text1} <br /> {data.text2}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* ----------------3--------------------- */}
<div className="bg-[#fcf9f4] p-8" >
        <div className="2xl:w-[80%] lg:w-[95%] md:w-[95%] w-[100%] mx-auto lg:p-8 md:p-8 flex flex-wrap justify-between items-center" data-aos="fade-right">
           <div  className='lg:w-[43%] md:w-[43%] w-[100%] '>
           <h2 className="lg:text-[35px] md:text-[35px] text-[24px] lg:w-[80%] md:w-[80%] font-bold mb-4" style={{lineHeight:"1.1"}}>50+ Premium quality Material</h2>
            <p className="xl:text-lg lg:text-lg md:text-lg text-[16px] mb-6">Providing you the Best quality Material for you Packaging solution.</p>
            <button className="bg-[#b04d4b]  text-white font-bold py-3 px-4 rounded xl:mt-4 lg:mt-4 md:mt-4 mt-2">Show More</button>
         
           </div>
              <div className='2xl:w-[45%] lg:w-[50%] md:w-[50%] w-[100%] lg:mt-0 md:mt-0 mt-8 '  data-aos="fade-left">
            {/* <Slider {...settings}> */}
                <div className='2xl:h-[320px] lg:h-[350px] md:h-[400px] h-[180px] '>
                    <img src="/images/banner.png" loading="lazy" alt="Room 1" onClick={handleClick} className="cursor-pointer w-[100%]  h-[100%] rounded-2xl"/>
                </div>
                {/* <div className='lg:h-[400px] md:h-[400px] h-[250px] '>
                    <img src="/images/material/sample2.jpeg" alt="Room 1" className="w-[100%] h-[100%]  rounded-2xl" />
                </div>
                <div className='lg:h-[400px] md:h-[400px] h-[250px] '>
                    <img src="/images/material/sample3.jpeg" alt="Room 1" className="w-[100%] h-[100%]  rounded-2xl" />
                </div> */}
            {/* </Slider> */}
            </div>
        </div>
        </div>
{/* -----------------Our Products---------------- */}         
         <div className=' pb-10 ' >

            <h2 className='font-bold mt-14 text-center lg:text-[38px] md:text-[35px] text-[25px]'>Our Materials</h2>
            <YourComponent prod={prod}  />
            </div>  


  {/* --------------Furniture-----------------  */}
  <div className="bg-[#191a18]  py-10">
      <div className="w-[95%]  mx-auto">
        <div className="flex flex-wrap " >
          {Data.map(item => (
            <div className="p-4  lg:w-[32%] md:w-1/3  mt-10" key={item.id} data-aos="fade-up">
              <div className="flex flex-col items-center h-full px-6 py-6 bg-white rounded-lg shadow-lg">
                <img
                  src={item.icon} // Assuming icon is a path to an image file
                  alt={`Icon for ${item.title}`}
                  loading="lazy"
                  className="mb-3 w-20 h-20 mt-[-60px]"
                />
                <h3 className="text-[18px] text-center font-bold mb-4">{item.title}</h3>
                <p className="leading-relaxed text-center text-[15px] mb-4">{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

{/* -------------boxes--------------------- */}
<div className='lg:pb-[5%] md:pb-[5%] pb-[7%] text-white' style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/images/backr.png)", backgroundSize:"100% 100%"}}>
<h2 className='lg:text-[35px] md:text-[35px] text-[25px] text-center font-bold lg:pt-[5%] md:pt-[5%] pt-[7%]'>Quality Custom Boxes, Affordable Prices</h2>
<p className='text-center lg:text-[15px] md:text-[15px] text-[14px] mt-[20px]  m-2 ' style={{lineHeight:"1.1"}}>From your order to your front door, our team manages it all to make things simpler for you</p>
<div className='flex lg:w-[31%] md:w-[31%] w-[100%]  lg:mt-[30px] md:mt-[2%] mt-[5%] mx-auto'>
    <button className='border-2 text-[12px] mx-auto rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handlecontact}>Contact Us Today</button>
    <button className='border-2 text-[12px] mx-auto lg:ml-10 md:ml-10 rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handleportfolio}>Get Free Quote</button>

</div>
        </div>
        <div className='bg-white  p-7'>
            <div className='flex items-center justify-between'>
                <h2 className=' font-bold lg:text-[30px] md:text-[30px] text-[20px]'>Give us a <span className='text-[#fe7d6a]'> Feedback</span></h2>
                <button className='bg-[#fe7d6a] lg:text-[17px] md:text-[17px] text-[13px] text-white font-bold lg:py-2 md:py-2 py-2 lg:px-4 md:px-4 px-2 rounded-lg' onClick={reviewclick}> Write a Review</button>
            </div>
        </div>
        <Footer/>
    </div>
  )
}
