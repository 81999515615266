import React, { useState, useRef, useEffect } from "react";
import "./style.css";
import { FiPlus } from "react-icons/fi";
import { faq } from "./Faqdata";

import AOS from 'aos';
import 'aos/dist/aos.css';

const Faqs = () => {
  const [active, setActive] = useState(Array(faq.length).fill(false));

  const contentRefs = useRef(faq.map(() => React.createRef()));

  useEffect(() => {

    AOS.init({
      duration: 1000, // Adjust the duration as needed
    });

    contentRefs.current.forEach((ref, index) => {
      ref.current.style.maxHeight = active[index]
        ? `${ref.current.scrollHeight}px`
        : "0px";
    });
  }, [active]);

  const toggleAccordion = (index) => {
    setActive((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  return (
    <>
    <div className="xl:m-20  lg:m-20 md:m-20 m-5  ">

      {faq.map((item, index) => (
        <div key={index} className="App mt-5" data-aos="fade-zoom-in">
          <button
            className={`question-section ${active[index] ? "active" : ""}`}
            onClick={() => toggleAccordion(index)}
          >
            <div className="question-align">
              <h2 className="question-style font-bold xl:text-[21px] lg:text-[21px] md:text-[21px] text-[17px]">
                {item.Question}
              </h2>
              <FiPlus
                className={active[index] ? "question-icon rotate" : "question-icon"}
              />
            </div>
            <div
              ref={contentRefs.current[index]}
              className={`answer ${active[index] ? "answer-divider" : ""}`}
            >
              <p className="font-medium">{item.Answer}</p>
            </div>
          </button>
        </div>
      ))}
      </div>
    </>
  );
};

export default Faqs;
