import React,{useEffect,useState} from 'react'
import { Helmet } from 'react-helmet';
import {  Data } from "../../Data/Mystyledata";
import YourComponent from '../YourComponent';
import Navbar from "../../Components/Navbar"
import Footer from '../../Components/Footer';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

export default function Ecommer() {
  const [prod, setProd] = useState([]);
  const navigate = useNavigate()
const reviewclick=()=>{
    navigate(`/feedback`)
}
const handlecontact = () => {
  navigate('/contact')
  window.scrollTo(0, 0);
  };
  const handleportfolio = () => {
    navigate('/request-quote')
    window.scrollTo(0, 0);
    };
    const handle2=()=>{
      navigate("/request-quote")
    }
useEffect(() => {
  axios.get('https://crm.onestepcustomboxes.co.uk/api/e-commerce')
      .then(response => {
          const data = response.data;
          console.log('Fetched data:', data.pro);

          // Adjust the image URLs
          const modifiedPro = data.pro.map(product => ({
              ...product,
              image: `https://crm.onestepcustomboxes.co.uk${product.image}`
          }));

          setProd(modifiedPro);
          AOS.init({
              duration: 1000
          });
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
}, []);   


const home=()=>{
  navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);

  return (
    <div>
         <Helmet>
        <title>Innovative E-commerce Packaging Solutions</title>
        <meta name="Description" content=" Elevate your e-commerce business with our cutting-edge packaging. Designed for durability and style, our packaging ensures safe delivery and a memorable unboxing experience." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/ecommerce-packaging'/>
  <meta name="robots" content="index,follow" />

      </Helmet>
        <Navbar/>
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] font-medium cursor-pointer " onClick={home}>
              Home
            </p>
           <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            {/* Add this line */}
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] font-medium ">
              By Industry
            </p>
           <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px] font-medium">
            Ecommer Custom Boxes
            </p>
          </div>
        </div>
{/* -------------------Read All news------------ */}
<div>
<div className=' text-black lg:w-[90%] md:w-[90%] w-[90%] mx-auto p-8 mt-4' data-aos="fade-right">
  <h1 className='font-bold xl:text-[30px] lg:text-[30px] md:text-[30px] text-[25px] mt-4' >Discover Our Ecommer Custom boxes</h1>
  <p className='mt-6'>
  At One Step Custom Boxes, we understand that in the world of e-commerce, first impressions matter. Your packaging is the first physical touchpoint your customers have with your brand. We provide custom e-commerce boxes that not only protect your products but also engage your customers with your brand.
  </p>
  <button className='hover:bg-white  bg-[#b04d4b] hover:text-[#b04d4b] text-white border-2 border-[#b04d4b] px-8 rounded-lg py-2 mt-5 font-bold'  onClick={handle2}>Get a Quote</button>
  </div> 
</div>
{/* -----------------Products---------------- */}
<div className=' pb-10 '>

<h2 className='font-bold mt-14 text-center lg:text-[38px] md:text-[35px] text-[25px]'>Our Products</h2>
<YourComponent prod={prod} />
</div>  

<div className='2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-1'>
      <div className='2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-4 py-6'>
        {/* <h3 className='text-[17px] font-medium'>
          At One Step Custom Boxes, we understand that in the world of e-commerce, first impressions matter. Your packaging is the first physical touchpoint your customers have with your brand. We provide custom e-commerce boxes that not only protect your products but also engage your customers with your brand.
        </h3> */}
        <h2 className='text-[20px] mt-6 font-bold'>
          Advantages of Custom E-Commerce Boxes
        </h2>
      </div>

      <div className='2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-4 pb-10'>
        <h3 className='text-[17px] font-medium mb-4'>
          Here are the key advantages you will get by choosing Custom E-commerce Boxes:
        </h3>
        <ul className='list-disc pl-6'>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Enhanced Brand Recognition:</strong> Custom e-commerce boxes allow you to showcase your brand’s identity. By incorporating your logo, colours, and messaging, your packaging becomes an extension of your brand, creating a memorable unboxing experience.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Perfect Fit for Products:</strong> If you go with the custom e-commerce boxes, then you will be able to get products with exact dimensions. This ensures your products are secure during transportation, which will minimize the risk of damage.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Cost Efficiency:</strong> By designing boxes that fit your products perfectly, you can reduce the size and weight of your packages, which can save on shipping costs.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Flexibility for Promotional Campaigns:</strong> Custom packaging allows you to easily incorporate special promotions, seasonal designs, or limited-edition themes into your boxes. This flexibility helps you engage customers throughout the year.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Competitive Edge:</strong> Good and quality custom packaging sets your brand apart from your competitors. Hence, unique and well-designed packaging can make your products more luxurious, giving you a competitive advantage.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Professional Appearance:</strong> Custom boxes give your products a polished and professional look. Whether you’re sending out premium products or everyday items, well-designed packaging reflects the quality and care you put into your business, boosting customer confidence and loyalty.
          </li>
        </ul>

        <h2 className='text-[20px] mt-6  mb-4font-bold'>
          Why Choose Our Custom E-Commerce Boxes?
        </h2>

        <ul className='list-disc pl-6'>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Speed and Efficiency:</strong> We pride ourselves on delivering 98% of our orders within 24 hours. Our commitment to efficiency means your custom boxes will be ready and shipped to your doorstep within 7-8 days, ensuring you never fall behind in your operations.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Free Shipping Across the UK:</strong> Enjoy free shipping on all orders, with no hidden charges. Whether you’re a small business or a large enterprise, we’ve got you covered with cost-effective solutions.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Custom Solutions for Every Need:</strong> From size and style to design and material, our custom e-commerce boxes are fully tailored to your requirements. We offer all box styles and custom sizes, starting from 100 boxes, to accommodate both small and large-scale needs. With free design assistance, we ensure your packaging perfectly reflects your brand.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Premium Quality and Finishing Options:</strong> We offer a wide range of paper stock and finishing options to give your boxes a premium look and feel. Choose from gloss or matte lamination, embossing, foiling, and more to create packaging that stands out. Whether it’s a simple design or something more elaborate, our advanced printing techniques, including CMYK, PMS, and spot colours, ensure vibrant and accurate colours.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Hassle-Free and Cost-Effective:</strong> There are no die and plate charges or hidden fees. We believe in transparent pricing and providing our clients with the best value. Our fast turnaround time and free shipping make us the go-to choice for businesses across the UK.
          </li>
          <li className='text-[17px] font-medium mb-4'>
            <strong>Order with Confidence:</strong> We offer flat view proofs, 3D mock-ups, and physical sampling upon request to ensure you’re completely satisfied with the design before we go into production. Your satisfaction is our priority!
          </li>
        </ul>

        <h3 className='text-[17px] font-medium mt-6'>
          Want to protect and give a luxurious look to your e-commerce products? Contact us at <a className='text-[#fe7d6a]' href="mailto:Contact@onestepcustomboxes.co.uk">Contact@onestepcustomboxes.co.uk</a> or call us at <a  className='text-[#fe7d6a]' href="tel:02033320061">02033320061</a> to discuss your custom packaging needs. Let One Step Custom Boxes be your partner in creating packaging that not only protects your products but also enhances your brand’s identity.
        </h3>
      </div>
    </div>
{/* --------------Furniture-----------------  */}
<div className="bg-[#191a18]  py-10">
<div className="w-[95%]  mx-auto">
<div className="flex flex-wrap ">
{Data.map(item => (
<div className="p-4  lg:w-[32%] md:w-1/3  mt-10" key={item.id} data-aos="fade-up">
  <div className="flex flex-col items-center h-full px-6 py-6 bg-white rounded-lg shadow-lg">
    <img
      src={item.icon} // Assuming icon is a path to an image file
      alt={`Icon for ${item.title}`}
      className="mb-3 w-20 h-20 mt-[-60px]"
      loading="lazy"
    />
    <h3 className="text-[18px] text-center font-bold mb-4">{item.title}</h3>
    <p className="leading-relaxed text-center text-[15px] mb-4">{item.content}</p>
  </div>
</div>
))}
</div>
</div>
</div>
{/* ----------2----------- */}
<div className='lg:pb-[5%] md:pb-[5%] pb-[7%] text-white' style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/images/backr.png)", backgroundSize:"100% 100%"}}>
<h2 className='lg:text-[35px] md:text-[35px] text-[25px] text-center font-bold lg:pt-[5%] md:pt-[5%] pt-[7%]'>Quality Custom Boxes, Affordable Prices</h2>
<p className='text-center lg:text-[15px] md:text-[15px] text-[14px] mt-[20px]  m-2 ' style={{lineHeight:"1.1"}}>From your order to your front door, our team manages it all to make things simpler for you</p>
<div className='flex lg:w-[31%] md:w-[31%] w-[100%]  lg:mt-[30px] md:mt-[2%] mt-[5%] mx-auto'>
    <button className='border-2 text-[12px] mx-auto rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handlecontact}>Contact Us Today</button>
    <button className='border-2 text-[12px] mx-auto lg:ml-10 md:ml-10 rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handleportfolio}>Get Free Quote</button>

</div>
        </div>
        <div className='bg-white  p-7'>
            <div className='flex items-center justify-between'>
                <h2 className=' font-bold lg:text-[30px] md:text-[30px] text-[20px]'>Give us a <span className='text-[#fe7d6a]'> Feedback</span></h2>
                <button className='bg-[#fe7d6a] lg:text-[17px] md:text-[17px] text-[13px] text-white font-bold lg:py-2 md:py-2 py-2 lg:px-4 md:px-4 px-2 rounded-lg' onClick={reviewclick}> Write a Review</button>
            </div>
        </div>

    <Footer/>
    </div>
  )
}
