import React,{useEffect} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import MyStyle from "./Pages/MyStyle";
import Material from "./Pages/Material";
import Blog from "./Pages/Blog";
import Beauty from "./Pages/Industry/Beauty";
import QuoteForm from "./Pages/QuoteForm";
import Ecommer from "./Pages/Industry/Ecommer";
import Gifts from "./Pages/Industry/Gifts";
import Medical from "./Pages/Industry/Medical ";
import Beverage from "./Pages/Industry/Beverage"
import Cannabis from "./Pages/Industry/Cannabis"
import Clothing from "./Pages/Industry/Clothing"
import Custom from "./Pages/Industry/Custom"
import Electronics from "./Pages/Industry/Electronics"
import Fastfood from "./Pages/Industry/Fastfood"
import Healthcare from "./Pages/Industry/Healthcare"
import Jewelery from "./Pages/Industry/Jewelery"
import Shoes from "./Pages/Industry/Shoes"
import Supermarkets from "./Pages/Industry/Supermarkets"
import Supplements from "./Pages/Industry/Supplements"
import Portfolio from "./Pages/Portfolio";
import Product from "./Pages/Product";
import HoteItems from "./Pages/HoteItems";
import HelpFaqs from "./Pages/HelpFaqs";
import Privacypolicy from "./Pages/Privacypolicy";
import Termsofservice from "./Pages/Termsofservice";
import ByTages from "./Pages/ByTages";
import ByStickers from "./Pages/ByStickers";
import ArtworkGuidelines from "./Pages/Artwork";
import Marriott from "./Pages/Marriott";
import RoyalAscot from "./Pages/RoyalAscot";
import Termination from "./Pages/Termination";
// import Sample from "./Components/Sample";
import Newquote from "./Pages/newquote"
import Reviewadd from "./Pages/Reviewadd";
import BlogDetail from "./Pages/BlogDetail";
import Thanks from "./Pages/Thanks";
import PageNotFound from "./Pages/PageNotFound";
import Test from "./Pages/Test";

function App() {


  const location = window.location.href; // Get the full URL
  const path = new URL(location).pathname; // Extract the path
  
  // console.log(path,"path"); // This will log: /about-us
  
 
  useEffect(() => {
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/660943781ec1082f04dd304c/1hqa2v43v';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
  }, []);

  
  
  useEffect(() => {
    if (path === '/about-us') {
      window.location.href = '/about';
    }
    if (path === '/supplements') {
      window.location.href = '/supplements-packaging';
    }
  }, []);
 
  


  return (
   <>
    <Router>
      <a
      href="https://wa.me/+447743302942"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 z-10 left-1 bg-none rounded-full p-1  transition-transform duration-300 transform hover:scale-110"
    >
      <img src="/images/download1.webp" alt="WhatsApp" loading="lazy" className="h-16 w-18" />
    </a>
      <Routes>
      <Route path="/" element={<Home />} />
    <Route path="/feedback" element={<Reviewadd />} />
        <Route path="/instant-quote" element={<Newquote/>} />
        <Route path="/t" element={<Test/>} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mystyle" element={<MyStyle />} />
        <Route path="/clothing-tags" element={<ByTages />} />
        <Route path="/stickers-labels" element={<ByStickers />} />
        <Route path="/material" element={<Material />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/thank-you" element={<Thanks />} />

        {/* ----------------------industry----------- */}
        <Route path="/cosmetic-packaging" element={<Beauty />} />
        <Route path="/ecommerce-packaging" element={<Ecommer />} />
        <Route path="/gift-boxes" element={<Gifts />} />
        <Route path="/medical-device-packaging" element={<Medical />} />
        <Route path="/beverage-packaging" element={<Beverage />} />
        <Route path="/cannabis-packaging" element={<Cannabis />} />
        <Route path="/clothing-apparel-boxes" element={<Clothing />} />
        <Route path="/bespoke-packaging-boxes" element={<Custom />} />
        <Route path="/electronics-packaging-box" element={<Electronics />} />
        <Route path="/food-packaging-boxes" element={<Fastfood />} />
        <Route path="/pharmaceutical-packaging" element={<Healthcare />} />
        <Route path="/jewelry-boxes" element={<Jewelery />} />
        <Route path="/shoe-boxes" element={<Shoes />} />
        <Route path="/retail-packaging" element={<Supermarkets />} />
        <Route path="/supplements-packaging" element={<Supplements />} />
        <Route  path="/:catgr/:productName" element={<Product/>} />
        <Route path="/blog/:name" element={<BlogDetail />} />

        <Route path="/hotitems" element={<HoteItems/>} />
        <Route path="/request-quote" element={<QuoteForm />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/faqs" element={<HelpFaqs />} />
        <Route path="/privacy" element={<Privacypolicy />} />
        <Route path="/artwork" element={<ArtworkGuidelines />} />
        <Route path="/harrods" element={<Marriott />} />
        {/* <Route path="/harrods" element={<Harrods />} /> */}
        <Route path="/royalascot" element={<RoyalAscot />} />
        <Route path="/terms-of-services" element={<Termsofservice />} />
        <Route path="/testimonial" element={<Termination />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
   </>
  );
}

export default App;
