// import React,{useState,useEffect,useRef} from 'react';
// import { useLocation } from 'react-router-dom';
// import Navbar from '../Components/Navbar';
// import Footer from '../Components/Footer';
// import DOMPurify from 'dompurify';
// import { Helmet } from 'react-helmet';
// import axios from 'axios';

// import "./BlogDetail.css"
// // import { FaFacebook, FaInstagram, FaLinkedin, FaTwitterSquare, FaPinterest,FaStar } from "react-icons/fa";

// const BlogDetail = () => {
//   const location = useLocation();
//   const { product } = location.state || {};  // Handle case where location.state might be empty
//   const sanitizedHTML = product ? DOMPurify.sanitize(product.descrpt) : '';
//   const sanitizedHTML1 = product ? DOMPurify.sanitize(product.descp) : '';
//   const [useBackup, setUseBackup] = useState(false);

//   const urlWithoutDomain = window.location.pathname + window.location.search + window.location.hash;
//   const urlapi = urlWithoutDomain.slice(6).replace(/-/g, ' ');
//   console.log(urlapi, "urlapi");

//   useEffect(() => {
//     const fetchData = async () => {
//       console.log('Fetching data...');
//       try {
//         console.log('API URL:', `https://crm.onestepcustomboxes.co.uk/api/blog?name=${urlapi}`);
//         const response = await axios.get('https://crm.onestepcustomboxes.co.uk/api/blog', {
//           params: { name: urlapi }
//         });
//         console.log(response.data, "response.data.products");
//         // setProduct(response.data.products); // Uncomment this line if you want to set the product state
//       } catch (error) {
//         console.error('Error hitting the API:', error);
//       }
//     };

//     if (!product) {
//       fetchData();
//     }
//   }, [urlapi, product]);
  
  
//   const handleImageError = () => {
//     setUseBackup(true);
//   };

//   const containerRef = useRef(null);

//   const productimg = product.image[0];
//   const productimg1 = product.image[1];
//   const productimg2 = product.image[2];

//   const formattedName = encodeURIComponent(
//     decodeURIComponent(product.mn)
//       .replace(/\s+/g, '-')
//       .toLowerCase()
//       .replace(/[^a-z0-9\-]/g, '')  // Remove any non-alphanumeric characters except hyphens
//   );

//   useEffect(() => {
  
//     if (containerRef.current) {
//         // Select all p and h2 tags
//         const pTags = containerRef.current.querySelectorAll('p');
//         const h2Tags = containerRef.current.querySelectorAll('h2');
//         const liTags = containerRef.current.querySelectorAll('li');
        
//         // Hide all p tags except the first one
//         pTags.forEach((pTag, index) => {
//             if (index !== 0) {
//                 pTag.style.display = 'none';
//             }
//         });

//         // Hide all h2 tags
//         h2Tags.forEach((h2Tag) => {
//             h2Tag.style.display = 'none';
//         });
//         liTags.forEach((liTag) => {
//           liTag.style.display = 'none';
//       });
//     }
    
// }, [sanitizedHTML,sanitizedHTML1]);
//   return (
//   <div>
//       <Helmet>
//         <title>{product.mn}</title>
//         <meta name="Description" content={product.md} />
//         <link rel="canonical" href={`https://onestepcustomboxes.co.uk/blog/${formattedName}`} />
//        <meta name="robots" content="index" />

//       </Helmet>
//     <Navbar/>
// <div>
// <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
//           <div className="flex justify-center item-center  ">
//             <h2 className="lg:text-[15px] text-[9px] text-[#C4C4C4]  font-medium ">
//               Home
//             </h2>
//              {/* <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
//              <h2 className="lg:text-[15px] text-[9px] text-[#C4C4C4]  font-medium ">
//           Blogs
//             </h2> */}
//             <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
//             <h1 className="lg:text-[15px] text-[9px]  font-medium">
//           {/* {product.name} */}
//           Blogs
//             </h1>
//           </div>
//         </div>
//         <div className="w-[95%] py-14 mx-auto">
//         {product && (
//           <>
//             <div className="lg:w-[95%]  mb-5 mx-auto flex items-center justify-between flex-wrap">
         
//           <div className="lg:w-[45%] w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0" style={{ cursor: "auto" }}>
//                 <h2 className="text-gray-900 text-3xl title-font font-medium mb-1" style={{ cursor: "auto" }}>
//                   {product.name}
//                 </h2>
//                 <div ref={containerRef} className="text-[15px] mt-5" dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
               

//           </div>
//            {!useBackup ? (
//         <img
//           alt={product.name}
//           className="2xl:w-[40%] lg:w-1/2 w-full lg:h-[50vh] md:h-64 h-auto object-cover object-center rounded"
//           src={productimg}
//           loading="lazy"
//           onError={handleImageError}
//           style={{ cursor: 'auto' }}
//         />
//       ) : (
//         <img
//           alt={product.name}
//           loading="lazy"
//           className="2xl:w-[40%] lg:w-1/2 w-full lg:h-[50vh] md:h-64 h-auto object-cover object-center rounded"
//           src={`https://crm.onestepcustomboxes.co.uk${productimg}`}
//           style={{ cursor: 'auto' }}
//         />
//       )}
//             </div>
//             <div className="lg:w-[90%] w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0" style={{ cursor: "auto" }}>            
//                 {/* <p className="text-[15px]">{product.descrpt}</p> */}
//                 <div className="text-[15px]" dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
//               </div>
//               <div className='flex justify-around flex-wrap'>
//       {productimg1 && (
//         <img
//           alt={product.name}
//           loading="lazy"
//           className="2xl:w-[40%] mt-4 lg:w-[500px] w-full lg:h-[40vh] md:h-64 h-auto object-cover object-center rounded"
//           src={`https://crm.onestepcustomboxes.co.uk${productimg1}`}
//           style={{ cursor: 'auto' }}
//         />
//       )}
//       {productimg2 && (
//         <img
//           alt={product.name}
//           loading="lazy"
//           className="2xl:w-[40%] mt-4 lg:w-[500px] w-full lg:h-[40vh] md:h-64 h-auto object-cover object-center rounded"
//           src={`https://crm.onestepcustomboxes.co.uk${productimg2}`}
//           style={{ cursor: 'auto' }}
//         />
//       )}
//     </div>
// <div className="lg:w-[90%] w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0" style={{ cursor: "auto" }}>            
//                 {/* <p className="text-[15px]">{product.descrpt}</p> */}
//                 <div className="text-[15px]" dangerouslySetInnerHTML={{ __html: sanitizedHTML1 }}></div>
//               </div>
//             {/* <div className="w-[80%] rounded-xl mb-10 flex justify-center p-3 mx-auto bg-[#fe7d6a] text-white font-bold text-center" onClick={move}>
// <button className="">Get a Quote  form</button>
//             </div>
//             <div className="w-[100%] flex flex-wrap justify-between lg:pt-13 md:pt-13 pt-0 pb-10 mx-auto">
//               <div className="lg:w-[40%] md:w-[36%] w-[100%] flex justify-center items-center ml-3">
//                 <div>
//                   <h2 className="font-bold xl:text-[33px] lg:text-[33px] md:text-[33px] text-[25px]">
//                     Request a <span className="text-[#fe7d6a]"> Free Quote & Consultation </span>
//                   </h2>
//                   <p className="mt-3 xl:text-[15px] lg:text-[15px] md:text-[15px] text-[13px]">
//                     OnestepCustomBoxes is a representation of greatness, quality, and openness for every single consumer. We will respond to your price request form in less than a day if you fill it out!
//                     <br /> <br />
//                     From the initial consultation until the end of manufacturing and delivery, a devoted packaging specialist is ready to guide you through the full custom packaging process without causing you any pain.
//                   </p>
//                   <br />
//                   <p className="text-[15px] font-medium">
//                     Want help sooner? For immediate assistance, contact us via email at <span className="text-[#fe7d6a]">contact@onestepcustomboxes.co.uk</span>
//                   </p>
//                 </div>
//               </div>
//               <div className="lg:w-[59%] md:w-[59%] w-[100%]">
//                 <form className="lg:w-[80%] md:w-[80%] w-[95%] lg:mt-0 md:mt-0 mt-8 mx-auto shadow-2xl p-[6%]" onSubmit={handleSubmit}>
//                   <div className="mb-5 flex justify-between">
//                     <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
//                       <label htmlFor="name" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
//                         Name
//                       </label>
//                       <input
//                         type="text"
//                         id="name"
//                         name="name"
//                         value={formData.name}
//                         onChange={handleChange}
//                         className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
//                         placeholder="Your Name"
//                         required
//                       />
//                     </div>
//                     <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
//                       <label htmlFor="email" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
//                         E-mail
//                       </label>
//                       <input
//                         type="email"
//                         id="email"
//                         name="email"
//                         value={formData.email}
//                         onChange={handleChange}
//                         className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
//                         placeholder="Your E-mail"
//                         required
//                       />
//                     </div>
//                   </div>
//                   <div className="mb-5 flex justify-between">
//                     <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
//                       <label htmlFor="phone" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
//                         Phone
//                       </label>
//                       <input
//                         type="tel"
//                         id="phone"
//                         name="phone"
//                         value={formData.phone}
//                         onChange={handleChange}
//                         className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
//                         placeholder="Your Phone Number"
//                         required
//                       />
//                     </div>
//                     <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
//                       <label htmlFor="address" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
//                         Address
//                       </label>
//                       <input
//                         type="text"
//                         id="address"
//                         name="address"
//                         value={formData.address}
//                         onChange={handleChange}
//                         className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
//                         placeholder="Your Address"
//                         required
//                       />
//                     </div>
//                   </div>
//                   <div className="mb-5 flex justify-between">
//                     <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
//                       <label htmlFor="quantity" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
//                         Quantity
//                       </label>
//                       <input
//                         type="number"
//                         id="quantity"
//                         name="quantity"
//                         value={formData.quantity}
//                         onChange={handleChange}
//                         className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
//                         placeholder="Your Required Quantity"
//                         required
//                       />
//                     </div>
//                     <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
//                       <label htmlFor="boxType" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
//                         Box Type
//                       </label>
//                       <input
//                         type="text"
//                         id="boxType"
//                         name="boxType"
//                         value={formData.boxType}
//                         onChange={handleChange}
//                         className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
//                         placeholder="Coating"
//                         required
//                       />
//                     </div>
//                   </div>
//                   <div className="mb-6">
//                     <label htmlFor="message" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
//                       Message
//                     </label>
//                     <textarea
//                       id="message"
//                       name="message"
//                       value={formData.message}
//                       onChange={handleChange}
//                       rows="4"
//                       className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
//                       placeholder="Write your message here..."
//                       required
//                     ></textarea>
//                   </div>
//                   {submissionStatus === 'success' && (
//                     <div className="mt-4 text-white bg-green-400 p-3 mb-3 font-bold">
//                       Your request has been submitted successfully!
//                     </div>
//                   )}
//                   {submissionStatus === 'error' && (
//                     <div className="mt-4 text-red-600">
//                       There was an error submitting your request. Please try again later.
//                     </div>
//                   )}
//                   <button
//                     type="submit"
//                     className="text-white w-full bg-[#fe7d6a] hover:bg-[#e66f5d] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
//                   >
//                     Get a Quote Now
//                   </button>
                 
//                 </form>
//               </div>
//             </div> */}
//           </>
//         )}
//       </div>
// </div>

//     <Footer/>
//   </div>
//   );
// };

// export default BlogDetail;


import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import "./BlogDetail.css"

const BlogDetail = () => {
  const location = useLocation();
  const { product } = location.state || {};  // Handle case where location.state might be empty
  const [fetchedProduct, setFetchedProduct] = useState(null);  // State to store fetched product details
  const sanitizedHTML = product ? DOMPurify.sanitize(product.descrpt) : fetchedProduct ? DOMPurify.sanitize(fetchedProduct.descrpt) : '';
  const sanitizedHTML1 = product ? DOMPurify.sanitize(product.descp) : fetchedProduct ? DOMPurify.sanitize(fetchedProduct.descp) : '';
  const [useBackup, setUseBackup] = useState(false);

  const urlWithoutDomain = window.location.pathname + window.location.search + window.location.hash;
  const urlapi = urlWithoutDomain.slice(6).replace(/-/g, ' ').toUpperCase();

  console.log(urlapi, "urlapi");

  useEffect(() => {
    const fetchData = async () => {
      console.log('Fetching data...');
      try {
        console.log('API URL:', `https://crm.onestepcustomboxes.co.uk/api/blog?name=${urlapi}`);
        const response = await axios.get('https://crm.onestepcustomboxes.co.uk/api/blog', {
          params: { name: urlapi }
        });
        setFetchedProduct(response.data.products);  // Set the fetched product state
      } catch (error) {
        console.error('Error hitting the API:', error);
      }
    };

    if (!product) {
      fetchData();
    }
  }, [urlapi, product]);

  const handleImageError = () => {
    setUseBackup(true);
  };

  const containerRef = useRef(null);

  const productToShow = product || fetchedProduct;
  const productimg = productToShow?.image[0];
  const productimg1 = productToShow?.image[1];
  const productimg2 = productToShow?.image[2];

  const formattedName = encodeURIComponent(
    decodeURIComponent(productToShow?.mn || '')
      .replace(/\s+/g, '-')
      .toLowerCase()
      .replace(/[^a-z0-9\-]/g, '')  // Remove any non-alphanumeric characters except hyphens
  );

  useEffect(() => {
    if (containerRef.current) {
      // Select all p and h2 tags
      const pTags = containerRef.current.querySelectorAll('p');
      const h2Tags = containerRef.current.querySelectorAll('h2');
      const liTags = containerRef.current.querySelectorAll('li');

      // Hide all p tags except the first one
      pTags.forEach((pTag, index) => {
        if (index !== 0) {
          pTag.style.display = 'none';
        }
      });

      // Hide all h2 tags
      h2Tags.forEach((h2Tag) => {
        h2Tag.style.display = 'none';
      });
      liTags.forEach((liTag) => {
        liTag.style.display = 'none';
      });
    }

  }, [sanitizedHTML, sanitizedHTML1]);
  useEffect(() => {
    // Remove data-react-helmet attribute from the canonical link
    const canonicalLink = document.querySelector("link[rel='canonical']");
    if (canonicalLink) {
      canonicalLink.removeAttribute("data-react-helmet");
    }
  }, []);
  return (
    <div>
      <Helmet>
        <title>{productToShow?.mn}</title>
        <meta name="Description" content={productToShow?.md} />
        <link rel="canonical" href={`https://onestepcustomboxes.co.uk/blog/${formattedName}`} />
        <meta name="robots" content="index" />
      </Helmet>
      <Navbar />
      <div>
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4]  font-medium ">
              Home
            </p>
            <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px]  font-medium">
              Blogs
            </p>
          </div>
        </div>
        <div className="w-[95%] py-14 mx-auto">
          {productToShow && (
            <>
              <div className="lg:w-[95%]  mb-5 mx-auto flex items-center justify-between flex-wrap">
                <div className="lg:w-[45%] w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0" style={{ cursor: "auto" }}>
                  <h1 className="text-gray-900 text-3xl title-font font-medium mb-1" style={{ cursor: "auto" }}>
                    {productToShow.name}
                  </h1>
                  <div ref={containerRef} className="text-[15px] mt-5" dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
                </div>
                {!useBackup ? (
                  <img
                    alt={productToShow.name}
                    className="2xl:w-[40%] lg:w-1/2 w-full lg:h-[50vh] md:h-64 h-auto object-cover object-center rounded"
                    src={productimg}
                    loading="lazy"
                    onError={handleImageError}
                    style={{ cursor: 'auto' }}
                  />
                ) : (
                  <img
                    alt={productToShow.name}
                    loading="lazy"
                    className="2xl:w-[40%] lg:w-1/2 w-full lg:h-[50vh] md:h-64 h-auto object-cover object-center rounded"
                    src={`https://crm.onestepcustomboxes.co.uk${productimg}`}
                    style={{ cursor: 'auto' }}
                  />
                )}
              </div>
              <div className="lg:w-[90%] w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0" style={{ cursor: "auto" }}>
                <div className="text-[15px]" dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
              </div>
              <div className='flex justify-around flex-wrap'>
                {productimg1 && (
                  <img
                    alt={productToShow.name}
                    loading="lazy"
                    className="2xl:w-[40%] mt-4 lg:w-[500px] w-full lg:h-[40vh] md:h-64 h-auto object-cover object-center rounded"
                    src={`https://crm.onestepcustomboxes.co.uk${productimg1}`}
                    style={{ cursor: 'auto' }}
                  />
                )}
                {productimg2 && (
                  <img
                    alt={productToShow.name}
                    loading="lazy"
                    className="2xl:w-[40%] mt-4 lg:w-[500px] w-full lg:h-[40vh] md:h-64 h-auto object-cover object-center rounded"
                    src={`https://crm.onestepcustomboxes.co.uk${productimg2}`}
                    style={{ cursor: 'auto' }}
                  />
                )}
              </div>
              <div className="lg:w-[90%] w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0" style={{ cursor: "auto" }}>
                <div className="text-[15px]" dangerouslySetInnerHTML={{ __html: sanitizedHTML1 }}></div>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetail;
