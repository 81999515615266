// src/Pages/PageNotFound.js

import React from 'react';
import { useNavigate  } from 'react-router-dom';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';

const PageNotFound = () => {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/'); // Adjust the route as per your homepage route
    window.location.reload();
  };
  return (
   <>
   <Navbar/>
   <div className="flex flex-col items-center justify-center 2xl:p-20 xl:p-20 lg:p-20 md:p-20 p-10">
<h2 className='text-[80px] font-bold'>404</h2>
      <h2 className="text-4xl font-bold mb-4">Page Not Found</h2>
      <p className="mb-8">Sorry, the page you are looking for does not exist.</p>
      <button onClick={handleClick} className="px-4 py-2 bg-[#fe7d6a] text-white rounded hover:bg-[#f95d48]">
        Go to Homepage
      </button>
    </div>
   <Footer/>
   </>
  );
};

export default PageNotFound;
