import img from "../images/rating-provan-header.jpg";
import { CiDollar, CiClock2, CiPower } from "react-icons/ci";
import { CiDeliveryTruck } from "react-icons/ci";
import { CiDesktop } from "react-icons/ci";
import { PiCodesandboxLogoThin } from "react-icons/pi";
import { SlSettings } from "react-icons/sl";
import { AiOutlineGift } from "react-icons/ai";
import { IoPrintOutline } from "react-icons/io5";
import { IoIosList } from "react-icons/io";
import { TbUserStar } from "react-icons/tb";
import { IoPricetagsOutline } from "react-icons/io5";
export const topsliderdata = [
  {
    Text: " Your First Order Delight! Effortless Designing with Cutter Guide: Enjoy Complimentary Assistance!",
  },
  { Text: " Unmatched Shipping Speed! Unlock 10% Savings" },
  { Text: " Rapid Dispatch, Swift Delivery" },
  {
    Text: " Your First Order Delight! Effortless Designing with Cutter Guide: Enjoy Complimentary Assistance!",
  },
  { Text: " Unmatched Shipping Speed! Unlock 10% Savings" },
  { Text: " Rapid Dispatch, Swift Delivery" },
  {
    Text: "  Your First Order Delight! Effortless Designing with Cutter Guide: Enjoy Complimentary Assistance!",
  },
  { Text: " Unmatched Shipping Speed! Unlock 10% Savings" },
  { Text: " Rapid Dispatch, Swift Delivery" },
];

export const NavbarData = {
  img1: [img],
};

export const menuItems = [
  {
    img:"/images/industry/Beauty.webp",
    name: "Beauty and Cosmetics Packaging",
    link: "/cosmetic-packaging",
  },
  {
    img:"/images/industry/E-Commerce.webp",
    name: "E-Commerce Packaging",
    link: "/ecommerce-packaging",
   },
  {
    img:"/images/industry/Beverage.webp",
    name: "Beverage Custom Boxes",
    link: "/beverage-packaging",
  },
  {
    img:"/images/industry/Cannabis.webp",
    name: "Cannabis Custom Packaging",
    link: "/cannabis-packaging",
  },
  {
    img:"/images/industry/Clothing.webp",
    name: "Clothing and Apparel Boxes",
    link: "/clothing-apparel-boxes",
  },
 { img:"/images/industry/Custom.webp",
 name: "Custom Made Boxes",
 link: "/bespoke-packaging-boxes",},

 { img:"/images/industry/Electronics.webp",
 name: "Electronics Boxes",
 link: "/electronics-packaging-box",
},
{ img:"/images/industry/FastFood.webp",
name: "Fast Food Packaging",
link: "/food-packaging-boxes",
},
{
  img:"/images/industry/Gifts.webp",
  name: "Gifts and Souvenirs Boxes",
  link: "/gift-boxes",
},
{ img:"/images/industry/Healthcare.webp",
name: "Healthcare Boxes",
link: "/pharmaceutical-packaging",
},
{
  img:"/images/industry/JeweleryPackaging.webp",
  name: "Jewelry Packaging",
  link: "/jewelry-boxes",
},
{
  img:"/images/industry/MedicalDevices.webp",
  name: "Medical Devices Boxes",
  link: "/medical-device-packaging",
},
{
  img:"/images/industry/ShoesPacakging.webp",
  name: "Shoes Packaging",
  link: "/shoe-boxes",
},
{
  img:"/images/industry/SuperMarkets.webp",
  name: "Super Markets Packaging",
  link: "/retail-packaging",
},
{
  img:"/images/industry/Supplements.webp",
  name: "Supplements Packaging",
  link: "/supplements-packaging",
},
];

export const mainslider = [
{
  img:"/images/finalbanner/A1.webp"
},
{
  img:"/images/finalbanner/Display_Boxes.webp"
},
{
  img:"/images/finalbanner/Candle_boxes.webp"
},

];

export const Section2 = [
  {
    icon: <CiDollar />,
    text1: "No Die & ",
    text2: "Plate Charges",
  },
  {
    icon: <CiClock2 />,
    text1: "Fastest Turnaround ",
    text2: "7 to 8 working days",
  },
  {
    icon: <CiDeliveryTruck />,
    text1: "Free Shipping ",
    text2: "all across UK",
  },
  {
    icon: <CiPower />,
    text1: "Starting from",
    text2: "100 boxes",
  },
  {
    icon: <PiCodesandboxLogoThin />,
    text1: "Customize Size",
    text2: "& Style",
  },
  {
    icon: <CiDesktop />,
    text1: "Free Designing",
    text2: "Assistance",
  },
];

export const Slid2 = [
  {
    img: "/images/customproducts.webp",
    name:"Paper Boxes Packaging"
  },
  {
    img: "/images/Customdesign.webp",
    name:"Cereals Boxes Slim Size"
  },
  {
    img: "/images/custompacakging.webp",
    name:"Book Mockups"
  },
  {
    img: "/images/CustomBoxes.webp",
    name:"Pillow Boxes"
  },
  {
    img: "/images/Cuuterguider.webp",
    name:"Cutter Guider"
  },
  {
    img: "/images/Bakeryboxes.webp",
    name:"Cake Boxes"
  },
];
export const topdata = [
  {
    imageUrl: "/images/Top_products/CandleBoxes.jpg",
    productName: "Candle Boxes",
    para: "Make professional-grade candle packaging with the aid of our extensive range of options. ",
  },
  {
    imageUrl: "/images/Top_products/CornBoxes.jpg",
    productName: "Pop Corn Boxes",
    para: "Try our custom popcorn boxes for all your corn snack packaging needs.",
  },
  {
    imageUrl: "/images/Top_products/MacronBoxes.jpg",
    productName: "Macron Boxes",
    para: "Enjoy your sweets in elegance with our custom macaron boxes for packaging and display.",
  },
  {
    imageUrl: "/images/Top_products/Macronboxe.jpg",
    productName: "Display Macron Boxes",
    para: "We produce unique macaron boxes for bakery packing and sweets display. ",
  },
  {
    imageUrl: "/images/Top_products/CornflakesBoxes.jpg",
    productName: "Corn flakes Boxes",
    para: "Our custom corn flask boxes for packaging, storage, and shipment optimize agricultural operations.",
  },
  {
    imageUrl: "/images/Top_products/DonutBoxes.jpg",
    productName: "Donut Boxes",
    para: "Your bakery experience is enhanced by our flexible donut boxes for packing and display. ",
  },
  {
    imageUrl: "/images/Top_products/chocolateBoxes.jpg",
    productName: "Chocolate Boxes",
    para: "Design and packaging enhance our premium chocolate boxes. Our wrapping enhances chocolate and desserts.",
  },
  {
    imageUrl: "/images/Top_products/DonutsHexagonBoxes.jpg",
    productName: "Donuts Hexagon Boxes",
    para: "These hexagon-shaped donut boxes meet all your packaging needs and improve bakery appearance.",
  },
  {
    imageUrl: "/images/Top_products/MailerBoxes.jpg",
    productName: "Mailer Boxes",
    para: "Premium mailer boxes meet all packaging needs, enhancing shipment. Enhance product display and transportation safety. Our great mailers aid.",
  },
  {
    imageUrl: "/images/Top_products/eyelashesBoxes.jpg",
    productName: "eye lashes Boxes",
    para: "Our cosmetic eyelash boxes make your cosmetic packaging stand out.",
  },
  {
    imageUrl: "/images/Top_products/SoapBoxes.jpg",
    productName: "Soap Boxes",
    para: "Add custom packaging to soap boxes to fit product and branding demands.",
  },
  {
    imageUrl: "/images/Top_products/SleeveandtrayBoxes.jpg",
    productName: "Sleeve and tray Boxes",
    para: "Improve food and beverage packaging with our customizable sleeve and tray boxes.",
  },
  {
    imageUrl: "/images/Top_products/RigidBoxes.jpg",
    productName: "Rigid Boxes",
    para: "Secure Rigid Boxes for all your packaging requirements, providing durable containers for many industries. Eco-Friendly Holographic Foiling Boxes for sustainable, eye-catching packaging for all your needs.",
  },
  {
    imageUrl: "/images/Top_products/ecofriendly.jpg",
    productName: "Eco Friendly Holographic Foiling Boxes",
    para: "Eco-Friendly Holographic Foiling Boxes for sustainable, eye-catching packaging for all your needs.",
  },
  {
    imageUrl: "/images/Top_products/PillowBoxes.jpg",
    productName: "Pillow Boxes",
    para: "The multipurpose and aesthetically pleasing pillow box is an excellent choice for many retail items.",
  },
  {
    imageUrl: "/images/Top_products/BakeryBoxesPacakging.jpg",
    productName: "Bakery Boxes",
    para: "Secure Bakery Boxes for all your bakery requirements make packaging delicious treats simple and reliable.",
  },
  
];

export const indus = [
  {
    id:"1",
    img: "/images/industry/Beauty.webp",
    h1: "Beauty",
    url:"/cosmetic-packaging"
  },
  {
    id:"2",
    img: "/images/industry/Beverage.webp",
    h1: "Beverage",
     url:"/beverage-packaging"
  },
  {
    id:"3",
    img: "/images/industry/Cannabis.webp",
    h1: "Cannabis",
     url:"/cannabis-packaging"
  },
  {
    id:"4",
    img: "/images/industry/Clothing.webp",
    h1: "Clothing",
     url:"/clothing-apparel-boxes"
  },
  {
    id:"5",
    img: "/images/industry/Custom.webp",
    h1: "Custom",
     url:"/bespoke-packaging-boxes"
  },
  {
    id:"6",
    img: "/images/industry/E-Commerce.webp",
    h1: "E-Commerce",
     url:"/ecommerce-packaging"
  },
  {
    id:"7",
    img: "/images/industry/Electronics.webp",
    h1: "Electronics",
     url:"/electronics-packaging-box"
  },
  {
    id:"8",
    img: "/images/industry/FastFood.webp",
    h1: "FastFood",
     url:"/food-packaging-boxes"
  },
  {
    id:"9",
    img: "/images/industry/Gifts.webp",
    h1: "Gifts",
     url:"/gift-boxes"
  },
  {
    id:"10",
    img: "/images/industry/Healthcare.webp",
    h1: "Healthcare",
     url:"/pharmaceutical-packaging"
  },
  {
    id:"11",
    img: "/images/industry/JeweleryPackaging.webp",
    h1: "Jewellery ",
     url:"/jewelry-boxes"
  },
  {
    id:"12",
    img: "/images/industry/MedicalDevices.webp",
    h1: "Medical Devices",
     url:"/medical-device-packaging"
  },
  {
    id:"13",
    img: "/images/industry/ShoesPacakging.webp",
    h1: "Shoes Packaging",
     url:"/shoe-boxes"
  },
  {
    id:"14",
    img: "/images/industry/SuperMarkets.webp",
    h1: "Super Markets",
     url:"/retail-packaging"
  },
  {
    id:"15",
    img: "/images/industry/Supplements.webp",
    h1: "Supplements",
     url:"/supplements-packaging"
  },
];
export const sty = [
  {
    img: "/images/styl/0006.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/styl/0209.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/styl/0211.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/styl/0230.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/styl/0308.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/styl/0313.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/styl/102090.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/styl/102617.jpg",
    h1: "Bleach Card",
  },
];
export const mat = [
  {
    img: "/images/mater/1.jpg",
    h1: "Bleach Card ",
  },
  {
    img: "/images/mater/2.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/mater/3.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/mater/4.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/mater/5.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/mater/6.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/mater/7.jpg",
    h1: "Bleach Card",
  },
  {
    img: "/images/mater/8.jpg",
    h1: "Bleach Card",
  },
];

export const ban2 = [
  {
    img: "/images/EnsuringQuality.png",
    h3: "Ensuring Quality",
    p: "Superior quality and long-term reliability are our promises. Innovative Packaging Solution",
  },
  {
    img: "/images/Pacakgingsolution.png",
    h3: "Custom Packaging ",
    p: "We guarantee top-notch quality and durability",
  },
  {
    img: "/images/AffordablePricing.png",
    h3: "Affordable Pricing",
    p: "Quality doesn't mean expensive",
  },
  {
    img: "/images/EcoFriendly.png",
    h3: "Eco-Friendly Materials",
    p: "Sustainable choices for a greener footprint.",
  },
  {
    img: "/images/EfficientOrdering.png",
    h3: "Efficient Ordering",
    p: "Easy, Quick, and Hassle-free",
  },
  {
    img: "/images/ReliableDeliverey.png",
    h3: "Reliable Delivery",
    p: "We guarantee top-notch quality and durability",
  },
  
];

export const choos=[
  {
    icon:<SlSettings className='text-[63px] bg-[#fe7d6a] p-3 rounded-full'/>,
    h3:"Personalized Dimensions and design:",
    p:"We pride ourselves on offering our customers complete artistic freedom when it comes to the design of their packing boxes."
  },
  {
       icon:<AiOutlineGift className='text-[63px] bg-[#fe7d6a] p-2 rounded-full'/>,
    h3:"Environmental Team:",
    p:"Sustainability and environmental issues are rising, and We are aware of this. That's why we provide bespoke packaging in eco-friendly, Long-lasting materials. You may help shape a more sustainable future by working With us."
  },
  {
       icon:<IoPrintOutline className='text-[63px] bg-[#fe7d6a] p-2 rounded-full'/>,
    h3:"Advanced & Top printing quality:",
    p:"Affordable Custom Boxes of Superior Quality We were able to distinguish ourselves from the competition thanks to advancements in digital and inkjet printing."
  },
  {
       icon:<IoIosList className='text-[63px] bg-[#fe7d6a] p-3 rounded-full'/>,
    h3:"Unique features and coatings:",
    p:"The finishing touch! Make a statement with your eye-catching boxes thanks to the limitless personalization choices."
  },
  {
       icon:<TbUserStar className='text-[63px] bg-[#fe7d6a] p-2 rounded-full'/>,
    h3:"Quick and cost-free shipping:",
    p:"Plus, what's great? Delivery is completely free! Our guarantee is the most dependable and speediest delivery to your house."
  },
  {
       icon:<IoPricetagsOutline className='text-[63px] bg-[#fe7d6a] p-3 rounded-full'/>,
    h3:"Get a design at no cost:",
    p:"Give your ideas to our expert designers, and we'll turn them into reality."
  },
  {
       icon:<IoIosList className='text-[63px] bg-[#fe7d6a] p-3 rounded-full'/>,
    h3:"Above all, customer satisfaction:",
    p:"Our customers are our priority! Our CSRs are on duty 24/7. Please get in touch with me."
  },
  {
       icon:<CiDeliveryTruck className='text-[63px] bg-[#fe7d6a] p-2 rounded-full'/>,
    h3:"Unbeatable prices promised!",
    p:"Discover rates that are impossible to find anywhere else! Affordable and outstanding bespoke packaging alternatives may boost your company's success."
  },
]

