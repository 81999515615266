import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Helmet } from 'react-helmet';
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

export default function Termsofservice() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Adjust the duration as needed
    });
  }, []);

  const navigate=useNavigate();
  const home=()=>{
    navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <div>
      <div>
      <Helmet>
        <title>Clear & Comprehensive Terms of Service</title>
        <meta name="Description" content="Review our detailed Terms of Service to understand your rights and obligations when using our site. Ensuring transparency and trust for a smooth experience." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/terms-of-services'/>
  <meta name="robots" content="index" />

      </Helmet>
        <Navbar />
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] cursor-pointer  font-medium " onClick={home}>
              Home
            </p>
            <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            {/* Add this line */}
            <p className="lg:text-[15px] text-[9px]  font-medium">
              Terms of service
            </p>
          </div>
        </div>
        <div className="w-[95%] md:w-[90%] mx-auto overflow-hidden  pt-10 md:pt-16 ">
          {/* 1 */}
          <div data-aos="zoom-in">
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose ">
              By accessing or using Onestepcustomboxes.co.uk, you are fully
              embracing our policy on privacy. At Onestepcustomboxes, we value
              our users’ privacy very highly. We promise not to share your
              information with third parties unless absolutely necessary to
              complete your transaction or address your inquiry. Customer
              assistance, order fulfilment, shipping requests, and comments are
              the only areas that will use it.
            </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4 ">
              {" "}
              Be that as it may, there are situations in which we are required
              by law to disclose your information to comply, safeguard our
              interests and the safety of other website users, or as you may
              have previously permitted. Also, we can’t promise that we won’t
              share your data if we feel it’s necessary to comply with any
              ongoing legal proceedings, court orders, or procedures that impact
              our website.
            </p>

            <h1 className="text-sm md:text-[18px] font-semibold md:font-semibold pt-4">
              Compilation of Data{" "}
            </h1>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
              {" "}
              Information about you may be gathered whenever you engage with our
              site in any manner, whether it via registration, purchasing,
              survey participation, email exchange, or any other activity. Your
              name, mailing address, email, and/or phone number may be among the
              pieces of information that fall into this category. Anonymous
              individuals are welcome to browse our site.We use "cookies,"
              similar to all websites, to retain information about our users'
              preferences while they browse the internet. The definition and
              overview of the term "cookies" in relation to its everyday use are
              provided below.
            </p>
          </div>

          {/* 2 */}

          <div data-aos="fade-right">
            <h2 className="text-base md:text-[18px] font-semibold md:font-semibold pt-4">
              {" "}
              THE USAGE OF DATA{" "}
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
              The information we collect may be used for a variety of purposes,
              such as making your experience on our site more tailored to your
              interests, facilitating faster transaction processing, addressing
              customer care issues, and running contests, promotions, and
              surveys. By subscribing to our email newsletter, we make it
              possible for you to get frequent communications from us.The
              instructions for disabling the receipt of promotional emails may
              be found under the section labelled “How can you opt-out, remove,
              or modify information you have provided to us?” which is straight
              below this one. When users sign up for an account or participate
              in certain site features, such as marketing programmes or
              “members-only” content, they have the option to join our email
              list and begin receiving communications.
            </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
              Your information is completely secure with us; not even our linked
              companies will have access to it. When you submit an inquiry to
              Onestepcustomboxes.co.uk, we may retain some of your data in order
              to enhance our services, website, and promotional offerings. You
              also consent to us using your information to contact you on
              significant website updates and other relevant news.
            </p>
            <h2 className="text-sm md:text-[18px] font-semibold md:font-semibold pt-4">
              {" "}
              Protection of Your Information{" "}
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
              {" "}
              Here at Onestepcustomboxes.co.uk, we value our visitors’ and
              customers' privacy very highly. Our mix of online and physical
              security ensures that your private information and corporate
              records are protected. We take all possible measures to ensure
              that our users' and customers' data safe from unwanted access
              because we admire their right to privacy.I.e. To encrypt sensitive
              data before sending or receiving it, our website uses the
              widely-used Secure Sockets Layer (SSL) protocol. Despite safe
              Sockets Layer (SSL) encryption making data transfers over the
              Internet more secure, they are still vulnerable. Our website does
              not offer a secure environment for any information you may submit.{" "}
            </p>
          </div>

          <div data-aos="fade-left">
            <h2 className="text-base md:text-base font-semibold md:font-semibold pt-4">
              COOKIESS
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
              We do, in fact, utilise cookies. “Cookies” are little data files
              that our website stores on your computer and retains for an
              extended period of time. By integrating your data with our servers
              and records, they enable us to enhance the personalisation of our
              website for you. You must enable cookies for
              Onestepcustomboxes.co.uk to function properly. To manage and
              delete cookies, just follow the on-screen instructions provided by
              your browser. While disabling cookies will still allow you to
              access most of our site’s features, their functionality may be
              impaired in certain cases.
            </p>
            <h2 className="text-sm md:text-[18px] font-semibold md:font-semibold pt-4">
              {" "}
              Come and join us in loving prayer.{" "}
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  pb-6">
              If you have any queries or issues about our privacy policy, please
              feel free to contact us at{" "}
              <span className="text-xs">contact@onestepcustomboxes.co.uk.</span>
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
