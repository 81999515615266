import React,{useState,useEffect} from 'react'
import { Helmet } from 'react-helmet';
import Navbar from "../Components/Navbar";
// import { Terminationdata } from './Terminationdata';// Corrected import statement
import Footer from '../Components/Footer';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import { FaStar } from "react-icons/fa";

const Termination = () => {

  const [showMore, setShowMore] = useState(false);
  const [displayedCards, setDisplayedCards] = useState(6); // Number of cards initially displayed
const navigate = useNavigate()

const reviewclick=()=>{
    navigate(`/feedback`)
}
    
const handlecontact = () => {
  navigate('/contact')
  window.scrollTo(0, 0);
  };
  const handleportfolio = () => {
    navigate('/request-quote')
    window.scrollTo(0, 0);
    };

  const handleViewMore = () => {
    setShowMore(true);
    setDisplayedCards(prod.length); // Display all cards
  };

  const handleViewLess = () => {
    setShowMore(false);
    setDisplayedCards(6); // Display limited number of cards
  };

  const [prod, setProd] = useState([]);
  // const [section, setSection] = useState([]);
  useEffect(() => {  
    axios.get('https://crm.onestepcustomboxes.co.uk/api/reviews')
        .then(response => {
            const data = response.data;
            console.log('Fetched data:', data.reviews);
  
            // Adjust the image URLs
            const modifiedPro = data.reviews.map(product => ({
                ...product,
                image: `https://crm.onestepcustomboxes.co.uk${product.image}`
            }));
  
            setProd(modifiedPro);
            AOS.init({
              duration: 1000, // Adjust the duration as needed
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    // setSection(Section2);
  }, []);
  const home=()=>{
    navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <div>
       <Helmet>
        <title>Hear from Our Satisfied Customers | Happy Clients</title>
        <meta name="Description" content="Discover testimonials from our happy clients. Learn how our custom packaging solutions have met their needs and exceeded their expectations." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/testimonial'/>
  <meta name="robots" content="index" />

      </Helmet>
<Navbar/>
<div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] font-medium cursor-pointer" onClick={home}>
              Home
            </p>
             <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px] font-medium">
            Testimonial
            </p>
          </div>
        </div>
{/* -------------mainsection--------- */}
<div className='xl:h-[50vh] lg:h-[50vh] overflow-hidden md:h-[50vh] h-[25vh] flex flex-col justify-center items-center' style={{backgroundImage:"url(/images/portfolio-page.png)", backgroundSize:"100% 100%"}}>
<div className='text-white text-center xl:w-[60%] lg:w-[60%] md:w-[60%] w-[90%] ' data-aos="zoom-in"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="2000">
  <h1 className='xl:text-[50px] lg:text-[50px] md:text-[40px] text-center text-[25px] border-b-4 border-[#fe7d6a] text-center mt-2 font-bold xl:w-[43%] lg:w-[43%] md:w-[87%] w-[85%] mx-auto ' style={{lineHeight:"1.2"}}>Testimonial</h1> 
</div>
</div>

{/* -------------------Customer Feedback------------- */}
<div className='bg-black' >
  <div className='container  w-[95%] mx-auto py-16' >
    <div className="w-[93%] mx-auto grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-8 mt-10 overflow-hidden">
    {prod.slice(0, displayedCards).map((review, index) => (
  <div key={index} className={`w-full max-w-sm border border-gray-200 overflow-hidden rounded-lg pb-6 shadow ${index % 2 === 0 ? 'bg-white' : '#D9EDEC'}`} data-aos="fade-up-right">
    <div className="flex w-[100%]  flex-col mx-auto mt-6">
      {/* <img className="object-cover rounded-full mx-auto w-[20%] h-[20%] " src={card.image} alt="Profile" /> */}
      <div className="px-5 pb-5 ">
        <h5 className={`text-base md:text-base font-semibold tracking-tight ${index % 2 === 0 ? 'text-[#566166]' : 'text-white'} mt-1 text-center`}>{review.name}</h5>
        <div className="flex items-center mt-1 mb-0">
        <div className='flex mt-1 mx-auto'>
              {review.stars !== null && review.stars !== undefined ? (
                // Render stars based on the number provided in review.stars
                [...Array(parseInt(review.stars))].map((_, starIndex) => (
                  <FaStar key={starIndex} className='mr-2 text-[#fe7d6a]' />
                ))
              ) : (
                <p className={`text-sm font-semibold mt-6 ${index % 2 === 0 ? 'text-[#566166]' : 'text-white'}`}>No star rating provided</p>
              )}
            </div>
        </div>
      </div>
    </div>
    <div className="mx-auto w-[90%]">
      <hr />
      <p className={`text-sm font-semibold mt-6 ${index % 2 === 0 ? 'text-[#566166]' : 'text-white'}`}>{review.review}</p>
    </div>
  </div>
))}

      </div>
      {/* View More and View Less Buttons */}
      {!showMore ? (
        <div className="w-full text-center mt-16">
          <button
            className="bg-[#022C44] hover:bg-[#FF9C6E] text-white font-bold py-2 px-4 rounded"
            onClick={handleViewMore}
          >
            View More
          </button>
        </div>
      ) : (
        <div className="w-full text-center mt-16">
          <button
            className="bg-[#022C44] hover:bg-[#FF9C6E] text-white font-bold py-2 px-4 rounded mr-4"
            onClick={handleViewLess}
          >
            View Less
          </button>
        </div>
      )}
  </div>

</div>


{/* ------Footer----- */}
<div className='lg:pb-[5%] md:pb-[5%] pb-[7%] text-white' style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/images/backr.png)", backgroundSize:"100% 100%"}}>
<h2 className='lg:text-[35px] md:text-[35px] text-[20px] text-center font-bold lg:pt-[5%] md:pt-[5%] pt-[7%]'>Quality Custom Boxes, Affordable Prices</h2>
<p className='text-center lg:text-[15px] md:text-[15px] text-[13px] mt-[15px]  m-2 ' style={{lineHeight:"1.1"}}>From your order to your front door, our team manages it all to make things simpler for you</p>
<div className='flex lg:w-[31%] md:w-[31%] w-[100%]  pb-2 lg:mt-[30px] md:mt-[2%] mt-[5%] mx-auto'>
    <button className='border-2 lg:text-[12px] md:text-[12px] text-[10px] mx-auto rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handlecontact}>Contact Us Today</button>
    <button className='border-2 lg:text-[12px] md:text-[12px] text-[10px] mx-auto lg:ml-10 md:ml-10 rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handleportfolio}>Get Free Quote</button>

</div>
        </div>
        <div className='bg-white  p-7'>
            <div className='flex items-center justify-between'>
                <h2 className=' font-bold lg:text-[30px] md:text-[30px] text-[20px]'>Give us a <span className='text-[#fe7d6a]'> Feedback</span></h2>
                <button className='bg-[#fe7d6a] lg:text-[17px] md:text-[17px] text-[13px] text-white font-bold lg:py-2 md:py-2 py-2 lg:px-4 md:px-4 px-2 rounded-lg' onClick={reviewclick}> Write a Review</button>
            </div>
        </div>

        <Footer/>
    </div>
  );
};

export default Termination;
