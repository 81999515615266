import React,{useEffect} from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";

export default function Privacypolicy() {

  useEffect(() => {

    AOS.init({
      duration: 1000, // Adjust the duration as needed
    });

  }, []);
const navigate=useNavigate();
  const home=()=>{
    navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <>
      <div className="">
      <Helmet>
        <title>Comprehensive Privacy Policy | Your Data Secured</title>
        <meta name="Description" content="Understand how we collect, protect, and use your data. Our privacy policy ensures transparency and security, giving you confidence in your interactions with us." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/privacy'/>
  <meta name="robots" content="index" />

      </Helmet>
        <Navbar />
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className=" text-[#C4C4C4] lg:text-[15px] text-[9px] font-medium cursor-pointer " onClick={home}>
              Home
            </p>
             <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            {/* Add this line */}
            <p className="lg:text-[15px] text-[9px] font-medium">
            Privacy Policy
            </p>
          </div>
        </div>
        <div className="w-[95%] md:w-[90%] mx-auto overflow-hidden pt-10 md:pt-16 " >
          {/* 1 */}
          <div data-aos="zoom-in">
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose ">
              At Onestepcustomboxes, we guarantee your complete pleasure with
              every purchase and take great pride in the superiority of our
              services. To set the record straight and prevent any confusion
              that may result in needless actions, we have carefully crafted
              these suggestions. You may contact us directly whenever you like.
              Much obliged, and best wishes for many more fruitful
              collaborations to come!
            </p>

            <h1 className="text-base md:text-[22px] font-semibold md:font-bold pt-4">
              A CONCLUSION OF THIS
            </h1>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium pt-4 md:pt-4 ">
            The following terms are contained in this document:
            </p>

            <ol className="w-[85%] md:w-full text-[#7A7A7A] text-sm  md:text-sm font-medium font-robot  list-outside list-decimal pt-4 md:pt-4 mx-8 md:mx-12 leading-loose md:leading-loose">
              <li class="list-disc">
              The term “Buyer” is used here for the individual or organization buying the Goods.
              </li>
              <li class="list-disc">
              In a “Goods” transaction, the Seller is give anything to the Buyer.
              </li>
              <li class="list-disc">
              There is no mention of a partnership between these Terms and Conditions and no indication that one party has appointed another as its agent.
              </li>
             
            </ol>
          </div>

          {/* 2 */}

          <div data-aos="fade-right">
            <h2 className="text-base md:text-[22px] font-semibold md:font-bold pt-4">
              {" "}
              GENERAL
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
              These Terms and Conditions govern the purchase and sale of Goods
              from Seller to Buyer and supersede and replace any previous
              agreements, offers, or understandings between the parties. Except
              as expressly provided in writing and accepted by the Seller, this
              also includes any written or standard terms supplied by the Buyer.
            </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
              If the Seller and Buyer do not mutually agree to modify these
              terms of service in writing, then the modifications will not be
              valid.
            </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
              Any time you make a purchase or create an account with the Seller,
              you’re essentially signing away your future rights to any and all
              of the terms and conditions listed above. This encompasses any
              communications sent out by the Seller that the Seller considers
              relevant.
            </p>

            <h2 className="text-sm md:text-[20px] font-semibold md:font-semibold pt-4">
              {" "}
              Finances and Expenses{" "}
            </h2>

            <ol className="w-[85%] md:w-full text-[#7A7A7A] text-sm md:text-sm font-medium font-robot  list-outside list-decimal pt-4 md:pt-4 mx-8 md:mx-12 leading-loose md:leading-loose">
              <li class="list-disc">
                The agreed upon price shall be the same as the RRP (before
                discounts) unless the parties’ formal agreement specifies
                otherwise.
              </li>
              <li class="list-disc">
                Our prices will reflect the fact that we are a legitimate
                company.
              </li>
              <li class="list-disc">
                Wise, PayPal, Stripe, or a bank account are all acceptable
                methods of payment for this vendor. The terms of payment will be
                laid forth by the vendor.
              </li>
              <li class="list-disc">
                Any options for partial payments or credit extensions made
                available via PayPal or any other means are entirely at the
                discretion of the Seller.
              </li>
              <li class="list-disc">
                If the Seller decides that payment is not received by the
                stipulated due date, the Buyer shall not be held liable for
                non-delivery or any delays that may occur. Additionally, the
                Seller has the right to delay delivery of any unpaid goods and
                to demand advance payment for any goods that have not been
                delivered.
              </li>
              <li class="list-disc">
                A sale smd on description does not occur when the description is
                used to identify the items; it is only for identifying purposes.
                First things first: the Buyer freely confesses that it relied on
                zero descriptions when it entered into the contract.
              </li>
            </ol>
          </div>

          {/* 3 */}

          <div data-aos="fade-left">
            <h2 className="text-base md:text-[22px] font-semibold md:font-bold pt-4">
              SAMPLE
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
              The parties have mutually agreed that the product samples are for
              representational reasons only and that variations may occur in the
              bulk order due to the creative and shipping processes. Unfinished
              products may not always satisfy the quality standards of finished
              items, and the Buyer understands that prototypes and samples do
              not represent the final products.
            </p>

            <h2 className="text-sm md:text-[20px] font-semibold md:font-semibold pt-4">
              {" "}
              Our Return, Shipping, and Cancellation Procedures
            </h2>

            <ol className="w-[85%] md:w-full text-[#7A7A7A] text-sm md:text-sm font-medium font-robot  list-outside list-decimal pt-4 md:pt-4 mx-8 md:mx-12 leading-loose md:leading-loose">
              <li class="list-disc">
                Any changes must still be approved or rejected by the Seller at
                their discretion. Any and all transactions are subject to the
                seller’s word alone.
              </li>
              <li class="list-disc">
                Refunds will not be provided by the Seller if the Buyer chooses
                to cancel their purchase after the submission and approval
                procedure via PayPal or any other payment method.
              </li>
              <li class="list-disc">
                Customer reviews, images, and videos of completed products may
                only be used by the seller for promotional purposes on their
                website, in emails, and in ads, regardless of the channel of
                contact (live chat, phone, email, etc.).
              </li>
              <li class="list-disc">
                In their interactions with leads and consumers, the Seller may
                additionally use any and all data obtained.
              </li>
              <li class="list-disc">
                When you submit a form via the Seller’s website or other
                communication methods, you are granting the Seller your explicit
                consent to contact you and receive any communications that the
                Seller thinks are appropriate.
              </li>
              <li class="list-disc">
                All communications between Buyer and Seller, whether by email,
                live chat, phone, or other means of electronic payment,
                constitute an express acceptance of the claims made in these
                terms of service.
              </li>
              <li class="list-disc">
                No third party’s intervention or payment of any kind is required
                for the Seller to publicly disclose and promote any and all data
                without explicit written authorization.
              </li>
              <li class="list-disc">
                Unless the Seller says otherwise, all sales are final, and the
                Seller isn’t obligated to pay back the buyer at any stage of the
                project.
              </li>
              <li class="list-disc">
                Refund requests are entirely at the Seller’s discretion, however
                50% of the purchase price is a common amount that is usually
                accepted.
              </li>
              <li class="list-disc">You won’t have to worry about returns.</li>
              <li class="list-disc">
                The seller has the option to exclude any expenses incurred while
                working on the project from the return if the buyer seeks a
                complete refund.
              </li>
              <li class="list-disc">
                Any prior agreements executed by the Seller are superseded by
                this one.
              </li>
            </ol>
          </div>

          {/* 4 */}

          <div data-aos="fade-down-right">
            <h2 className="text-base md:text-[22px] font-semibold md:font-bold pt-4">
              RISKING
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
              At the time of delivery, the buyer assumes all risks relating to
              the Goods. If the Buyer wants to pick up the Goods themselves,
              risk will transfer to them as soon as they are available for
              collection or when they are designated for that purpose, whichever
              occurs first. The buyer is entirely liable for any modifications
              to their site’s rating or penalties that may occur throughout the
              course of the monthly subscription. While the seller claims to
              achieve your desired outcomes, there is no assurance that you will
              really get the outcomes shown in the commercials or on the screen.
              You shouldn’t consider the results, rankings, or visualisations as
              gospel as they are just approximations. These statements are made
              for educational and informational purposes only; the vendor does
              not guarantee that the knowledge offered will lead to the same or
              comparable outcomes in the future. Buyers should be aware that
              sellers cannot be held responsible for any outcomes that may arise
              from using the provided information to make business decisions.
              The Seller has the right to take measures such as locking,
              privatising, or restricting administrative access to the relevant
              objects in order to recover any amounts that are not paid in a
              timely manner. The Seller’s discretion will determine the exact
              steps to take. If payment is not made on time or is incomplete,
              the buyer must pay the seller at least $500,000 or an agreed upon
              amount.{" "}
            </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
              Title Until complete payment is received, the Seller will hold
              onto the Goods as their property.
            </p>

            <h2 className="text-sm md:text-[20px] font-semibold md:font-semibold pt-4">
              Limits on Legal Responsibility
            </h2>

            <ol className="w-[85%] md:w-full text-[#7A7A7A] text-sm md:text-sm font-medium font-robot  list-outside list-decimal pt-4 md:pt-4 mx-8 md:mx-12 leading-loose md:leading-loose">
              <li class="list-disc">
                In the event that the Buyer suffers losses or damages exceeding
                the amount specified in the contract, the Seller shall not be
                held liable.
              </li>
              <li class="list-disc">
                If the Seller is found liable in an arbitration decision about
                copyright infringement or legal actions, they are solely liable
                up to a maximum liability payment of $500.
              </li>
              <li class="list-disc">
                The Prosecution must be responsible for covering all litigation
                costs and legal fees against the Seller, regardless of the
                outcome of the case.
              </li>
              <li class="list-disc">
                Neither these Terms nor any future agreements shall limit nor
                exclude the Seller’s liability for any death or injury caused by
                the Seller’s or its employees’ or agents’ carelessness.
              </li>
            </ol>

            <h2 className="text-sm md:text-[20px] font-semibold md:font-semibold pt-4">
              The Legitimate Use of Source Images
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-2 ">
              Unless otherwise specified in writing by both parties, the Buyer
              and end-user shall hold any Intellectual Property Rights resulting
              from this Agreement. The Seller shall get agreements from third
              parties and make all other required measures to ensure the
              execution of these rights. Material or commodities delivered by
              the Seller may or may not represent the Seller’s views or beliefs.
              The Seller thus disclaims any liability with regard to the
              truthfulness or accuracy of the statements, the whole burden of
              proof being with the Buyer.
            </p>

            <h2 className="text-base md:text-[22px] font-semibold md:font-bold pt-4">
              EVENT BY GOD
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-3  ">
            A GOD-INSPIRED EVENT Events outside of the Seller's control, such as natural disasters, labor disputes, shipping delays, accidents, war, fire, or shortages of raw materials, will not be held accountable for any delays or non-performance. The circumstances may
              necessitate a fair extension of the Seller’s duty. The seller may
              terminate the contract with no further responsibilities if the
              delay lasts for an excessively long period.
            </p>
          </div>

          {/* 5 */}

          <div data-aos="fade-up-left"> 
            <h2 className="text-base md:text-[22px] font-semibold md:font-bold pt-4">
              LINK BETWEEN THE PARTIES
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
            LINK ACCESSIBLE TO EACH PARTY These Terms and Conditions make no mention of a partnership or joint venture, and neither party has appointed the other as its agent. Determining Accountabilities and Contracting Out The Buyer may not assign, transfer, or subcontract any right or obligation under the terms of the goods sale agreement without the Seller's prior written approval. Nonetheless, the Seller retains the right to assign, subcontract, or delegate any and all tasks. Paperwork for Discharge Should one party fail to enforce any of these terms right away, the other party shall be free to do so at a later time.
            </p>

      

            <h2 className="text-sm md:text-[20px] font-semibold md:font-semibold pt-4">
              Severability as a notion
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
              The other provisions of these Terms and Conditions will remain
              fully enforceable in the case that any term or condition is
              determined by a court of competent jurisdiction to be illegal,
              unlawful, or unenforceable.
            </p>

            <h2 className="text-sm md:text-[20px] font-semibold md:font-semibold pt-4">
              {" "}
              The Importance of General Law and Jurisdiction
            </h2>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-4 md:pt-4  ">
            The Significance of Jurisdiction and General Law Regardless of who starts the legal process, the Seller has the right to select the county and state in which the proceedings will be heard, which is usually London, United Kingdom. The parties hereto agree that the laws of the United Kingdom will govern this Agreement and that the courts of the United Kingdom will have exclusive jurisdiction in the event of a dispute arising out of this Agreement.{" "}
            </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-2 md:pt-2  ">
              The postal address for official communication is 128 City Road,
              London, EC1V 2NX.
            </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose  md:leading-loose pt-2 md:pt-2  ">
              Come and join us in loving prayer.{" "}
            </p>
            <p className="text-[#7A7A7A] text-sm md:text-sm font-medium leading-loose md:leading-loose pt-2 md:pt-2 pb-4 ">
              If our privacy policy is not clear or if you have any issues,
              please contact us at{" "}
              <span className="text-xs">contact@onestepcustomboxes.co.uk.</span>
            </p>
          </div>
        </div>

        <Footer />
      </div>

</>
);
}
