import React, { useState, useEffect } from 'react';
// import { cardData } from './carddata'; // Corrected import statement
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaStar } from "react-icons/fa";

export default function Card() {
  const [showMore, setShowMore] = useState(false);
  const [displayedCards, setDisplayedCards] = useState(6); // Number of cards initially displayed

  const handleViewMore = () => {
    setShowMore(true);
    setDisplayedCards(cardData.length); // Display all cards
  };

  const handleViewLess = () => {
    setShowMore(false);
    setDisplayedCards(6); // Display limited number of cards
  };

  const[cardData, setCardData]=useState([]);
  useEffect(() => {
    axios.get('https://crm.onestepcustomboxes.co.uk/api/reviews')
    .then(response => {
        const data = response.data;
        console.log('Fetched data:', data.reviews);

        // Adjust the image URLs
        const modifiedPro = data.reviews.map(product => ({
            ...product,
            image: `https://crm.onestepcustomboxes.co.uk${product.image}`
        }));

        setCardData(modifiedPro);
        AOS.init({
          duration: 1000, // Adjust the duration as needed
        });
    })
    .catch(error => {
        console.error('Error fetching data:', error);
    });
// setSection(Section2);

  }, []);
  return (
    <>
      <div className="w-[93%] mx-auto grid lg:grid-cols-3 md:grid-cols-2 grid-cols-2 gap-4 mt-10 overflow-hidden">
        {cardData.slice(0, displayedCards).map((review, index) => (
          <div key={index} className={`w-full max-w-sm border border-gray-200 overflow-hidden rounded-lg pb-6 shadow ${index % 2 === 0 ? 'bg-white' : '#D9EDEC'}`} data-aos="fade-up-right">
            <div className="flex w-[100%]  flex-col mx-auto mt-6">
              {/* <img className="object-cover rounded-full mx-auto w-16 h-16 shadow-lg" src={card.image} alt="Profile" /> */}
              <div className="px-5 pb-5 ">
                <h5 className="text-base md:text-base font-semibold tracking-tight text-gray-900 mt-1 text-center">{review.name}</h5>
                <div className="flex items-center mt-1 mb-0">
        <div className='flex mt-1 mx-auto'>
              {review.stars !== null && review.stars !== undefined ? (
                // Render stars based on the number provided in review.stars
                [...Array(parseInt(review.stars))].map((_, starIndex) => (
                  <FaStar key={starIndex} className='mr-2 text-[#fe7d6a]' />
                ))
              ) : (
                <p className={`text-sm font-semibold mt-6 ${index % 2 === 0 ? 'text-[#566166]' : 'text-[#566166]'}`}>No star rating provided</p>
              )}
            </div>
        </div>
              </div>
            </div>
            <div className="mx-auto w-[90%]">
              <hr />
              <p className="text-sm font-semibold text-center text-[#566166] mt-6">{review.review}</p>
              {showMore && <p className="text-sm font-semibold text-[#566166] mt-6">{review.review2}</p>}
            </div>
          </div>
        ))}
      </div>
      {/* View More and View Less Buttons */}
      {!showMore ? (
        <div className="w-full text-center mt-16">
          <button
            className="bg-[#022C44] hover:bg-[#FF9C6E] text-white font-bold py-2 px-4 rounded"
            onClick={handleViewMore}
          >
            View More
          </button>
        </div>
      ) : (
        <div className="w-full text-center mt-16">
          <button
            className="bg-[#022C44] hover:bg-[#FF9C6E] text-white font-bold py-2 px-4 rounded mr-4"
            onClick={handleViewLess}
          >
            View Less
          </button>
        </div>
      )}
    </>
  );
}

