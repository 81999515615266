import React from 'react'
import Navbar from '../Components/Navbar'
import Footer from '../Components/Footer'
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
export default function Thanks() {

    const navigate = useNavigate();

    const handleBackToHomeClick = () => {
        navigate('/');
    };
  return (
    <div>
        <Navbar/>
        <div className='m-10 2xl:w-[80%] xl:w-[80%] lg:w-[80%] md:w-[80%] w-[90%] mx-auto '>
         <div className='flex justify-center'>
         <div className='2xl:w-[45%] flex xl:w-[45%] lg:w-[45%]md:w-[45%] w-[80%] text-center  flex-col items-center'>
<h2 className='font-bold text-[30px]'>I'm grateful.</h2>
<p className='font-medium text-[16px]'>FOR SENDING US YOUR QUOTE!</p>
<img className='m-3' src='/images/thanks.png' alt='Thanks'/>
<p className='text-center text-[15px]'>The Sales Team has received your message. After reviewing your request, we'll get in touch with you.</p>
{/* <h3 className='font-bold text-[23px] mt-2'>You are Welcome to call us at</h3> */}
{/* <p className='mt-1'>020 8050 9220</p> */}
            </div>
         </div>
         <button 
            className='flex items-center mt-3 hover:text-[#fe7d6a]' 
            onClick={handleBackToHomeClick}
        >
            <FaArrowLeft className='mr-1' /> Back To Home
        </button>  </div>
        <Footer/>
    </div>
  )
}
