export const Product =[
    {
      id:"1",
         productName : 'Slytherine',
     imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
     description : 'Stylish cafe chair',
    },
    {
      id:"2",
        productName : 'Slytherine2',
    imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
    description : 'Stylish cafe chair',
    d:"For the British market, we make high-quality corrugated boxes. These boxes are typical in bespoke packaging because to their eco-friendliness, adaptability, and accuracy. Our corrugated boxes are eco-friendly and robust containers for a variety of things due to their size and form options. Corrugated cardboard protects your possessions throughout transportation and handling like no other material.    These corrugated boxes may become effective brand ambassadors with logos and other branding components. Since we enjoy modification, these boxes may be resized and restructured. They are cost-effective and help preserve our planet."
   },
   {
    id:"3",
    productName : 'Slytherine3',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"4",
   productName : 'Slytherine4',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"5",
    productName : 'Slytherine5',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"6",
   productName : 'Slytherine6',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"7",
    productName : 'Slytherine7',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"8",
   productName : 'Slytherine8',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"9",
    productName : 'Slytherine',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"10",
   productName : 'Slytherine2',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"11",
productName : 'Slytherine3',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"12",
productName : 'Slytherine4',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"13",
productName : 'Slytherine5',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"14",
productName : 'Slytherine6',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"15",
productName : 'Slytherine7',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
  id:"16",
productName : 'Slytherine8',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
]
export const Product2 =[
  {
    id:"1",
       productName : 'Ecommer',
   imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
   description : 'Stylish cafe chair',
  },
  {
    id:"2",
      productName : 'Ecommer2',
  imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
  description : 'Stylish cafe chair',
  d:"For the British market, we make high-quality corrugated boxes. These boxes are typical in bespoke packaging because to their eco-friendliness, adaptability, and accuracy. Our corrugated boxes are eco-friendly and robust containers for a variety of things due to their size and form options. Corrugated cardboard protects your possessions throughout transportation and handling like no other material.    These corrugated boxes may become effective brand ambassadors with logos and other branding components. Since we enjoy modification, these boxes may be resized and restructured. They are cost-effective and help preserve our planet."
 },
 {
  id:"3",
  productName : 'Slytherine3',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"4",
 productName : 'Slytherine4',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"5",
  productName : 'Slytherine5',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"6",
 productName : 'Slytherine6',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"7",
  productName : 'Slytherine7',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"8",
 productName : 'Slytherine8',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"9",
  productName : 'Slytherine',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"10",
 productName : 'Slytherine2',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"11",
productName : 'Slytherine3',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"12",
productName : 'Slytherine4',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"13",
productName : 'Slytherine5',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"14",
productName : 'Slytherine6',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"15",
productName : 'Slytherine7',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
{
id:"16",
productName : 'Slytherine8',
imageUrl : '/images/mystyle/FaceWashBoxes.jpg', // Replace with the actual path to your image
description : 'Stylish cafe chair',
},
]
export const Data = [
    {
      id: 1,
      icon: '/images/mystyle/box-onee.png', // Replace with actual icon paths
      title: 'Starting with 100 Boxes',
      content:
        'Embark on your packaging journey with confidence! Whether you need 100 custom boxes for a small venture or a larger project, we ve got you covered with quality and customization.',
    },
    {
      id: 2,
      icon: '/images/mystyle/box-two.png',
      title: 'Customized Size & Style',
      content:
        'Tailor your packaging to perfection with our customized size and style options. Elevate your brands image and functionality with personalized packaging solutions that make a lasting impression.',
    },
    {
        id: 3,
        icon: '/images/mystyle/box-one.png',
        title: 'Free Shipping all across UK',
        content:
          'Anywhere in the UK, you can enjoy hassle-free delivery right to your door and wave goodbye to shipping costs.',
      },
      {
        id: 4,
        icon: '/images/mystyle/box-four.png',
        title: 'Free Designing Assistance',
        content:
          'Benefit from our years of experience and industry expertise as we collaborate to create captivating custom packaging solutions tailored to your brand.',
      },
      {
        id: 5,
        icon: '/images/mystyle/box-five.png',
        title: 'No Die & Plate Charge',
        content:
          'Eliminate extra costs with our no die & plate charges policy. Experience the freedom to create without constraints as we waive these traditional fees',
      },
      {
        id: 6,
        icon: '/images/mystyle/box-six.png',
        title: 'Fastest Turnaround 7 to 8 working days',
        content:
          'We understand the urgency of your packaging needs and are committed to meeting your deadlines with precision and efficiency.',
      },
    // ... More card data
  ];