import React,{useEffect,useState} from 'react'
import { Helmet } from 'react-helmet';
import {  Data } from "../../Data/Mystyledata";
import YourComponent from '../YourComponent';
import Navbar from "../../Components/Navbar"
import Footer from '../../Components/Footer';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

export default function Fastfood() {
  const [prod, setProd] = useState([]);
  const navigate = useNavigate()
const reviewclick=()=>{
    navigate(`/feedback`)
}
    
const handlecontact = () => {
  navigate('/contact')
  window.scrollTo(0, 0);
  };
  const handleportfolio = () => {
    navigate('/request-quote')
    window.scrollTo(0, 0);
    };
    const handle2=()=>{
      navigate("/request-quote")
    }
useEffect(() => {
  axios.get('https://crm.onestepcustomboxes.co.uk/api/fast')
      .then(response => {
          const data = response.data;
          console.log('Fetched data:', data.pro);

          // Adjust the image URLs
          const modifiedPro = data.pro.map(product => ({
              ...product,
              image: `https://crm.onestepcustomboxes.co.uk${product.image}`
          }));

          setProd(modifiedPro);
          AOS.init({
              duration: 1000
          });
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
}, []);   

const home=()=>{
  navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <div>
       <Helmet>
        <title>Durable and Eco-Friendly Fast Food Packaging Solution!</title>
        <meta name="Description" content="Explore our eco-friendly, stylish fast food packaging solutions. Perfect for maintaining freshness, ensuring safety, and delivering a convenient dining experience to your customers." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/food-packaging-boxes'/>
  <meta name="robots" content="index,follow" />

      </Helmet>
        <Navbar/>
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] cursor-pointer  font-medium " onClick={home}>
              Home
            </p>
  <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            {/* Add this line */}
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4]  font-medium ">
              By Industry
            </p>
  <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px]  font-medium">
            Fast Food Custom Boxes
            </p>
          </div>
        </div>
{/* -------------------Read All news------------ */}
<div>
<div className=' text-black lg:w-[90%] md:w-[90%] w-[90%] mx-auto p-8 mt-4' data-aos="fade-right">
  <h1 className='font-bold xl:text-[30px] lg:text-[30px] md:text-[30px] text-[25px] mt-4' >Discover Our Fast Food Custom boxes</h1>
  <p className='mt-6'>
  In the dynamic UK beauty market, we stand out with our extensive collection of bespoke cosmetics and packaging. Our meticulously designed packaging solutions will take your brand to new heights of notoriety. In addition to safeguarding your belongings, our exquisite wrapping will make them shine. Any advantage that might make your cosmetics product stand out in the highly competitive cosmetics sector is greatly appreciated.
  </p>
  <button className='hover:bg-white  bg-[#b04d4b] hover:text-[#b04d4b] text-white border-2 border-[#b04d4b] px-8 rounded-lg py-2 mt-5 font-bold' onClick={handle2}>Get a Quote</button>
  </div> 
</div>
{/* -----------------Products---------------- */}
<div className=' pb-10 '>

<h2 className='font-bold mt-14 text-center lg:text-[38px] md:text-[35px] text-[25px]'>Our Products</h2>
<YourComponent prod={prod} />
</div>  


{/* --------------Furniture-----------------  */}
<div className="bg-[#191a18]  py-10">
<div className="w-[95%]  mx-auto">
<div className="flex flex-wrap ">
{Data.map(item => (
<div className="p-4  lg:w-[32%] md:w-1/3  mt-10" key={item.id} data-aos="fade-up" >
  <div className="flex flex-col items-center h-full px-6 py-6 bg-white rounded-lg shadow-lg">
    <img
      src={item.icon} // Assuming icon is a path to an image file
      alt={`Icon for ${item.title}`}
      loading="lazy"
      className="mb-3 w-20 h-20 mt-[-60px]"
    />
    <h3 className="text-[18px] text-center font-bold mb-4">{item.title}</h3>
    <p className="leading-relaxed text-center text-[15px] mb-4">{item.content}</p>
  </div>
</div>
))}
</div>
</div>
</div>

{/* ----------2----------- */}
<div className='lg:pb-[5%] md:pb-[5%] pb-[7%] text-white' style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/images/backr.png)", backgroundSize:"100% 100%"}}>
<h2 className='lg:text-[35px] md:text-[35px] text-[25px] text-center font-bold lg:pt-[5%] md:pt-[5%] pt-[7%]'>Quality Custom Boxes, Affordable Prices</h2>
<p className='text-center lg:text-[15px] md:text-[15px] text-[14px] mt-[20px]  m-2 ' style={{lineHeight:"1.1"}}>From your order to your front door, our team manages it all to make things simpler for you</p>
<div className='flex lg:w-[31%] md:w-[31%] w-[100%]  lg:mt-[30px] md:mt-[2%] mt-[5%] mx-auto'>
    <button className='border-2 text-[12px] mx-auto rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handlecontact}>Contact Us Today</button>
    <button className='border-2 text-[12px] mx-auto lg:ml-10 md:ml-10 rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handleportfolio}>Get Free Quote</button>

</div>
        </div>
        <div className='bg-white  p-7'>
            <div className='flex items-center justify-between'>
                <h2 className=' font-bold lg:text-[30px] md:text-[30px] text-[20px]'>Give us a <span className='text-[#fe7d6a]'> Feedback</span></h2>
                <button className='bg-[#fe7d6a] lg:text-[17px] md:text-[17px] text-[13px] text-white font-bold lg:py-2 md:py-2 py-2 lg:px-4 md:px-4 px-2 rounded-lg' onClick={reviewclick}> Write a Review</button>
            </div>
        </div>

    <Footer/>
    </div>
  )
}
