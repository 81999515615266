import React,{useEffect, useState} from 'react'
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Helmet } from 'react-helmet';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';
import YourComponent3 from './YourComponent3';
import axios from 'axios';
export default function Blog() {
const navigate = useNavigate()

const reviewclick=()=>{
    navigate(`/feedback`)
}
    
const handlecontact = () => {
  navigate('/contact')
  window.scrollTo(0, 0);
  };
  const handleportfolio = () => {
    navigate('/request-quote')
    window.scrollTo(0, 0);
    };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Adjust the duration as needed
    });

  }, []);

  const [blogPosts, setBlogPosts]=useState([]) 
  useEffect(() => {
    axios.get('https://crm.onestepcustomboxes.co.uk/api/blogs')
        .then(response => {
            const data = response.data;
            console.log('Fetched data:', data.blog);

            // Adjust the image URLs
            // const modifiedPro = data.blog.map(product => ({
            //     ...product,
            //     image: `https://crm.onestepcustomboxes.co.uk${product.image}`                
            // }));
            const modifiedPro = data.blog.map(product => ({
              ...product,
              // image: product.image.map(img => `https://crm.onestepcustomboxes.co.uk${img}`)
            }));
    

            setBlogPosts(modifiedPro);
            console.log(modifiedPro,"modifiedPro")
            AOS.init({
                duration: 1000
            });
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
}, []);


const home=()=>{
  navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <div>
       <Helmet>
        <title>Discover Tips and Trends in Custom Packaging</title>
        <meta name="Description" content="Explore our blog for the latest tips, trends, and insights on custom packaging. Stay informed on how to elevate your brand’s packaging and enhance customer experiences." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/blog'/>
  <meta name="robots" content="index" />

      </Helmet>
      {/* ---------Navbar-------- */}
      <Navbar />
      <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] cursor-pointer font-medium " onClick={home}>
              Home
            </p>
             <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px]  font-medium">
          Blogs
            </p>
          </div>
        </div>
      <div>
        <div>
          <h1 className="text-center lg:text-[35px] md:text-[35px] text-[35px] mt-10 font-bold">
            LATEST BLOGS
          </h1>
          <p
            className="text-center lg:w-[48%] md:w-[48%] w-[90%] mx-auto font-medium mt-5"
            style={{ lineHeight: "1.3" }}
          >
            Onestepcustomboxes, we provide answers to unspoken questions,
            offering insights and knowledge through our publications. Explore
            the world of packaging with us
          </p>
        </div>
        <div className="flex flex-wrap lg:justify-around md:justify-between justify-around  lg:w-[90%]  md:w-[90%] w-[95%] mb-14 mt-14 mx-auto" data-aos="fade-up">
        <YourComponent3 prod={blogPosts} />
      {/* {blogPosts.map(post => (
        <a key={post.id}  className="block lg:w-[24%] md:w-[24%] w-[80%] mt-5 rounded overflow-hidden shadow-lg">
          <div>
            <img
              className="w-full"
              src={post.imageUrl}
              alt={post.altText}
            />
            <div className="px-2 py-4">
              <div className="font-bold text-[17px] mb-2">
                {post.title}
              </div>
              <p className="text-gray-700 text-[14px] flex items-center">
                <img
                  className="h-6 w-6 mr-3"
                  src={post.authorImage}
                  alt={post.altText}
                />{" "}
                BY {post.authorName} | {post.date}
              </p>
            </div>
          </div>
        </a>
      ))} */}
        </div>
      </div>
      {/* ----------Discover Blog------------- */}
      {/* <div className="flex flex-wrap  lg:w-[90%] md:w-[90%] w-[95%] mx-auto mb-10 ">
     <div className="lg:w-[45%] md:w-[50%] w-[90%] mx-auto flex items-center ">
        <h2 className="lg:text-[35px] md:text-[35px] text-[28px] font-bold">
            <span className="line-through text-[#d6d5d5]">BLOGS</span> <br/>
            DISCOVER OUR LATESTS STORY.
        </h2>
     </div>
     <div className="lg:w-[55%] md:w-[50%] w-[100%] flex flex-wrap lg:mt-0 md:mt-0 mt-4 justify-around " >
     <div className="lg:w-[43%] md:w-[43%] w-[80%] rounded overflow-hidden shadow-lg m-4" data-aos="fade-up">
      <img className="w-full" src="/images/Blog/blog-10.png" alt="Blog" />
      <div className="px-2 pt-4 pb-2">
        <span className="inline-block  rounded-full px-3 py-1 text-[15px]  text-gray-400 mr-2 mb-2">24.OCT.2022 | BY ADMIN</span>
      </div>    
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">The Revolution in the Packaging Industry and Its Impact on the Future</div>
      </div>
    
    </div>
    <div className="lg:w-[43%] md:w-[43%] w-[80%] rounded overflow-hidden shadow-lg m-4" data-aos="fade-up">
      <img className="w-full" src="/images/Blog/blog-10.png" alt="Blog" />
      <div className="px-2 pt-4 pb-2">
        <span className="inline-block  rounded-full px-3 py-1 text-[15px]  text-gray-400 mr-2 mb-2">24.OCT.2022 | BY ADMIN</span>
      </div>    
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">The Revolution in the Packaging Industry and Its Impact on the Future</div>
      </div>
    
    </div>
    <div className="lg:w-[43%] md:w-[43%] w-[80%] rounded overflow-hidden shadow-lg m-4" data-aos="fade-up">
      <img className="w-full" src="/images/Blog/blog-10.png" alt="Blog" />
      <div className="px-2 pt-4 pb-2">
        <span className="inline-block  rounded-full px-3 py-1 text-[15px]  text-gray-400 mr-2 mb-2">24.OCT.2022 | BY ADMIN</span>
      </div>    
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">The Revolution in the Packaging Industry and Its Impact on the Future</div>
      </div>
    
    </div>
    <div className="lg:w-[43%] md:w-[43%] w-[80%] rounded overflow-hidden shadow-lg m-4" data-aos="fade-up">
      <img className="w-full" src="/images/Blog/blog-10.png" alt="Blog" />
      <div className="px-2 pt-4 pb-2">
        <span className="inline-block  rounded-full px-3 py-1 text-[15px]  text-gray-400 mr-2 mb-2">24.OCT.2022 | BY ADMIN</span>
      </div>    
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">The Revolution in the Packaging Industry and Its Impact on the Future</div>
      </div>
    
    </div>
     </div>
      </div> */}
      {/* -----------------Quality Custom--------- */}
      <div className='lg:pb-[5%] md:pb-[5%] pb-[7%] text-white' style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/images/backr.png)", backgroundSize:"100% 100%"}}>
<h2 className='lg:text-[35px] md:text-[35px] text-[25px] text-center font-bold lg:pt-[5%] md:pt-[5%] pt-[7%]'>Quality Custom Boxes, Affordable Prices</h2>
<p className='text-center lg:text-[15px] md:text-[15px] text-[14px] mt-[20px]  m-2 ' style={{lineHeight:"1.1"}}>From your order to your front door, our team manages it all to make things simpler for you</p>
<div className='flex lg:w-[31%] md:w-[31%] w-[100%]  lg:mt-[30px] md:mt-[2%] mt-[5%] mx-auto'>
    <button className='border-2 text-[12px] mx-auto rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handlecontact}>Contact Us Today</button>
    <button className='border-2 text-[12px] mx-auto lg:ml-10 md:ml-10 rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handleportfolio}>Get Free Quote</button>

</div>
        </div>
        <div className='bg-white  p-7'>
            <div className='flex items-center justify-between'>
                <h2 className=' font-bold lg:text-[30px] md:text-[30px] text-[20px]'>Give us a <span className='text-[#fe7d6a]'> Feedback</span></h2>
                <button className='bg-[#fe7d6a] lg:text-[17px] md:text-[17px] text-[13px] text-white font-bold lg:py-2 md:py-2 py-2 lg:px-4 md:px-4 px-2 rounded-lg' onClick={reviewclick}> Write a Review</button>
                
            </div>
        </div>
        {/* --------------------Footer-------- */}
        <Footer/>
    </div>
  );
}
