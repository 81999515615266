import React, { useState, useRef, useEffect } from "react";
import { Helmet } from 'react-helmet';
import "./Help.css";
import { FiPlus } from "react-icons/fi";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { faq } from "./Faq/Faqdata"


import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";

export default function HelpFaqs() {
  const [active, setActive] = useState(Array(faq.length).fill(false));

  const contentRefs = useRef(faq.map(() => React.createRef()));

  useEffect(() => {

      AOS.init({
      duration: 1000, // Adjust the duration as needed
    });

    // Adjust the maxHeight of the first accordion based on its active state
    contentRefs.current.forEach((ref, index) => {
      ref.current.style.maxHeight = active[index]
        ? `${ref.current.scrollHeight}px`
        : "0px";
    });
  }, [active]);

  const toggleAccordion = (index) => {
    setActive((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };
const navigate= useNavigate();
  const home=()=>{
    navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <>
     <div>
     <Helmet>
        <title>Frequently Asked Questions | Quick Answers</title>
        <meta name="Description" content="Find answers to the most common questions about our custom packaging solutions. Get the information you need quickly and easily with our comprehensive FAQ section." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/faqs'/>
  <meta name="robots" content="index" />

      </Helmet>
        <Navbar/>
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] cursor-pointer font-medium " onClick={home}>
              Home
            </p>
             <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px] font-medium">
          Help & Faqs
            </p>
          </div>
        </div>
        <div className="mt-7">
            <h1 className="text-center text-[30px] font-bold">Frequently asked questions</h1>
            <p className="text-center text-[18px] font-medium">Your questions, answered</p>
        </div>
{/* ------------------FAQS---------- */}
<div className="xl:m-20  lg:m-20 md:m-20 m-5  ">
{faq.map((item, index) => (
        <div key={index} className="App mt-5" data-aos="fade-zoom-in">
          <button
            className={`question-section ${active[index] ? "active" : ""}`}
            onClick={() => toggleAccordion(index)}
          >
            <div className="question-align">
              <h2 className="question-style font-bold xl:text-[21px] lg:text-[21px] md:text-[21px] text-[17px]">
                {item.Question}
              </h2>
              <FiPlus
                className={active[index] ? "question-icon rotate" : "question-icon"}
              />
            </div>
            <div
              ref={contentRefs.current[index]}
              className={`answer ${active[index] ? "answer-divider" : ""}`}
            >
              <p className="font-medium">{item.Answer}</p>
            </div>
          </button>
        </div>
      ))}
</div>
{/* -------------------------baner--------- */}
<div className="bg-[#e8e5e0] flex flex-wrap py-4"  >
    <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] w-[90%] mx-auto  flex justify-center items-center">
        <div className="flex justify-center items-center flex-col">
            <h2 className="text-[30px] font-medium text-center">Bring your ideas to <br/> life in minute!</h2>
            <p className="text-[15px] mt-5 font-medium text-center ">Delivering groundbreaking excellence in packaging</p>
            <button className="bg-white text-black px-4 mt-3 py-3"> Get Started Today</button>
        </div>
    </div>
    <div className="xl:w-[50%] lg:w-[50%] md:w-[50%] w-[90%] mx-auto mt-3 ">
        <img src="/images/services.jpg" loading="lazy" alt="img"/>
    </div>
    
</div>
{/* -----Footer--------- */}
        <Footer/>
     </div>
    </>
  );
}
