import React,{ useEffect} from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Helmet } from 'react-helmet';

import AOS from 'aos';
import 'aos/dist/aos.css';
import Card from "../Cards/Cards";
import Choosing2 from "../Components/Choosing2";
import { useNavigate } from "react-router-dom";

export default function  Marriott() {
  useEffect(() => {

    AOS.init({
      duration: 1000, // Adjust the duration as needed
    });

  }, []);
  const navigate= useNavigate();
  
const home=()=>{
  navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <>
    <Helmet>
        <title>Harrods' Success with One Step Custom Boxes</title>
        <meta name="Description" content="Discover how Harrods enhanced brand image and customer satisfaction with our custom packaging solutions, achieving remarkable success and exceeding expectations." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/harrods'/>
  <meta name="robots" content="index" />

      </Helmet>
        <Navbar />
      <div className="">
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className=" text-[#C4C4C4] lg:text-[15px] text-[9px] font-medium cursor-pointer" onClick={home}>
              Home
            </p>
             <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            {/* Add this line */}
            <p className="lg:text-[15px] text-[9px] font-medium">
            Harrods
            </p>
          </div>
        </div>
        <div>
          <h1 className="text-center text-[30px] mt-10 mb-10 font-bold">Customer Success Stories Harrods</h1>
        </div>
       {/* 2 */}
       <section class="bg-white">
    <div class=" 2xl:w-[80%]  xl:w-[90%] lg:w-[80%] md:w-[80%] w-[100%] px-6 py-10 mx-auto">
        <div class="lg:-mx-6 flex justify-around lg:flex lg:items-center overflow-hidden">
            {/* 1mage */}
            <img class="object-cover object-center 2xl:w-[35%] lg:w-1/2 lg:mx-6 w-full h-96 rounded-lg 2xl:h-[35rem] lg:h-[26rem]" src="/images/stories/success1.jpg" loading="lazy" alt="" data-aos="fade-right"/>

            <div class="mt-8  lg:w-1/2 lg:px-6 lg:mt-0" data-aos="fade-left">
                {/* <p class="text-5xl font-semibold text-blue-500 ">“</p> */}

                <h2 class="2xl:text-[40px] text-2xl font-semibold text-gray-800  lg:text-3xl lg:w-96">
                The Problem
                </h2>

                <p class="2xl:text-[21px] 2xl:w-[90%]   mt-6 lg:text-[15px] md:text-[15px] text-[13px] font-medium ">
                Harrods was celebrating its 175th anniversary, and they were trying to do something new apart from running some traditional campaigning. They were severe regarding their brand appearance. Harrods recognised the importance of enhancing its brand through customised gift packaging. Harrods’ marketers want a branded custom box packaging for their product called “Henry Bear.” They want to showcase Establishing Credibility, Highlighting Superiority and Trust through product packaging.</p>
            </div>
        </div>
    </div>
</section>

{/* 2 */}
<section class="bg-[#FCF6F1] pb-10">
    <div class=" px-6 py-10 mx-auto " data-aos="fade-right">
            <div>
            <div class="w-full md:w-[70%] h-52 mx-auto px-8 py-4 mt-16  bg-white rounded-lg shadow-lg">
<h2 class="w-[80%] text-[#566166] mx-auto text-sm md:text-xl md:mt-10 mt-4 leading-normal  font-semibold text-center ">Our new design strategy builds on that Courtyard legacy in ways that are perfectly suited for both leisure and business travelers.</h2>

</div>
    {/* <div class="flex justify-center -mt-8 md:justify-center ">
      <img class="object-cover rounded-full w-24 h-24 border border-4 border-white shadow-lg" src="/images/diane-mayer.jpeg" alt=""/>
    </div> */}

                <div class="flex flex-col items-center justify-center mt-6">

                    <div class=" text-center">
                        <h2 class="font-semibold text-gray-800 ">Robin smith</h2>
                        <p class="text-sm text-gray-500 mt-2">Robin smith Sales & stragety manager</p>
                    </div>
                </div>
            </div>

           
    </div>
</section>

{/* 3 */}

<section>
<div className="w-[93%] mx-auto md:flex md:flex-row-reverse  mb-10"> 
  <div className="w-[90%] mx-auto md:w-[70%] mt-10" >
    <div className="w-[95%] mx-auto overflow-hidden" >
      <div data-aos="fade-left">

  <h2 class="text-2xl font-semibold text-gray-800  lg:text-3xl" >
  The Solution
    </h2>

    <p class=" mt-6 lg:text-[15px] md:text-[15px] text-[13px] font-medium ">
    On 1st January 2024, they contacted One Step Custom Boxes about branded custom box packaging for their famous Henry Bear. Our team collaborated closely with Harrods' representatives to understand their requirements. Harrods company wants to ensure that the packages reflect the brand's legacy of luxury and attention to detail.
 </p>

    <p class="mt-2 lg:text-[15px] md:text-[15px] text-[13px] font-medium">After the design process, our team of professionals crafted the box prototypes. Later on, Harrods finalised the designs, and our production team executed the manufacturing process.
</p>

        <h2 class="text-2xl font-semibold text-gray-800  lg:text-3xl  mt-6">
        The Results
    </h2>

    <p className="mt-6 lg:text-[15px] md:text-[15px] text-[13px] font-medium ">
    The reviews we got from the Harrods team were fantastic. They were so impressed by our work and promptly placed repeat orders.    </p>  

    <p className="mt-6 lg:text-[15px] md:text-[15px] text-[13px] font-medium ">“I must say, One Step Custom Boxes exceeded my expectations! Their team was accommodating throughout the process and gave their 100%.”</p>

    <p className="mt-4 lg:text-[15px] md:text-[15px] text-[13px] font-medium ">
    Michael Ward
    </p>
<p className="mt-2 lg:text-[15px] md:text-[15px] text-[13px] font-medium ">
Director of Client Services, Harrods

</p>
      </div>
      
      <div className="bg-[#E8F6F5] w-[100%] md:mt-10 mt-4 pt-[1px] pb-16">
        <div data-aos="fade-up-right">
            
<h2 class="w-[80%] text-[#566166] mx-auto text-sm md:text-xl md:mt-16 mt-4 leading-normal  font-medium text-center ">Leading the way in a few service categories, Courtyard never stops innovating thoughtfully to give its guests the greatest possible experience while they live, work, and play.</h2>

{/* 
    <div class="flex justify-center  md:justify-center md:mt-6 mt-4">
      <img class="object-cover rounded-full w-24 h-24 border border-4 border-white shadow-lg" src="/images/diane-mayer.jpeg" alt=""/>
    </div> */}

                <div class="flex flex-col items-center justify-center mt-6">

                    <div class=" text-center">
                        <h2 class="font-semibold text-gray-800 ">George Williamson</h2>
                        <p class="text-sm text-gray-500 mt-2">Vice President and Global Brand Leader</p>
                    </div>
                    </div>
                </div>
            </div>
    </div>
  </div>
  {/* 2 */}
  <div className="w-[90%] mx-auto md:w-[30%]">

    {/* 1 */}

    <div className="bg-[#E8F6F5] rounded-lg md:mt-10 mt-4 pt-[1px]" data-aos="fade-right">
            
                    <div class="w-[90%] text-[#566166] mx-auto text-center">
             <h2 class="w-[90%] text-[#022C44] mx-auto text-sm md:text-xl md:mt-6 mt-4 leading-normal  font-bold text-center ">United Kingdom Leading Packaging & Custom Box Manufacturer</h2>
             <p class="text-sm mt-6 ">
Onestepcustomboxes Online custom box and package design and acquisition are simple with OnestepCustomBoxes. Contact us right now to learn more about your options for bringing your packaging concept to life.</p>
             
          <button className="bg-[#F48781] hover:bg-[#FF9C6E] text-white font-bold py-2 px-4 mt-6 rounded">
            Get Free Quote
          </button>
                </div>
                <img className="h-52 w-full mt-10" src="/images/old-spice-mailer-box.png" loading="lazy" alt=""/>
            </div>
    {/* 2 */}

  

{/* 3 */}
{/* <div className=" bg-[#FFD7CA]  rounded-lg md:mt-10 mt-4 pt-[1px] pb-6" data-aos="zoom-in-up">
  <div className="w-[90%] mx-auto">

<h2 className=" text-[#022C44]  text-sm md:text-2xl md:mt-6 mt-4 leading-normal font-bold text-center ">
Want<span className="text-[#17A99F]"> 10% off </span> your first order?
  </h2>
  <p class="mt-6 md:text-sm text-sm   text-center ">
  Type your email address and we’ll send you the discount code directly to your inbox.
      </p>  


    <div class="flex">
        <div class="relative w-full mt-6">
            <input type="search" id="search-dropdown" class="block py-4 px-5 w-full z-20 text-sm text-[#878888] bg-white rounded-lg " placeholder="Email Address" required />
            <button type="submit" class="absolute top-0 end-0 p-2.5 h-full text-sm font-medium text-white bg-[#2BA399] rounded-tr-lg hover:bg-[#1AA99F]">
         Get Code
  </button>
        </div>
    </div>


      <p class="mt-6 md:text-[10px] text-sm font-semibold  text-center ">
      JOIN 1000S OF OTHERS. YES, IT’S COMPLETELY FREE.
      </p> 
      <p class="mt-2 md:text-[9px] text-xs  text-[#92A1A8] text-center ">
      By submitting this form you agree to our Terms of Service and Privacy Policy.
      </p> 
  </div>
</div> */}


  </div>
</div>
</section>

{/* 4 */}

<section>
  <div>
    <div className="bg-[#E8F6F5] overflow-hidden  pt-[1px] pb-16 ">
      <div className=" text-center overflow-hidden" data-aos="fade-left">
    <h2 class="text-xl md:text-2xl font-bold text-[#022C44]  lg:text-3xl mt-14 ">
    What Our Customers Say
      </h2>

      <p class=" mt-2 lg:text-sm md:text-sm text-xs font-medium ">
      If you're looking for exceptional, affordable, and unique bespoke packaging, go no further than us! But rather than just taking our word for it, see what our customers have to say.  </p>   
      </div>
       <Card/>
        </div> 
  </div>
</section>

{/* 5 */}



{/* 6 */}
<Choosing2/>

      </div>
        <Footer />

</>
);
}


