import React,{useEffect,useState} from 'react'
import { Helmet } from 'react-helmet';
import { Data } from "../../Data/Mystyledata";
import YourComponent from '../YourComponent';
import Navbar from "../../Components/Navbar"
import Footer from '../../Components/Footer';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

export default function Clothing() {

  const [prod, setProd] = useState([]);
  const navigate = useNavigate()
const reviewclick=()=>{
    navigate(`/feedback`)
}
const handlecontact = () => {
  navigate('/contact')
  window.scrollTo(0, 0);
  };
  const handleportfolio = () => {
    navigate('/request-quote')
    window.scrollTo(0, 0);
    };
    const handle2=()=>{
      navigate("/request-quote")
    }
useEffect(() => {
  axios.get('https://crm.onestepcustomboxes.co.uk/api/clothing')
      .then(response => {
          const data = response.data;
          console.log('Fetched data:', data.pro);

          // Adjust the image URLs
          const modifiedPro = data.pro.map(product => ({
              ...product,
              image: `https://crm.onestepcustomboxes.co.uk${product.image}`
          }));

          setProd(modifiedPro);
          AOS.init({
              duration: 1000
          });
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
}, []);    
const home=()=>{
  navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <div>
       <Helmet>
        <title>Stylish & Sustainable Apparel Packaging Solutions</title>
        <meta name="Description" content=" Discover premium apparel packaging boxes designed to enhance your brand’s image. Perfect for secure shipping and elegant presentation, tailored to your needs." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/clothing-apparel-boxes'/>
  <meta name="robots" content="index,follow" />

      </Helmet>
        <Navbar/>
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] cursor-pointer font-medium " onClick={home}>
              Home
            </p>
            <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            {/* Add this line */}
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4]  font-medium ">
              By Industry
            </p>
            <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px]  font-medium">
            Clothing Custom Boxes
            </p>
          </div>
        </div>
{/* -------------------Read All news------------ */}
<div>
<div className=' text-black lg:w-[90%] md:w-[90%] w-[90%] mx-auto p-8 mt-4' data-aos="fade-right">
  <h1 className='font-bold xl:text-[30px] lg:text-[30px] md:text-[30px] text-[25px] mt-4' >Discover Our Clothing Custom boxes</h1>
  <p className='mt-6'>
  At One Step Custom Boxes, apart from product protection, we understand that packaging is crucial in defining your brand's image. Our custom clothing boxes are designed to protect your products and make a lasting impression on your customers. Whether you're a boutique fashion brand, a large-scale retailer, or an independent designer, our custom packaging solutions will meet your needs.
  </p>
  <button className='hover:bg-white  bg-[#b04d4b] hover:text-[#b04d4b] text-white border-2 border-[#b04d4b] px-8 rounded-lg py-2 mt-5 font-bold'onClick={handle2}>Get a Quote</button>
  </div> 
</div>
{/* -----------------Products---------------- */}
<div className=' pb-10 '>

<h2 className='font-bold mt-14 text-center lg:text-[38px] md:text-[35px] text-[25px]'>Our Products</h2>
<YourComponent prod={prod} />
</div>  

<div className="2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-1">
      <div className="2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-4 py-6">
        {/* <h3 className="text-[17px] font-medium">
          At One Step Custom Boxes, apart from product protection, we understand that packaging is crucial in defining your brand's image. Our custom clothing boxes are designed to protect your products and make a lasting impression on your customers. Whether you're a boutique fashion brand, a large-scale retailer, or an independent designer, our custom packaging solutions will meet your needs.
        </h3> */}
        <h2 className="text-[20px] mt-6 font-bold">
          Why Choose Our Custom Clothing Boxes?
        </h2>
      </div>

      <div className="2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-4 pb-10">
        <h3 className="text-[17px] font-medium mb-4">
          Here are key benefits you will get by choosing One Step Custom Boxes:
        </h3>
        <ul className="list-disc pl-6">
          <li className="text-[17px] font-medium mb-4">
            <strong>Quick Turnaround:</strong> We pride ourselves on efficiency. With 98% of orders ready within 24 hours, your custom clothing boxes will be manufactured and shipped to your doorstep within 8-10 days.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Get 25% Off:</strong> Currently, we are offering 25% off all sorts of custom packaging solutions.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Free Shipping Across the UK:</strong> Enjoy free shipping on all orders of £300+ across the UK, ensuring your packaging arrives on time without additional cost.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>No Die & Plate Charges:</strong> We offer competitive pricing with no hidden costs, including no charges for die and plate.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Custom Sizes & Styles:</strong> Our custom clothing boxes come in all sizes and styles, tailored to fit your requirements. Whether you need a sleek and modern design or something more traditional, we've got you covered.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>High-Quality Materials:</strong> We offer a wide range of paper stock options to ensure your packaging is durable and visually appealing. Choose plain, CMYK colours, PMS (Pantone Matching System), and spot colours for your printing needs.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Premium Finishing Options:</strong> Enhance the look and feel of your boxes with our premium finishing options, including gloss lamination, matte lamination, gloss AQ, gloss UV, matte UV, spot UV, embossing, and foiling.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Included and Additional Options:</strong> Our standard offerings include die-cutting, glueing, and perforation. We offer options like window patching and foil stamping in various colours for those looking to add an extra touch of elegance.
          </li>
        </ul>

        <h2 className="text-[20px] mt-6 font-bold">
          How Does It Work?
        </h2>

        <ul className="list-disc pl-6">
          <li className="text-[17px] font-medium mb-4">
            <strong>Custom Design Assistance:</strong> Our team of experts offers free design assistance to help bring your vision to life. We work closely with you to ensure your packaging reflects your brand's identity.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Proofing:</strong> Before production, you'll receive a flat view, 3D mock-up, or a physical sample upon request. This ensures that every detail meets your expectations.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Production & Turnaround:</strong> Once the press-ready file is confirmed, we begin production, with a turnaround of 8-10 business days.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Shipping:</strong> Your custom clothing boxes will be shipped flat, packed securely in containers, and delivered via UPS, FedEx, or DHL.
          </li>
        </ul>

        <h3 className="text-[17px] font-medium mt-6">
          Ready to elevate your brand with custom clothing boxes? Contact us today at <a className="text-[#fe7d6a]" href="mailto:Contact@onestepcustomboxes.co.uk">Contact@onestepcustomboxes.co.uk</a> or call us at <a className="text-[#fe7d6a]" href="tel:02033320061">02033320061</a>. Our team is here to assist you with all your custom packaging needs.
        </h3>
      </div>
    </div>

{/* --------------Furniture-----------------  */}
<div className="bg-[#191a18]  py-10">
<div className="w-[95%]  mx-auto">
<div className="flex flex-wrap ">
{Data.map(item => (
<div className="p-4  lg:w-[32%] md:w-1/3  mt-10" key={item.id} data-aos="fade-up">
  <div className="flex flex-col items-center h-full px-6 py-6 bg-white rounded-lg shadow-lg">
    <img
      src={item.icon} // Assuming icon is a path to an image file
      alt={`Icon for ${item.title}`}
      loading="lazy"
      className="mb-3 w-20 h-20 mt-[-60px]"
    />
    <h3 className="text-[18px] text-center font-bold mb-4">{item.title}</h3>
    <p className="leading-relaxed text-center text-[15px] mb-4">{item.content}</p>
  </div>
</div>
))}
</div>
</div>
</div>

{/* ----------2----------- */}
<div className='lg:pb-[5%] md:pb-[5%] pb-[7%] text-white' style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/images/backr.png)", backgroundSize:"100% 100%"}}>
<h2 className='lg:text-[35px] md:text-[35px] text-[25px] text-center font-bold lg:pt-[5%] md:pt-[5%] pt-[7%]'>Quality Custom Boxes, Affordable Prices</h2>
<p className='text-center lg:text-[15px] md:text-[15px] text-[14px] mt-[20px]  m-2 ' style={{lineHeight:"1.1"}}>From your order to your front door, our team manages it all to make things simpler for you</p>
<div className='flex lg:w-[31%] md:w-[31%] w-[100%]  lg:mt-[30px] md:mt-[2%] mt-[5%] mx-auto'>
    <button className='border-2 text-[12px] mx-auto rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handlecontact}>Contact Us Today</button>
    <button className='border-2 text-[12px] mx-auto lg:ml-10 md:ml-10 rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold' onClick={handleportfolio}>Get Free Quote</button>

</div>
        </div>
        <div className='bg-white  p-7'>
            <div className='flex items-center justify-between'>
                <h2 className=' font-bold lg:text-[30px] md:text-[30px] text-[20px]'>Give us a <span className='text-[#fe7d6a]'> Feedback</span></h2>
                <button className='bg-[#fe7d6a] lg:text-[17px] md:text-[17px] text-[13px] text-white font-bold lg:py-2 md:py-2 py-2 lg:px-4 md:px-4 px-2 rounded-lg' onClick={reviewclick}> Write a Review</button>
            </div>
        </div>

    <Footer/>
    </div>
  )
}
