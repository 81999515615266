import React,{useState,useEffect,useCallback } from 'react'
import {  Section2 } from "../Data/Homepagedata";
import { Helmet } from 'react-helmet';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaStar } from "react-icons/fa";
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper/modules';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Topproduct from '../Components/Topproduct';
export default function NewQuote() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    quantity: '',
    color: '',
    length: '',
    width: '',
    height: '',
    unit: '',
    designFile: null, // Changed file to designFile
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'designFile') { // Updated to designFile
      setFormData({
        ...formData,
        designFile: files[0], // Get the first selected file
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const data = new FormData();
    data.append('name', formData.name);
    data.append('phone', formData.phone);
    data.append('email', formData.email);
    data.append('quantity', formData.quantity);
    data.append('color', formData.color);
    data.append('length', formData.length);
    data.append('width', formData.width);
    data.append('height', formData.height);
    data.append('unit', formData.unit);
    if (formData.designFile) { // Updated to designFile
      data.append('designFile', formData.designFile); // Append the file
    }
  
    try {
      const response = await fetch('https://crm.onestepcustomboxes.co.uk/api/quote', {
        method: 'POST',
        body: data, // Automatically sends as multipart/form-data
      });
  
      if (response.ok) {
        setSubmissionStatus('success');
        setFormData({
          name: '',
          email: '',
          phone: '',
          quantity: '',
          color: '',
          length: '',
          width: '',
          height: '',
          unit: '',
          designFile: null, // Reset to null
        });
      } else {
        setSubmissionStatus('error');
      }
    } catch (error) {
      console.error('Error:', error);
      setSubmissionStatus('error');
    }
  
    setTimeout(() => {
      setSubmissionStatus(null);
    }, 2000);
  };


  


  const [prodr, setProdr] = useState([]);
  const fetchData = useCallback(() => {
    Promise.all([
      axios.get('https://crm.onestepcustomboxes.co.uk/api/reviews'),
    ])
      .then(([reviewsRes]) => {
        setProdr(modifyData(reviewsRes.data.reviews));
        // setLoading(false);
        AOS.init({ duration: 1000 });
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const modifyData = (data) => {
    return data.map(item => ({
      ...item,
      image: `https://crm.onestepcustomboxes.co.uk/${item.image}`
    }));
  };

  const [section, setSection] = useState([]);
  useEffect(() => {
    setSection(Section2);
  }, []);
  const [activeVideo, setActiveVideo] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  const handleVideoClick = (videoSrc) => {
    setActiveVideo(videoSrc);
  };

  const handleCloseModal = () => {
    setActiveVideo(null);
  };
  useEffect(() => {
    // Remove data-react-helmet attribute from the canonical link
    const canonicalLink = document.querySelector("link[rel='canonical']");
    if (canonicalLink) {
      canonicalLink.removeAttribute("data-react-helmet");
    }
  }, []);
  return (
    <div>
       <Helmet>
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/instant-quote'/>
  <meta name="robots" content="noindex" />

      </Helmet>
      <Navbar />
      <h1 className='font-bold text-center text-[30px] 2xl:mb-5 xl:mb-5 lg:mb-5 md:mb-5 mb-0  mt-6'>
      Instant Quote
          </h1>
          <div className="mx-auto 2xl:flex xl:flex lg:flex md:flex  hidden " style={{ fontFamily: "montserrat" }}>
          <div className="flex flex-wrap  lg:mt-7 md:mt-5 mt-4 lg:w-[69%] md:w-[70%] w-[85%] mb-8 mx-auto">
            {section.map((data) => (
              <div className="lg:w-[16%]  md:w-[33%] w-[50%] lg:mb-0 md:mb-10 mb-10 flex flex-col items-center" data-aos="fade-up">
                <div
                  className="lg:text-[45px] md:text-[50px] text-[40px] p-2 rounded-full border"
                  style={{ border: "3px solid #fe7d6a" }}
                >
                  {data.icon}
                </div>
                <p
                  className="text-center mt-2 text-[14px]"
                  style={{ fontFamily: "montserrat", fontWeight: "550" }}
                >
                  {data.text1} <br /> {data.text2}
                </p>
              </div>
            ))}
          </div>
        </div>
      {/* --------------form-------- */}
      <div className="w-[100%] p-8 flex flex-wrap justify-between lg:pt-13 md:pt-13 pt-0 pb-10 mx-auto">
        <div className="lg:w-[59%] md:w-[59%] w-[100%]">
          <form className="lg:w-[80%] md:w-[80%] w-[95%] lg:mt-0 md:mt-0 mt-8 mx-auto shadow-2xl p-[6%]" onSubmit={handleSubmit}>
            <div className="mb-5 flex justify-between flex-wrap">
              <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%] mb-4">
                <label htmlFor="name" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%] mb-4">
                <label htmlFor="email" className="block mb-2 font-bold text-[15px] text-gray-900">
                  E-mail
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Your E-mail"
                  required
                />
              </div>
              <div className="xl:w-[31%] lg:w-[31%] md:w-[31%] w-[47%] mb-4">
                <label htmlFor="phone" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Phone
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Your Phone Number"
                  required
                />
              </div>
              <div className="xl:w-[31%] lg:w-[31%] md:w-[31%] w-[47%] mb-4">
                <label htmlFor="length" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Length
                </label>
                <input
                  type="text"
                  id="length"
                  name="length"
                  value={formData.length}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Length"
                />
              </div>
              <div className="xl:w-[31%] lg:w-[31%] md:w-[31%] w-[47%] mb-4">
                <label htmlFor="width" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Width
                </label>
                <input
                  type="text"
                  id="width"
                  name="width"
                  value={formData.width}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Width"
                />
              </div>
              <div className="xl:w-[31%] lg:w-[31%] md:w-[31%] w-[47%] mb-4">
                <label htmlFor="height" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Height
                </label>
                <input
                  type="text"
                  id="height"
                  name="height"
                  value={formData.height}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Height"
                />
              </div>
             
              <div className="xl:w-[31%] lg:w-[31%] md:w-[31%] w-[47%] mb-4">
                <label htmlFor="quantity" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Quantity
                </label>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  value={formData.quantity}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Your Quantity"
                  required
                />
              </div>
              <div className="xl:w-[31%] lg:w-[31%] md:w-[31%] w-[47%] mb-4">
                <label htmlFor="color" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Color
                </label>
                <select id="color" name="color"
                    value={formData.color} onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option>Please Choose an option</option>
                    <option value="1color ">1 Color</option>
                    <option value="2color">2 Color </option>
                    <option value="3color">3 Color </option>
                    <option value="4color">4 Color </option>
                    <option value="1/pantone-color">1/pantone Color </option>
                    <option value="2/pantone-color">2/pantone Color </option>
                    <option value="3/pantone-color">3/pantone Color </option>
                    <option value="4/pantone-color">4/pantone Color </option>
                </select>
              </div>
              <div className="xl:w-[31%] lg:w-[31%] md:w-[31%] w-[47%] mb-4">
                <label htmlFor="unit" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Unit
                </label>
                <input
                  type="text"
                  id="unit"
                  name="unit"
                  value={formData.unit}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Unit"
                />
              </div>
             <div className='w-auto mb-4 '>
               <label htmlFor="designFile" className="block mb-2 font-bold text-[15px] text-gray-900">
                Upload File
              </label>
              <input
                type="file"
                id="designFile" // Updated to designFile
                name="designFile" // Updated to designFile
                onChange={handleChange}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2"
              />
              </div>
            </div>
            <button
              type="submit"
              className="text-white w-full bg-[#fe7d6a] hover:bg-[#e66f5d] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Get a Quote Now
            </button>
          </form>
          {submissionStatus === 'success' && (
            <div className="text-green-500 mt-4">Form submitted successfully!</div>
          )}
          {submissionStatus === 'error' && (
            <div className="text-red-500 mt-4">An error occurred. Please try again.</div>
          )}
        </div>
        <div className="lg:w-[40%] md:w-[36%] w-[100%] flex justify-center items-center mt-5 ">
          <div>
<img className='2xl:h-[450px] xl:h-[450px] lg:h-[450px] md:h-[450px] h-[380px] mx-auto w-[90%] ' src='/images/finalbanner/ok.webp' loading="lazy" alt='img' />
          </div>
        </div>
      </div>
      {/* <div className="w-[100%] p-8 flex flex-wrap justify-between lg:pt-13 md:pt-13 pt-0 pb-10 mx-auto">
        
        <div className="lg:w-[59%] md:w-[59%] w-[100%]">
          <form className="lg:w-[80%] md:w-[80%] w-[95%] lg:mt-0 md:mt-0 mt-8 mx-auto shadow-2xl p-[6%]" onSubmit={handleSubmit}>
            
            <div className="mb-5 flex justify-between">
              <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
                <label htmlFor="name" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
                <label htmlFor="email" className="block mb-2 font-bold text-[15px] text-gray-900">
                  E-mail
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Your E-mail"
                  required
                />
              </div>
            </div>
            <div className="mb-5 flex justify-between flex-wrap">
              <div className="xl:w-[31%] lg:w-[31%] md:w-[31%] w-[47%]">
                <label htmlFor="phone" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Phone
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Your Phone Number"
                  required
                />
              </div>
              <div className="xl:w-[31%] lg:w-[31%] md:w-[31%] w-[47%]">
                <label htmlFor="quantity" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Quantity
                </label>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  value={formData.quantity}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Your Required Quantity"
                  required
                />
              </div>
              <div className="xl:w-[31%] lg:w-[31%] md:w-[31%] w-[47%]">
              <label htmlFor="color" className="block mb-2 font-bold text-[15px] text-gray-900">Color</label>
                <select id="color" name="color"
                    value={formData.color} onChange={handleChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                        <option value="">-Please Choose an option-</option>
                    <option value="1color ">1 Color</option>
                    <option value="2color">2 Color </option>
                    <option value="3color">3 Color </option>
                    <option value="4color">4 Color </option>
                    <option value="1/pantone-color">1/pantone Color </option>
                    <option value="2/pantone-color">2/pantone Color </option>
                    <option value="3/pantone-color">3/pantone Color </option>
                    <option value="4/pantone-color">4/pantone Color </option>
                </select>
              </div>
            </div>
            <div className="mb-5 flex justify-between flex-wrap">
              <div className="xl:w-[23%] lg:w-[23%] md:w-[23%] w-[47%]">
                <label htmlFor="length" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Length
                </label>
                <input
                  type="number"
                  id="length"
                  name="length"
                  value={formData.length}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Length"
                  required
                />
              </div>
              <div className="xl:w-[23%] lg:w-[23%] md:w-[23%] w-[47%]">
                <label htmlFor="width" className="block mb-2 font-bold text-[15px] text-gray-900">
                  Width
                </label>
                <input
                  type="number"
                  id="width"
                  name="width"
                  value={formData.width}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Width"
                  required
                />
              </div>
              <div className="xl:w-[23%] lg:w-[23%] md:w-[23%] w-[47%]">
                <label htmlFor="height" className="block mb-2 font-bold text-[15px] text-gray-900">
                Height
                </label>
                <input
                  type="number"
                  id="height"
                  name="height"
                  value={formData.height}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Height"
                  required
                />
              </div>
              <div className="xl:w-[23%] lg:w-[23%] md:w-[23%] w-[47%]">
              <label htmlFor="unit" className="block mb-2 font-bold text-[15px] text-gray-900">Unit</label>
                        <select id="unit" name="unit" value={formData.unit} onChange={handleChange}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                            <option value="cm">cm</option>
                            <option value="inch">inch</option>
                            <option value="mm">mm</option>
                        </select>
              </div>
            </div>
          <div>
            
            </div>

            {submissionStatus === 'success' && (
              <div className="mt-4 text-white bg-green-400 p-3 mb-3 font-bold">
                Your request has been submitted successfully!
              </div>
            )}
            {submissionStatus === 'error' && (
              <div className="mt-4 text-red-600">
                There was an error submitting your request. Please try again later.
              </div>
            )}
            <button
              type="submit"
              className="text-white w-full bg-[#fe7d6a] hover:bg-[#e66f5d] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              Get a Quote Now
            </button>
          </form>
        </div>
        <div className="lg:w-[40%] md:w-[36%] w-[100%] flex justify-center items-center mt-5 ">
          <div>
<img className='2xl:h-[450px] xl:h-[450px] lg:h-[450px] md:h-[450px] h-[380px] mx-auto w-[90%] ' src='/images/finalbanner/ok.webp' loading="lazy" alt='img' />
          </div>
        </div>
      </div> */}
      {/* ------videos---------- */}
      <div>
  <div className='bg-white pt-8 2xl:w-[80%] mx-auto'>
    <h2 className='lg:text-[35px] md:text-[40px] mb-3 text-[15px] p-1 font-[700] text-center lg:w-[80%] md:w-[80%] w-[97%] mx-auto'>
      Reasons Why <span className='text-[#fe7d6a]'>OnestepCustomBoxes</span> Packaging Has 1,000s of Satisfied Clients
    </h2>
    
    {/* For mobile, show only 2 videos */}
    <div className='flex w-[85%] mx-auto flex-wrap justify-around lg:hidden'>
      {[
        { id: 1, src: '/images/homevideo1.mp4' },
        { id: 2, src: '/images/homevideo2.mp4' }
      ].map((video) => (
        <div key={video.id} className='w-[45%] mb-4 mt-2'>
          <video
            playsInline
            muted
            preload="yes"
            autoPlay
            loop
            className="h-[250px] mx-auto mt-0 pb-0 cursor-pointer"
            onClick={() => handleVideoClick(video.src)}
            data-aos="zoom-in-up"
          >
            <source src={video.src} type="video/mp4" />
          </video>
        </div>
      ))}
    </div>

    {/* For larger screens, show all videos */}
    <div className='hidden lg:flex w-[65%] mx-auto flex-wrap justify-around'>
      {[
        { id: 1, src: '/images/homevideo1.mp4' },
        { id: 2, src: '/images/homevideo2.mp4' },
        { id: 3, src: '/images/homevideo3.mp4' },
        { id: 4, src: '/images/homevideo4.mp4' }
      ].map((video) => (
        <div key={video.id} className='2xl:w-[25%] xl:w-[25%] lg:w-[25%] mb-4 mt-2'>
          <video
            playsInline
            muted
            preload="yes"
            autoPlay
            loop
            className="2xl:h-[350px] lg:h-[350px] md:h-[350px] h-[200px] mx-auto lg:mt-12 md:mt-12 mt-0 lg:pb-8 md:pb-8 pb-0 cursor-pointer"
            onClick={() => handleVideoClick(video.src)}
            data-aos="zoom-in-up"
          >
            <source src={video.src} type="video/mp4" />
          </video>
        </div>
      ))}
    </div>
  </div>

  {activeVideo && (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75">
      <div className="relative w-full h-full max-w-4xl max-h-full">
        <video controls autoPlay className="w-full h-full object-contain">
          <source src={activeVideo} type="video/mp4" />
        </video>
        <button
          onClick={handleCloseModal}
          className="absolute top-2 right-2 text-white bg-red-500 rounded-full p-2"
        >
          &times;
        </button>
      </div>
    </div>
  )}
</div>

      {/* ---------------review------------- */}
      <div className='pt-8 w-[90%] mx-auto' >
        <h1 className='font-bold text-center text-[30px] 2xl:mb-4 xl:mb-4 lg:mb-4 md:mb-4 mb-0'>
          Reviews
          </h1>
    <Swiper
    data-aos="fade-up"
       autoplay={{
        delay: 2500, // specify the delay in milliseconds between slides
        disableOnInteraction: false, // set to false if you want autoplay to continue even when user interacts with the swiper
      }}
       slidesPerView={4}
        spaceBetween={30}
        centeredSlides={true}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
        modules={[Autoplay]}
        className="mySwiper "
      >
{prodr.map((review, index) => (
        <SwiperSlide key={index} className='bg-white rounded-xl'>
          <div className='flex justify-center p-5 items-center flex-col'>
            <h2 className='font-medium'>{review.name}</h2>
            <div className='flex mt-1'>
              {review.stars !== null && review.stars !== undefined ? (
                // Render stars based on the number provided in review.stars
                [...Array(parseInt(review.stars))].map((_, starIndex) => (
                  <FaStar key={starIndex} className='mr-2 text-[#fe7d6a]' />
                ))
              ) : (
                <p>No star rating provided</p>
              )}
            </div>
            <p className='text-[15px] mt-3' style={{ lineHeight: "1.2" }}>
              {review.review}
            </p>
          </div>
        </SwiperSlide>
      ))}
      </Swiper>
    </div>
    {/* ---------Hotitems-------- */}
    <Topproduct/>
      <Footer />
    </div>
  );
}
