import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import axios from 'axios';

const YourComponent3 = ({ prod }) => {
  const [showMore, setShowMore] = useState(false);

  const ProductCard = ({ data }) => {
    const navigate = useNavigate();
    const handleClick = async () => {
      const formattedName = encodeURIComponent(
        decodeURIComponent(data.name)
          .replace(/\s+/g, '-')
          .toLowerCase()
          .replace(/[^a-z0-9\-]/g, '')  // Remove any non-alphanumeric characters except hyphens
      );
        navigate(`/blog/${formattedName}`, { state: { product: data } });
      window.scrollTo(0, 0);
    };

const dataimg = data.image[0];

    useEffect(() => {
      AOS.init({
        duration: 1000,
      });
    }, []);

    return (
      <div
        className="lg:w-[45%] md:w-[45%] mt-6 w-[80%] hover:cursor-pointer rounded overflow-hidden shadow-lg rounded-tl-3xl rounded-tr-3xl relative"
        data-aos="fade-up"
        onClick={handleClick}
      >
         <img
                // key={index == 0}
                className="w-full h-64 object-cover object-center"
                src={`https://crm.onestepcustomboxes.co.uk${dataimg}`}
                alt={data.name}
                loading="lazy"
              />
       {/* {data.image.map((imageUrl, index) => (
              <img
                key={index == 0}
                className="w-full h-64 object-cover object-center"
                src={`https://crm.onestepcustomboxes.co.uk${imageUrl}`}
                alt={data.name}
                loading="lazy"
              />
            ))} */}
        <div className="px-6 py-2">
          <div className="font-bold lg:text-[14px] text-center md:text-[14px] text-[13px] m-3 mb-2">{data.name}</div>
        </div>
      </div>
    );
  };

  const renderCards = () => {
    const maxCardsToShow = 8;
    const visibleCards = prod.slice(0, maxCardsToShow);
    const remainingCards = prod.length - maxCardsToShow;

    return (
      <>
        {visibleCards.map((data, index) => (
          <ProductCard key={index} data={data} />
        ))}
        {remainingCards > 0 && (
          <div className="w-full flex justify-center">
            <button className="text-white bg-[#b04d4b] font-bold py-3 px-6 rounded mt-6" onClick={() => setShowMore(true)}>
              Show More
            </button>
          </div>
        )}
      </>
    );
  };

  const renderAllCards = () => {
    return (
      <>
        {prod.map((data, index) => (
          <ProductCard key={index} data={data} />
        ))}
      </>
    );
  };

  return (
    <div>
      <div className='flex flex-wrap lg:w-[85%] md:w-[85%] w-[100%] mt-6 mx-auto justify-around'>
        {showMore ? renderAllCards() : renderCards()}
      </div>
    </div>
  );
};

export default YourComponent3;
