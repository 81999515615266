import React, { useEffect, useState } from "react";
  import Navbar from "../Components/Navbar";
  import { useLocation, useNavigate } from 'react-router-dom';
  import DOMPurify from 'dompurify';
  // import ProductSlider from "./ProductSlider";
  import Footer from "../Components/Footer"
  import { FaFacebook, FaInstagram, FaLinkedin, FaTwitterSquare, FaPinterest,FaStar } from "react-icons/fa";
  import { Helmet } from 'react-helmet';
import axios from "axios";
// import Productquote from "./Productquote";

  export default function Product() {
    const { state } = useLocation();

    const [product, setProduct] = useState(state && state.product ? state.product : null);
    // const [product2, setProduct2] = useState(null);
    const navigate = useNavigate();

      if (product) {
        const fetchCategory = async () => {
          try {
            const words = product.catgr.toLowerCase().split(' ');
            let categoryName = words[0] === 'by' && words.length > 1 ? words[1] : words[0]; // Skip 'by' if it's the first word
  
            // Correct 'suplements' to 'supplements'
            if (categoryName === 'suplements') {
              categoryName = 'supplements';
            }
            if (categoryName === 'style') {
              categoryName = 'industry';
            }
  
            const response = await axios.get(`https://crm.onestepcustomboxes.co.uk/api/${categoryName}`);
            // setProduct2(response.data.pro);
            console.log(response.data.pro, "response.data.pro");
          } catch (error) {
            console.error('Error fetching industry URL:', error);
          }
        };
  
        fetchCategory();
      }



    
    const [useBackup, setUseBackup] = useState(false);
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      address: '',
      quantity: '',
      boxType: '',
      message: '',
    });
    const [submissionStatus, setSubmissionStatus] = useState(null);
  
    const urlWithoutDomain = window.location.pathname + window.location.search + window.location.hash;            
    const urlapi = urlWithoutDomain.slice(1);
  
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page when component mounts
  
      if (!state) { 
        const fetchProduct = async () => {
          try {
            const response = await axios.get('https://crm.onestepcustomboxes.co.uk/api/pro', {
              params: { url: urlapi }
            });
            
            if (!response.data.products || response.data.products.length === 0) {
              // If no products found, navigate to the Not Found page
              navigate('*'); // Adjust the route as per your setup
            } else {
              setProduct(response.data.products);
            }
          } catch (error) {
            console.error('Error hitting the API:', error);
            navigate('*'); // Navigate to Not Found page in case of an error
          }
        };
        fetchProduct();
      }
    }, [state, urlapi]);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await fetch('https://crm.onestepcustomboxes.co.uk/api/lead', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: formData.name,
            phone: formData.phone,
            email: formData.email,
            coating: formData.boxType,
            quantity: formData.quantity,
            address: formData.address,
            additionalNotes: formData.message,
          }),
        });
  
        if (response.ok) {
          setSubmissionStatus('success'); // Set status to success
          setFormData({
            name: '',
            email: '',
            phone: '',
            address: '',
            quantity: '',
            boxType: '',
            message: '',
          });
        } else {
          setSubmissionStatus('error'); // Set status to error
        }
      } catch (error) {
        console.error('Error:', error);
        setSubmissionStatus('error'); // Set status to error
      }
  
      setTimeout(() => {
        setSubmissionStatus(null);
      }, 2000);
    };
  
    const handleImageError = () => {
      setUseBackup(true);
    };
  
    const home = () => {
      navigate("/");
    };
  
    const handleBackClick = () => {
      window.scrollTo(0, 0);
      window.history.back();
    };
  
    const move = () => {
      navigate('/request-quote');
    };
  
    const movereview = () => {
      navigate('/feedback');
    };
    const sanitizedHTML1 = product ? DOMPurify.sanitize(product.descrption) : '';
    const sanitizedHTML2 = product ? DOMPurify.sanitize(product.descrpt) : '';
  
    const currentUrl = window.location.href;

    const validUrls = [
      "https://onestepcustomboxes.co.uk/electronics-packaging-box/battery-packaging",
      "https://onestepcustomboxes.co.uk/electronics-packaging-box/camera-gear-packaging",
      "https://onestepcustomboxes.co.uk/electronics-packaging-box/computer-component-packaging",
      "https://onestepcustomboxes.co.uk/electronics-packaging-box/drone-packaging",
      "https://onestepcustomboxes.co.uk/electronics-packaging-box/gaming-console-packaging"
    ];
    
    const shouldIndex = validUrls.includes(currentUrl); 

    useEffect(() => {
      // Remove data-react-helmet attribute from the canonical link
      const canonicalLink = document.querySelector("link[rel='canonical']");
      if (canonicalLink) {
        canonicalLink.removeAttribute("data-react-helmet");
      }
    }, [product]);

      return (
        <div>
           <Helmet>
        <title>{product ? product.mn : "Product"}</title>
        <meta name="Description" content={product ? product.md : ""} />
        <link rel="canonical" href={`https://onestepcustomboxes.co.uk/${product ? product.url : ""}`} />
        <meta name="robots" content={shouldIndex ? "index,follow" : "noindex,nofollow"} />
      </Helmet>
          <Navbar />
          <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
            <div className="flex justify-center item-center">
              <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] cursor-pointer font-medium" onClick={home}>Home</p>
              <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
              <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] font-medium">By Industry</p>
              <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
              <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] font-medium cursor-pointer" onClick={handleBackClick}>{product ? product.catgr : ""}</p>
          <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
          <p className="lg:text-[15px] text-[9px] font-medium">{product ? product.name : ""}</p>
       </div>
          </div>
          <div className="w-[90%] py-14 mx-auto">
            {product && (
              <>
                <div className="lg:w-[85%] mb-5 mx-auto flex flex-wrap">
                {!useBackup ? (
            <img
              alt={product.pt}
              className="2xl:w-[40%] lg:w-1/2 w-full lg:h-[auto] md:h-64 h-auto object-cover object-center rounded"
              src={product.image}
              loading="lazy"
              onError={handleImageError}
              style={{ cursor: 'auto' }}
            />
          ) : (
            <img
              alt={product.pt}
              loading="lazy"
              className="2xl:w-[40%] lg:w-1/2 w-full lg:h-[auto] md:h-64 h-auto object-cover object-center rounded"
              src={`https://crm.onestepcustomboxes.co.uk${product.image}`}
              style={{ cursor: 'auto' }}
            />
          )}
                  <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0" style={{ cursor: "auto" }}>
                    <h1 className="text-gray-900 text-3xl title-font font-medium mb-1" style={{ cursor: "auto" }}>
                      {product.name}
                    </h1>
                    <div className="flex mb-4">
                      <span className="flex items-center">
                      <FaStar className="ml-1 text-[#fe7d6a]" />
                      <FaStar className="ml-1 text-[#fe7d6a]" />
                      <FaStar className="ml-1 text-[#fe7d6a]" />
                      <FaStar className="ml-1 text-[#fe7d6a]" />
                      <FaStar className="ml-1 text-[#fe7d6a]" />
                      </span>
                      <span className="flex ml-3 pl-3 py-2 border-l-2 border-gray-200 space-x-2s">
          <a className="ml-2 hover:text-[#fe7d6a] text-[20px]" href="https://web.facebook.com/people/One-Step-Custom-Boxes/61556641223995/?_rdc=1&_rdr">
          <FaFacebook />
            </a> 
            <a className="ml-2 hover:text-[#fe7d6a] text-[20px]" href="https://www.instagram.com/onestepcustomboxesltd/">
            <FaInstagram />
            </a>
            <a className="ml-2 hover:text-[#fe7d6a] text-[20px]" href="https://www.linkedin.com/company/onestepcustomboxesltd/?viewAsMember=true">
            <FaLinkedin />
            </a>
            <a className="ml-2 hover:text-[#fe7d6a] text-[20px]" href="https://twitter.com/i/flow/login?redirect_after_login=%2Fonestepcustom">
            <FaTwitterSquare />
            </a>
            <a className="ml-2 hover:text-[#fe7d6a] text-[20px]" href="https://www.pinterest.com/onestepcustomboxes/">
            <FaPinterest />
            </a>
                      </span>
                    </div>
                    <div className="text-[15px]" dangerouslySetInnerHTML={{ __html: sanitizedHTML2 }}></div>
                    {/* <p className="text-[15px]">{product.descrpt}</p> */}
                    <div className="flex pt-3 mt-3 border-t-2 flex-col text-[14px]">
                      <h2 className="mt-2">
                        <span className="font-bold"> Categories: </span>  {product.catgr}
                      </h2>
                      {/* <h2 className="mt-2"> */}
                        {/* <span className="font-bold"> Tages: </span> Boxes, Corrugated Boxes, Custom Corrugated Boxes */}
                      {/* </h2> */}
                    </div>
                  </div>
                </div>
                <div className=" mb-10">
                  {/* {product.descrption} */}
                 <div className="text-[15px]" dangerouslySetInnerHTML={{ __html: sanitizedHTML1 }}></div>

                </div>
              <div className="flex flex-wrap" >
              <div className="2xl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-[30%] w-[80%] rounded-xl 2xl:mb-10 xl:mb-10 lg:mb-10 md:mb-10 mb-5 flex justify-center p-3 mx-auto bg-[#fe7d6a] text-white font-bold text-center" onClick={move}>
    <button className="">Get a Quote  form</button>
                </div>
                <div className="2xl:w-[30%] xl:w-[30%] lg:w-[30%] md:w-[30%] w-[80%] rounded-xl 2xl:mb-10 xl:mb-10 lg:mb-10 md:mb-10 mb-5 flex justify-center p-3 mx-auto bg-[#fe7d6a] text-white font-bold text-center" onClick={movereview}>
    <button className="">Write a Review</button>
                </div>
              </div>
                <div className="w-[100%] flex flex-wrap justify-between lg:pt-13 md:pt-13 pt-0 pb-10 mx-auto">
                  <div className="lg:w-[40%] md:w-[36%] w-[100%] flex justify-center items-center ml-3">
                    <div>
                      <h2 className="font-bold xl:text-[33px] lg:text-[33px] md:text-[33px] text-[25px]">
                        Request a <span className="text-[#fe7d6a]"> Free Quote & Consultation </span>
                      </h2>
                      <p className="mt-3 xl:text-[15px] lg:text-[15px] md:text-[15px] text-[13px]">
                        OnestepCustomBoxes is a representation of greatness, quality, and openness for every single consumer. We will respond to your price request form in less than a day if you fill it out!
                        <br /> <br />
                        From the initial consultation until the end of manufacturing and delivery, a devoted packaging specialist is ready to guide you through the full custom packaging process without causing you any pain.
                      </p>
                      <br />
                      <p className="text-[15px] font-medium">
                        Want help sooner? For immediate assistance, contact us via email at <span className="text-[#fe7d6a]">contact@onestepcustomboxes.co.uk</span>
                      </p>
                    </div>
                  </div>
                  <div className="lg:w-[59%] md:w-[59%] w-[100%]">
                    <form className="lg:w-[80%] md:w-[80%] w-[95%] lg:mt-0 md:mt-0 mt-8 mx-auto shadow-2xl p-[6%]" onSubmit={handleSubmit}>
                      <div className="mb-5 flex justify-between">
                        <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
                          <label htmlFor="name" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
                            Name
                          </label>
                          <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your Name"
                            required
                          />
                        </div>
                        <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
                          <label htmlFor="email" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
                            E-mail
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your E-mail"
                            required
                          />
                        </div>
                      </div>
                      <div className="mb-5 flex justify-between">
                        <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
                          <label htmlFor="phone" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
                            Phone
                          </label>
                          <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your Phone Number"
                            required
                          />
                        </div>
                        <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
                          <label htmlFor="address" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
                            Address
                          </label>
                          <input
                            type="text"
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your Address"
                            required
                          />
                        </div>
                      </div>
                      <div className="mb-5 flex justify-between">
                        <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
                          <label htmlFor="quantity" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
                            Quantity
                          </label>
                          <input
                            type="number"
                            id="quantity"
                            name="quantity"
                            value={formData.quantity}
                            onChange={handleChange}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Your Required Quantity"
                            required
                          />
                        </div>
                        <div className="xl:w-[45%] lg:w-[45%] md:w-[45%] w-[47%]">
                          <label htmlFor="boxType" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
                            Box Type
                          </label>
                          <input
                            type="text"
                            id="boxType"
                            name="boxType"
                            value={formData.boxType}
                            onChange={handleChange}
                            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            placeholder="Coating"
                            required
                          />
                        </div>
                      </div>
                      <div className="mb-6">
                        <label htmlFor="message" className="block mb-2 font-bold text-[15px] text-gray-900 dark:text-white">
                          Message
                        </label>
                        <textarea
                          id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                          rows="4"
                          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                          placeholder="Write your message here..."
                          required
                        ></textarea>
                      </div>
                      {submissionStatus === 'success' && (
                        <div className="mt-4 text-white bg-green-400 p-3 mb-3 font-bold">
                          Your request has been submitted successfully!
                        </div>
                      )}
                      {submissionStatus === 'error' && (
                        <div className="mt-4 text-red-600">
                          There was an error submitting your request. Please try again later.
                        </div>
                      )}
                      <button
                        type="submit"
                        className="text-white w-full bg-[#fe7d6a] hover:bg-[#e66f5d] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        Get a Quote Now
                      </button>
                    
                    </form>
                  </div>
                </div>
      
              </>
            )}
          </div>
          {/* <ProductSlider prod={product2}/> */}
          <Footer />
        </div>
      );
    }