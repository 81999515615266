// HoteItems.js
import { Helmet } from 'react-helmet';
import React,{useEffect,useState} from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import HotComponent from './HotComponent'; // Import YourComponent
import axios from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";

const HoteItems = () => {
  
  const navigate = useNavigate()


  const handleNavigate = () => {
navigate('/request-quote')
window.scrollTo(0, 0);
};
const [prod, setProd] = useState([]);
// const [section, setSection] = useState([]);
useEffect(() => {  
  axios.get('https://crm.onestepcustomboxes.co.uk/api/hot-items')
      .then(response => {
          const data = response.data;
          console.log('Fetched data:', data.pro);

          // Adjust the image URLs
          const modifiedPro = data.pro.map(product => ({
              ...product,
              image: `https://crm.onestepcustomboxes.co.uk${product.image}`
          }));

          setProd(modifiedPro);
          AOS.init({
            duration: 1000, // Adjust the duration as needed
          });
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
  // setSection(Section2);
}, []);


const home=()=>{
  navigate("/")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <div>
      <Helmet>
        <title>Top-Rated Custom Boxes for High Demand Products</title>
        <meta name="Description" content="Discover our best-selling custom boxes designed to meet high demand. Ensure product safety, durability, and stylish presentation with our premium packaging solutions." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/hotitems'/>
  <meta name="robots" content="noindex" />

      </Helmet>
      <Navbar />
      <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] font-medium cursor-pointer " onClick={home}>
              Home
            </p>
             <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px] font-medium">
          Hot Items
            </p>
          </div>
        </div>
        <div className=' xl:h-[370px] lg:h-[400px] lg:flex md:flex hidden md:h-[380px] h-[300px] 2xl:w-[70%] xl:w-[95%] lg:w-[80%] md:w-[80%] w-[90%] mx-auto rounded-[80px]  mx-auto mt-10'  style={{backgroundImage:"url(/images/HotItemsBanner.png" , backgroundSize:"100% 100%"}} data-aos="fade-left">
        <div className='w-[90%]  h-[100%] mx-auto flex justify-between items-center ' >
<div className='lg:w-[50%] md:w-[32%] w-[100%] flex flex-col justify-end h-[90%] p-4 lg:mt-0 md:mt-0 mt-5 mb-5' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" >
   {/* <h2 className='lg:text-[35px] mt-2 md:text-[35px] text-[25px]  font-bold' style={{lineHeight:"1.1"}}>Discover the <span className='text-[#fe7d6a]'><br/> top quality </span>  Material</h2> */}
   {/* <p className='lg:mt-8 font-medium md:mt-8 mt-4'>We are proud to be a top UK manufacturer, providing you with high-quality materials for your custom boxes.</p> */}
   <button className='bg-[#b04d4b] text-white hover:bg-white hover:text-[#b04d4b] lg:w-[35%] md:w-[35%] w-[60%] font-bold lg:py-3 md:py-4 py-3 rounded-lg'  onClick={handleNavigate} >Get a Quote</button>
    </div>
</div>

        </div>
        <div className='xl:h-[400px] lg:h-[400px] lg:hidden md:hidden flex mb-[50px] md:h-[380px] h-[auto] p-1 xl:w-[90%] lg:w-[80%] md:w-[80%] w-[90%] mx-auto rounded-[80px]  mx-auto mt-10 bg-[#f8e7d6]'>
        <div className='w-[90%]  h-[100%] mx-auto flex justify-between items-center '>
<div className='lg:w-[50%] md:w-[32%] w-[100%]   flex flex-col lg:items-start items-center p-4 lg:mt-0 md:mt-0 mt-5 mb-5'>
   <h1 className='lg:text-[35px] mt-2 md:text-[35px] text-[25px]  font-bold' style={{lineHeight:"1.1"}}>You One-Shop Destination For Cookies <span className='text-[#fe7d6a]'> Boxes and Packaging</span> Needs.</h1>
   <p className='lg:mt-8 font-medium md:mt-8 mt-4'>In The Dynamic UK Beauty Market, We Stand Out With Our Extensive Collection Of Bespoke Cookies Boxes And Packaging.</p>
   <button className='bg-[#b04d4b] text-white hover:bg-white hover:text-[#b04d4b] lg:w-[35%] md:w-[35%] w-[60%]  lg:mt-8 md:mt-8 mt-6 font-bold lg:py-3 md:py-4 py-3 rounded-lg'  onClick={handleNavigate} >Get a Quote</button>
   </div>
</div>

        </div>
      {/* Display current page items */}
      <h2 className="text-[35px] text-center font-bold m-3">HOT ITEMS</h2>
      <div className="w-[100%]  mt-8 mb-5 mx-auto flex">
        <HotComponent prod={prod} /> {/* Pass dummyData to HotComponent */}
      </div>
      <Footer />
    </div>
  );
};

export default HoteItems;
