import React,{useEffect,useState} from 'react'
import { Helmet } from 'react-helmet';
import {  Data } from "../../Data/Mystyledata";
import YourComponent from '../YourComponent';
import Navbar from "../../Components/Navbar"
import Footer from '../../Components/Footer';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from 'react-router-dom';

export default function Custom() {
  const [prod, setProd] = useState([]);
  const navigate = useNavigate()
const reviewclick=()=>{
    navigate(`/feedback`)
}
    
useEffect(() => {
  axios.get('https://crm.onestepcustomboxes.co.uk/api/custom')
      .then(response => {
          const data = response.data;
          console.log('Fetched data:', data.pro);

          // Adjust the image URLs
          const modifiedPro = data.pro.map(product => ({
              ...product,
              image: `https://crm.onestepcustomboxes.co.uk${product.image}`
          }));

          setProd(modifiedPro);
          AOS.init({
              duration: 1000
          });
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
}, []);   


const home=()=>{
  navigate("/")
}
const handle2=()=>{
  navigate("/request-quote")
}
useEffect(() => {
  // Remove data-react-helmet attribute from the canonical link
  const canonicalLink = document.querySelector("link[rel='canonical']");
  if (canonicalLink) {
    canonicalLink.removeAttribute("data-react-helmet");
  }
}, []);
  return (
    <div>
       <Helmet>
        <title>Bespoke Custom Boxes: Crafted for Your Brand & Products</title>
        <meta name="Description" content=" Discover our bespoke custom-made boxes tailored to your specific needs. Perfect for secure shipping, creating a lasting impression, and enhancing your product's appeal." />
        <link rel='canonical' href='https://onestepcustomboxes.co.uk/bespoke-packaging-boxes'/>
  <meta name="robots" content="index,follow" />

      </Helmet>
        <Navbar/>
        <div className="w-[100%] p-4 bg-[#F5F5F5] text-center ">
          <div className="flex justify-center item-center  ">
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] cursor-pointer font-medium " onClick={home}>
              Home
            </p>
                       <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            {/* Add this line */}
            <p className="lg:text-[15px] text-[9px] text-[#C4C4C4] font-medium ">
              By Industry
            </p>
                       <hr className="border-b border-1 border-gray-300 w-[7%] md:w-[2%] w-[14px] lg:mt-3 md:mt-2 mt-2 ml-2 mr-2" />
            <p className="lg:text-[15px] text-[9px] font-medium">
            Custom Made Boxes
            </p>
          </div>
        </div>
{/* -------------------Read All news------------ */}
<div>
<div className=' text-black lg:w-[90%] md:w-[90%] w-[90%] mx-auto p-8 mt-4' data-aos="fade-right">
  <h1 className='font-bold xl:text-[30px] lg:text-[30px] md:text-[30px] text-[25px] mt-4' >Discover Our Custom Made boxes</h1>
  <p className='mt-6'>
  At One Step Custom Boxes, we specialize in creating high-quality custom boxes tailored to your business's unique needs. Whether you’re looking for visually excellent packaging or boxes that perfectly fit your product dimensions, our expert team is here to bring your dream to life. We are not new in the business. We’ve established ourselves as a trusted name in the custom packaging industry for ten years.
  </p>
  <button className='hover:bg-white  bg-[#b04d4b] hover:text-[#b04d4b] text-white border-2 border-[#b04d4b] px-8 rounded-lg py-2 mt-5 font-bold' onClick={handle2}>Get a Quote</button>
  </div> 
</div>
{/* -----------------Products---------------- */}
<div className=' pb-10 '>

<h2 className='font-bold mt-14 text-center lg:text-[38px] md:text-[35px] text-[25px]'>Our Products</h2>
<YourComponent prod={prod} />
</div>  

<div className="2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-1">
      <div className="2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-4 py-6">
        <h3 className="text-[17px] font-medium">
          At One Step Custom Boxes, we specialize in creating high-quality custom boxes tailored to your business's unique needs. Whether you’re looking for visually excellent packaging or boxes that perfectly fit your product dimensions, our expert team is here to bring your dream to life. We are not new in the business. We’ve established ourselves as a trusted name in the custom packaging industry for ten years.
        </h3>
        <h2 className="text-[20px] mt-6 font-bold">
          Why Choose Our Custom Made Boxes?
        </h2>
      </div>

      <div className="2xl:px-10 xl:px-10 lg:px-10 md:px-10 px-4 pb-10">
        <h3 className="text-[17px] font-medium mb-4">
          Here are the key features you will get by choosing One Step Custom Boxes:
        </h3>
        <ul className="list-disc pl-6">
          <li className="text-[17px] font-medium mb-4">
            <strong>Fast Turnaround:</strong> We understand the importance of time in business. That’s why 98% of our orders are ready within 24 hours and shipped directly to your doorstep within 7-8 days.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Free Shipping:</strong> All UK orders of £300 or more enjoy free shipping. We aim to make your experience seamless and cost-effective.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>No Die & Plate Charges:</strong> We offer a range of customization options without any additional die and plate charges, ensuring that you get the best value for your investment.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Custom Sizes & Styles:</strong> From small batches starting at just 100 boxes to large-scale orders of up to 500,000, we can accommodate your needs with custom sizes and styles.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Free Design Assistance:</strong> Our team of experienced designers can help you create the perfect box design that reflects your brand identity.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Reputable Shipping Options:</strong> Our boxes are shipped flat and packed securely in cartons to ensure they arrive in perfect condition. We partner with leading carriers like UPS, FedEx, and DHL to deliver your orders swiftly and safely.
          </li>
        </ul>

        <h2 className="text-[20px] mt-6 font-bold">
          Customization Options
        </h2>

        <ul className="list-disc pl-6">
          <li className="text-[17px] font-medium mb-4">
            <strong>Box Styles:</strong> We offer various box styles to suit any product, from simple folding cartons to complex structural designs.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Dimensions:</strong> Customization is at the heart of what we do. No matter the length, width, or height, we can create boxes that perfectly fit your products.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Paper Stock:</strong> Choose from various materials to ensure that your boxes are visually appealing but also durable and functional.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Printing:</strong> Whether you prefer plain, CMYK colours, PMS (Pantone Matching System), or spot colours, we offer a range of printing options to make your packaging stand out.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Finishing:</strong> Enhance the look and feel of your boxes with our finishing options, including gloss lamination, matte lamination, gloss AQ, gloss UV, matte UV, spot UV, embossing, and foiling.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Included Options:</strong> Our standard offerings include die-cutting, glueing, and perforation to ensure your boxes are functional and ready for use.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Additional Options:</strong> Take your packaging to the next level with extra options like embossing, window patching, and foil stamping in gold, silver, copper, red, or blue.
          </li>
          <li className="text-[17px] font-medium mb-4">
            <strong>Proofing and Turnaround:</strong> We offer various proofing options, including flat view, 3D mock-ups, and physical sampling upon request. Once the client confirms the press-ready file, the turnaround time is typically 8-10 business days.
          </li>
        </ul>

        <h3 className="text-[17px] font-medium mt-6">
          For more information or to place an order, email <a className="text-[#fe7d6a]" href="mailto:Contact@onestepcustomboxes.co.uk">Contact@onestepcustomboxes.co.uk</a> or call us at <a className="text-[#fe7d6a]" href="tel:02033320061">02033320061</a>. Our team is ready to assist you in creating the perfect custom packaging solution for your business.
        </h3>
      </div>
    </div>

{/* --------------Furniture-----------------  */}
<div className="bg-[#191a18]  py-10">
<div className="w-[95%]  mx-auto">
<div className="flex flex-wrap ">
{Data.map(item => (
<div className="p-4  lg:w-[32%] md:w-1/3  mt-10" key={item.id} data-aos="fade-up">
  <div className="flex flex-col items-center h-full px-6 py-6 bg-white rounded-lg shadow-lg">
    <img
      src={item.icon} // Assuming icon is a path to an image file
      alt={`Icon for ${item.title}`}
      loading="lazy"
      className="mb-3 w-20 h-20 mt-[-60px]"
    />
    <h3 className="text-[18px] text-center font-bold mb-4">{item.title}</h3>
    <p className="leading-relaxed text-center text-[15px] mb-4">{item.content}</p>
  </div>
</div>
))}
</div>
</div>
</div>

{/* ----------2----------- */}
<div className='lg:pb-[5%] md:pb-[5%] pb-[7%] text-white' style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(/images/backr.png)", backgroundSize:"100% 100%"}}>
<h2 className='lg:text-[35px] md:text-[35px] text-[25px] text-center font-bold lg:pt-[5%] md:pt-[5%] pt-[7%]'>Quality Custom Boxes, Affordable Prices</h2>
<p className='text-center lg:text-[15px] md:text-[15px] text-[14px] mt-[20px]  m-2 ' style={{lineHeight:"1.1"}}>From your order to your front door, our team manages it all to make things simpler for you</p>
<div className='flex lg:w-[31%] md:w-[31%] w-[100%]  lg:mt-[30px] md:mt-[2%] mt-[5%] mx-auto'>
    <button className='border-2 text-[12px] mx-auto rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold'>Contact Us Today</button>
    <button className='border-2 text-[12px] mx-auto lg:ml-10 md:ml-10 rounded-lg px-4 py-3 hover:bg-white hover:text-black font-bold'>Get Free Quote</button>

</div>
        </div>
        <div className='bg-white  p-7'>
            <div className='flex items-center justify-between'>
                <h2 className=' font-bold lg:text-[30px] md:text-[30px] text-[20px]'>Give us a <span className='text-[#fe7d6a]'> Feedback</span></h2>
                <button className='bg-[#fe7d6a] lg:text-[17px] md:text-[17px] text-[13px] text-white font-bold lg:py-2 md:py-2 py-2 lg:px-4 md:px-4 px-2 rounded-lg' onClick={reviewclick}> Write a Review</button>
            </div>
        </div>

    <Footer/>
    </div>
  )
}
